import { useNavigate } from "react-router-dom";
import { Container, Card, Table, ButtonGroup, Button, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faChevronRight, faChevronLeft, faHourglass, faCheck, faClose, faMinus, faReply } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { ThreeDots } from 'react-loader-spinner';
import { getMedicalClaimLists, getMedicalClaimListsCount, updateCurrentPage } from "../../3.Store/MedicalClaimSlice";

const MedicalClaimList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const employee = JSON.parse(localStorage.getItem("current_user"));
  const userId = employee?.id;
  const lists = useSelector(state => state.MedicalClaimSlice.medicalClaimLists);
  const currentPage = useSelector(state => state.MedicalClaimSlice.currentPage);
  const totalPages = useSelector(state => state.MedicalClaimSlice.totalPages);

  useEffect(() => {
    setLoading(true);
    if(userId) {
      dispatch(getMedicalClaimLists({id:userId, page:currentPage}));
      dispatch(getMedicalClaimListsCount({id:userId}))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
          setLoading(false);
      });
    }
  }, [userId])

  const handleBtnBack = () => {
    navigate(-1);
  };

  const handleBtnAdd = () => {
    navigate("/MedicalClaimForm");
  };

  const handleClickView = (id) => {
    navigate(`/MedicalClaimForm/${id}`);
  };

  const handleClickEdit = () => {};

  const handlePreviousPage = () => {
    setLoading(true);
    if (currentPage > 1) {
      dispatch(updateCurrentPage(currentPage - 1));
      dispatch(getMedicalClaimLists({id:userId, page:currentPage - 1}))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    }
  };

  const handleNextPage = () => {
    setLoading(true);
    if (currentPage < totalPages) {
      dispatch(updateCurrentPage(currentPage + 1));
      dispatch(getMedicalClaimLists({id:userId, page:currentPage + 1}))
      .then(() => {
          setLoading(false);
      })
      .catch(() => {
          setLoading(false);
      });
    }
  };

  return (
    <Container>
      {loading && (
        <ThreeDots
        visible={loading}
        height="80"
        width="80"
        color="#4fa94d"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: '9999',
        }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Medical Claims</h1>
        <h1 className="MiniTitleHeader">List</h1>

        <Table>
          <thead>
            <tr>
              <th>No</th>
              <th>Date Applied</th>
              <th>Receipt No.</th>
              <th>Practitioner</th>
              <th>Claim (RM)</th>
              <th>Status</th>
              <th>Review</th>
              <th>
                <FontAwesomeIcon icon={faPenToSquare} />
              </th>
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {lists.length > 0 ? (
                lists.map((employee, index) => (
                <tr onClick={() => handleClickView(employee.id)} key={employee.id} style={{ cursor: "pointer" }}>
                    <td>{index + 1}</td>
                    <td>{moment(employee.modified).format("YYYY-MM-DD")}</td>
                    <td>{employee.receipt_no}</td>
                    <td>{employee.receipt_reference}</td>
                    <td>{employee.amount}</td>
                    <td>
                      {employee.status.description === "PENDING" && (
                        <FontAwesomeIcon icon={faHourglass} color="#FFC107" />
                      )}
                      {employee.status.description === "APPROVED-HR" && (
                        <FontAwesomeIcon icon={faCheck} color="#28A744" />
                      )}
                      {employee.status.description === "APPROVED-S" && (
                        <FontAwesomeIcon icon={faCheck} color="#28A744" />
                      )}
                      {employee.status.description === "REJECTED-HR" && (
                        <FontAwesomeIcon icon={faClose} color="#DC3545" />
                      )}
                      {employee.status.description === "REJECTED-S" && (
                        <FontAwesomeIcon icon={faClose} color="#DC3545" />
                      )}
                      {employee.status.description === "DELETED" && (
                        <FontAwesomeIcon icon={faMinus} color="#FFC107" />
                      )}
                      {employee.status.description === "WITHDRAWN" && (
                        <FontAwesomeIcon icon={faReply} color="#28A744" />
                      )}
                    </td>
                    <td>
                      {employee.hr_reviewed == false ? (
                        <FontAwesomeIcon icon={faClose} color="#DC3545" />
                      ) : (
                        <FontAwesomeIcon icon={faCheck} color="#28A744" />
                      )}
                    </td>
                    <td>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </td>
                </tr>
                ))
                ) : (
                <tr>
                  <td colSpan="8">No results found</td>
                </tr>
              )}
            </tbody>
          )}
        </Table>

        <table>
          <tbody>
            <tr>
              <td className="FormLabel">
                <FontAwesomeIcon icon={faHourglass} color="#FFC107" />
                &nbsp;Pending
                &ensp;&ensp;&ensp;&ensp;&ensp;
                <FontAwesomeIcon icon={faCheck} color="#28A744" />
                &nbsp;Approved/Reviewed
                &ensp;&ensp;&ensp;&ensp;&ensp;
                <FontAwesomeIcon icon={faClose} color="#DC3545" />
                &nbsp;Rejected/Not Reviewed
                &ensp;&ensp;&ensp;&ensp;&ensp;
                <FontAwesomeIcon icon={faMinus} color="#FFC107" />
                &nbsp;Deleted
              </td>
            </tr>
          </tbody>
        </table>

        <Col style={{ marginLeft: "auto" }}>
          <Form.Label className="FormLabel">{`${currentPage} of ${totalPages}`}</Form.Label> &ensp;
          {currentPage > 1 ? (
            <Form.Label className="FormLabel" onClick={handlePreviousPage}>
              <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#ffffff" }} />
            </Form.Label>
          ) : (
            <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Form.Label>
          )}
          &ensp;
          {currentPage < totalPages ? (
            <Form.Label className="FormLabel" onClick={handleNextPage}>
              <FontAwesomeIcon icon={faChevronRight} style={{ color: "#ffffff" }} />
            </Form.Label>
          ) : (
            <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
              <FontAwesomeIcon icon={faChevronRight} />
            </Form.Label>
          )}
        </Col>

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
            Back
            </Button>
          </ButtonGroup>
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnAdd}>
              Add
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};
export default MedicalClaimList;
