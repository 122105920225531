import { Container, Navbar, Image, Nav } from "react-bootstrap";
import { useState } from "react";

import LeaveIcon from "../assets/leaveicon.png";
import { useNavigate } from "react-router-dom";

export const styles = {
  icon: { width: "30px", height: "30px" },
  title: { fontSize: "25px" },
  username: { color: "#FFF", textWeight: "bold", fontSize: "20px" },
  LinkText: { fontSize: "20px" },
};

const NavbarMenu = () => {
  const navigate = useNavigate();

  const employee = JSON.parse(localStorage.getItem("current_user"));
  const fullname = employee?.fullname;

  const handleBtnLogout = () => {
    localStorage.clear();
    navigate("/Login");
  };

  return (
    <Navbar bg="dark" variant="dark" expand="md" sticky="top">
      <Container fluid>
        <Navbar.Brand href="/">
          <Image
            src={LeaveIcon}
            style={styles.icon}
            className="d-inline-block align-top"
          />
          <span style={styles.title}>&nbsp;eLeave</span>
          &nbsp; &nbsp;
          <span style={styles.username}>{fullname}</span>
        </Navbar.Brand>

        <Nav>
          {/* <Nav.Link style={styles.LinkText} href="/LeaveApplicationList">
            Apply Leave
          </Nav.Link>
          <Nav.Link style={styles.LinkText} href="/MedicalClaimList">
            Medical Claim
          </Nav.Link> */}
          <Nav.Link style={styles.LinkText} onClick={handleBtnLogout}>
            Logout
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};
export default NavbarMenu;