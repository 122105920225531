import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  ButtonGroup,
  Button,
  Dropdown,
  Modal
} from "react-bootstrap";
import { 
  getEmployeePhoto,
  getEmployeeEdit, 
  getCompanyLists, 
  getEmployees, 
  resetForm,
  dismissEmployee,
  dismissUser,
  updateForm,
  addUsername,
  addUser, 
  updateUser,
  addEmployee,
  addLeaveProrated,
  updateProrated,
  updatePhoto,
  uploadEmployeePhoto,
  replaceEmployeePhoto,
  getAEmployee
} from "../../3.Store/EmployeeSlice";
import { addLeaveAwards, updateList } from "../../3.Store/LeaveAwardsSlice";
import { Authentication, Gender, WorkingSchedule, EmployeeCategory, ServiceLength, leaveValuesByCategory } from "../../2.Commons/DropdownOption";
import { ThreeDots } from 'react-loader-spinner';
import { openEditor } from "react-profile";
import "react-profile/themes/default";
import defaultPhoto from "../../2.Commons/defaultPhoto.jpg";
import './Employee.css';

const EmployeeForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showResetPwd, setShowResetPwd] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [passwordGenerated, setPasswordGenerated] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const photo = useSelector((state) => state.EmployeeSlice.photo);
  const employee = useSelector((state) => state.EmployeeSlice.employeeDetail);
  const user = useSelector((state) => state.EmployeeSlice.user);
  const companyLists = useSelector((state) => state.EmployeeSlice.company);
  const employeeName = useSelector(state => state.EmployeeSlice.supervisor);
  const awardList = useSelector(state => state.LeaveAwardsSlice.awardList);
  const leaveProrated = useSelector((state) => state.EmployeeSlice.leaveProrated);
  const { 
    fullname,
    username,
    sid,
    gender,
    date_joined,
    date_left,
    email,
    company,
    working_schedule,
    employee_category,
    service_length,    
    supervisor,
    status
  } = employee || {};
  const { password, authentication_type } = user;
  const { annual, hospitalization, maternity, medical, sick } = awardList;
  const { 
    prorated_pct_first_year,
    prorated_leave_awarded,
    prorated_sickleave_awarded,
    prorated_medicalbenefit_awarded,
  } = leaveProrated;
  let oldPhotoUrl = "";

  const generatePassword = () => {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let autoPassword = "";

    for (let i = 0; i < 6; i++) { // Assuming default password length is 12
      autoPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }

    return autoPassword;
  };

  const getSearchParamsFromQuery = () => {
    const params = new URLSearchParams(location.search);
    const searchText = params.get('searchText') || '';
    const active = params.get('active') || '0';
    return { searchText, active };
  };

  const { searchText, active } = getSearchParamsFromQuery();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(getCompanyLists());
        await dispatch(getEmployees());
        if (id) {
          await dispatch(getAEmployee({ id }));
          await dispatch(getEmployeeEdit({ id }));
          await dispatch(getEmployeePhoto({ id }));
        } else {
          dispatch(resetForm());
          calculateProrate(employee_category.id, date_joined);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [id]);  

  const calculateProrate = (categoryId, dateJoined) => {
    // const selectedCategory = EmployeeCategory.find(category => category.id === employee_category.id);
    const selectedCategory = EmployeeCategory.find(category => category.id === categoryId);
    console.log(selectedCategory)
    if (selectedCategory) {
      const leaveValues = leaveValuesByCategory[selectedCategory.value];
      if (leaveValues) {
        // Input date string
        const dateString = moment(dateJoined).format("YYYY-MM-DD");
        // Create a Date object from the date string
        const date = new Date(dateString);

        var start = new Date(date.getFullYear(), 0, 0);
        var end = new Date(date.getFullYear() + 1, 0, 0);
        var diff = (end.getTime() - date.getTime()) / (end.getTime() - start.getTime());
        var pct_to_be_prorated = (1.0 - diff);
        var prorated_leave_award2 = diff * leaveValues.annual;
        var prorated_sickleave_award = diff * leaveValues.sick;
        var prorated_medicalbenefit_award = (((12 - (date.getMonth()))/12) * leaveValues.medical);
                
        var new_leave_balance2 = +prorated_leave_award2;

        const entity = { ...leaveProrated,
          prorated_pct_first_year: Math.round(1000 * pct_to_be_prorated) / 10,
          prorated_leave_awarded: Math.round(2 * prorated_leave_award2) / 2,
          prorated_sickleave_awarded: Math.round(2 * prorated_sickleave_award) / 2,
          prorated_medicalbenefit_awarded: Math.round(2 * prorated_medicalbenefit_award) / 2,
          new_leave_balance: Math.round(2 * new_leave_balance2) / 2
        }
        dispatch(updateProrated(entity));

        const entity2 = { ...awardList,
          annual: leaveValues.annual,
          hospitalization: leaveValues.hospitalization,
          maternity: leaveValues.maternity,
          medical: leaveValues.medical,
          sick: leaveValues.sick
        }
        dispatch(updateList(entity2));
      }
    };
  };

  const handleBtnResetPwd = () => {
    setShowResetPwd(true);
  };

  const handleBtnCancel = () => {
    setShowResetPwd(false);
  };

  const handleBtnSubmit = () => {
    if (showResetPwd) {
      if (newPassword !== repeatNewPassword) {
        Swal.fire("Error", "Passwords do not match", "error");
        setNewPassword("");
        setRepeatNewPassword("");
        return;
      }
      else if (newPassword=== "" || repeatNewPassword==="") {
        Swal.fire("Error", "Please enter a password", "error");
        return;
      }
      else if (newPassword === repeatNewPassword) {
        setLoading(true);
        dispatch(dismissUser({ authentication_type: 1, id: id, password: newPassword }))
          .unwrap()
          .then(() => {
            setLoading(false);
            Swal.fire({
              title: "Password for " + fullname + ' is changed',
              icon: 'success',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                setShowResetPwd(false);
                dispatch(getAEmployee({id}));
                dispatch(getEmployeeEdit({id}));
              }
            });
          })
          .catch((err) => {
            setLoading(false);
            Swal.fire("Error", err.response.data, "error");
          });
      }
    }
  };

  const handleBtnBack = () => {
    dispatch(resetForm());
    // navigate(-1);
    navigate(`/EmployeeList?searchText=${searchText}&active=${active}`);
  };

  const handleBtnDismiss = () => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        dispatch(dismissEmployee({ id: id, status: { id: "-3" }, date_left: moment(new Date()).format("YYYY-MM-DD") }));
        dispatch(dismissUser({ id: id, status: { id: "-1" } }))
          .unwrap()
          .then(() => {
            setLoading(false);
            Swal.fire({
              title: fullname + ' has been dismissed',
              icon: 'success',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(-1);
              }
            });
          })
          .catch((err) => {
            setLoading(false);
            Swal.fire("Error", err.response.data, "error");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancel Dismiss Employee', 'error');
      }
    });
  };

  const handleBtnDelete = () => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          dispatch(dismissEmployee({id: id, status: { id: "-1" }, username: "DELETED"}));
          dispatch(dismissUser({id: id, status: { id: "-1" }, username: "DELETED" }))
            .unwrap()
            .then(() => {
              setLoading(false);
              Swal.fire({
                title: fullname + " has been deleted",
                icon: 'success',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate(-1);
                }
              });
            })
            .catch((err) => {
              Swal.fire("Error", err.response.data, "error", "OK");
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', 'Cancel Delete Employee', 'error');
        }
    });
  };

  const handleFormChange = (e) => {
    const entity = { ...employee, [e.target.name]: e.target.value };
    dispatch(updateForm(entity));
    const entity2 = { ...user, [e.target.name]: e.target.value };
    dispatch(updateUser(entity2));
  };

  const handleProrated = (e) => {
    const entity = { ...leaveProrated, [e.target.name]: e.target.value };
    console.log(entity)
    dispatch(updateProrated(entity));
  };

  const handleLeaveAwards = (e) => {
    const entity = { ...awardList, [e.target.name]: e.target.value };
    console.log(entity)
    dispatch(updateList(entity));
  };

  // Map employee names to options
  const options = employeeName.map(employee => ({
    label: employee.fullname,
    value: employee.fullname,
    id: employee.id
  }));

  // Find the option that matches the supervisor's fullname
  useEffect(() => {
    const initialOption = options.find(option => option.id === supervisor?.id);
    setSelectedOption(initialOption);
  }, [supervisor]);

  const handleDropdownSupervisor = (selectedOption) => {
    const entity = { ...employee, supervisor: {id:selectedOption.id} };
    dispatch(updateForm(entity));
  };

  const handleDateChange = (e) => {
    const value = e.target.value;
    const entity = { ...employee, date_joined: value };
    console.log(entity)
    dispatch(updateForm(entity));
    calculateProrate(employee_category.id, value);
  };

  // Function to get the authentication type label
  const getAuthenticationLabel = (value) => {
    const auth = Authentication.find(auth => auth.value === value);
    return auth ? auth.label : "";
  };

  const handleDropdownAuthentication = (value) => {
    const entity2 = { ...user, authentication_type: value };
    console.log(entity2)
    dispatch(updateUser(entity2));

    if (value === 1 && !id) {
      const autoPassward = generatePassword();
      const entity = { ...user, authentication_type: value, password: autoPassward };
      console.log(entity)
      dispatch(updateUser(entity));
      setPasswordGenerated(true);
    }
    else{
      const entity = { ...user, authentication_type: value, password: "" };
      console.log(entity)
      dispatch(updateUser(entity));
      setPasswordGenerated(false);
    }
  }
  // Function to get the gender type label
  const getGenderLabel = (value) => {
    const gender = Gender.find(gender => gender.value === value);
    return gender ? gender.label : "";
  };

  const handleDropdownGender = (value) => {
    const entity = { ...employee, gender: value };
    dispatch(updateForm(entity));
  };

  // Function to get the company type label
  const getCompanyLabel = (value) => {
    const company = companyLists.find(company => company.id === value);
    return company ? company.description : "";
  };

  const handleDropdownCompany = (value) => {
    const entity = { ...employee, company: {id: value} };
    dispatch(updateForm(entity));
  };

  // Function to get the WorkingSchedule type label
  const getScheduleLabel = (value) => {
    const schedule = WorkingSchedule.find(schedule => schedule.id === value);
    return schedule ? schedule.value : "";
  };

  const handleDropdownSchedule = (value) => {
    const entity = { ...employee, working_schedule: {id:value} };
    dispatch(updateForm(entity));
  };

  // Function to get the EmployeeCategory type label
  const getEmployeeCategoryLabel = (value) => {
    const category = EmployeeCategory.find(category => category.id === value);
    return category ? category.label : "";
  };

  const handleDropdownEmployeeCategory = (value) => {
    const entity = { ...employee, employee_category: {id:value} };
    console.log(entity);
    dispatch(updateForm(entity));
    
    // Pass the employee_category: id
    calculateProrate(value, date_joined);
  };

  // Function to get the ServiceLength type label
  const getServiceLengthLabel = (value) => {
    const length = ServiceLength.find(length => length.id === value);
    return length ? length.label : "";
  };

  const handleDropdownServiceLength = (value) => {
    const entity = { ...employee, service_length: {id:value} };
    console.log(entity);
    dispatch(updateForm(entity));
  };

  const handleBtnUpdateDetails = () => {
    setLoading(true);
    const entity = { ...user }
    dispatch(dismissUser(entity));

    if (photo.photo !== oldPhotoUrl && photo.photo !== undefined) {
      const entity5 = {
        photo: photo.photo,
        photo_changed: true
      }

      dispatch(replaceEmployeePhoto({id: id, ...entity5})).unwrap();
    }

    dispatch(dismissEmployee({id:id, ...employee, date_joined: moment(date_joined).format("YYYY-MM-DD")}))
      .unwrap()
      .then(() => {
        setLoading(false);
        Swal.fire({
          title: fullname + ' record is updated.',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        .then(() => {
          navigate(`/EmployeeList?searchText=${searchText}&active=${active}`);
        })
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire("Error", err.response.data, "error");
      });
  };

  const handleResign = (id) => {
    navigate(`/EmployeeResignationForm/${id}`);
  };

  const handleBtnAdd = async () => {
    setLoading(true);
    
    const entity = { ...user };

    try {
      // Check if the username exists by dispatching addUsername
      await dispatch(addUsername(entity)).unwrap();
      
      // If addUsername succeeds, proceed with user creation
      const addedUser = await dispatch(addUser(entity)).unwrap();
      const userId = addedUser.id;

      const entity2 = { 
        ...employee, 
        id: userId, 
        date_joined: moment(date_joined).format("YYYY-MM-DD") 
      };

      const entity3 = { 
        ...awardList, 
        advanced: "5", 
        employee: { 
          id: userId,
          service_length: {
            id: "P" 
          } 
        },
        effective_date: moment(date_joined).format("YYYY-MM-DD"), 
        leave_award_type: { id: "A" }, 
        employee_category: { id: employee_category.id },
        service_length: 0,
        service_length2: "P", 
        year: moment(date_joined).format("YYYY") 
      };

      const entity4 = { 
        ...leaveProrated, 
        employee: { id: userId }, 
        leave_awarded_this_year: annual, 
        medicalbenefit_awarded_for_this_year: medical, 
        new_leave_balance: prorated_leave_awarded, 
        prorate_date: moment(date_joined).format("YYYY-MM-DD"), 
        sickleave_awarded_for_this_year: sick, 
        year: moment(date_joined).format("YYYY") 
      };
  
      // Dispatch remaining actions with the new user ID
      await Promise.all([
        dispatch(addEmployee(entity2)).unwrap(),
        dispatch(addLeaveAwards(entity3)).unwrap(),
        dispatch(addLeaveProrated(entity4)).unwrap()
      ]);

      // Dispatch uploadEmployeePhoto action if needed
      // This condition ensures it's only dispatched if a photo was uploaded
      if (photo && photo.photo) {
        const entity5 = {
          photo: photo.photo,
          employee: { id: userId }
        };

        await dispatch(uploadEmployeePhoto({ id: userId, ...entity5 })).unwrap();
      }
      
      setLoading(false);
      dispatch(resetForm());
      Swal.fire({
        title: fullname + ' is added. Click OK to add another record.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } catch (err) {
      setLoading(false);
      Swal.fire("Error", err.response?.data || err.message, "error");
    }
  };

  const handlePhotoChange = async (e) => {
    const editor = await openEditor({ src: e.target.files[0] });
    // Store the old photo URL
    oldPhotoUrl = photo?.photo;
    // Update the photo URL with the new one
    const entity = { ...photo, photo: editor?.editedImage?.getDataURL() };
    console.log(entity);
    dispatch(updatePhoto(entity));
    setShowUploadButton(false);
  };

  const handleImageClick = () => {
    setShowUploadButton(true);
  };

  const handleBtnCancelImage = () => {
    setShowUploadButton(false);
  };

  return (
    <Container>
      {loading && (
          <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
              position: 'fixed',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: '9999',
          }}
          />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Employees</h1>

        <Row className="my-3">
          <Col md={6} sm={6}>
            <Row className="my-3 relative-container">
              <img
                src={photo?.photo || defaultPhoto}
                alt="Employee Photo"
                className="oval-image"
                onClick={handleImageClick}
              />
              {showUploadButton && (
                <Form className="upload-button-style">
                  <Form.Group  controlId="formFile" className="mt-3">
                    <Form.Control type="file" accept="image/*" onChange={handlePhotoChange} />
                    <br/>
                    <Button className="Button" onClick={handleBtnCancelImage}>Cancel</Button>
                  </Form.Group>
                </Form>
              )}
            </Row>
            
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Fullname</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control name="fullname" value={fullname || ""} placeholder="Fullname" onChange={handleFormChange}/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Username</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control name="username" value={username || ""} placeholder="Username" onChange={handleFormChange}/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Staff ID</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control name="sid" value={sid || ""} placeholder="Staff ID" onChange={handleFormChange}/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Email</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control name="email" value={email || ""} placeholder="Email" onChange={handleFormChange}/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">
                  Authentication Type
                </Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Dropdown>
                  <Dropdown.Toggle className="Button" style={{width: "100%"}}>{getAuthenticationLabel(authentication_type)}</Dropdown.Toggle>
                  <Dropdown.Menu style={{width: "100%"}}>
                    {Authentication.map((action, index) => (
                      <Dropdown.Item key={index} onClick={() => handleDropdownAuthentication(action.value)}>
                        {action.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            {passwordGenerated == true && !id && authentication_type == 1 && (
              <Row className="my-3">
                <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Password</Form.Label>
                </Col>
                <Col md={8} sm={8}>
                  <Form.Control name="password" value={password} onChange={handleFormChange}/>
                </Col>
              </Row>
            )}
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">
                  Gender
                </Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Dropdown>
                  <Dropdown.Toggle className="Button" style={{width: "100%"}}>{getGenderLabel(gender) || "MALE"}</Dropdown.Toggle>
                  <Dropdown.Menu style={{width: "100%"}}>
                    {Gender.map((action, index) => (
                      <Dropdown.Item key={index} onClick={() => handleDropdownGender(action.value)}>
                        {action.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">
                  Status
                </Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={status?.description || "Pending"} readOnly/>
              </Col>
            </Row>
          </Col>

          <Col md={6} sm={6}>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Date Join</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control type="date" name="date_joined" value={moment(date_joined).format("YYYY-MM-DD")} onChange={handleDateChange}/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Company</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Dropdown>
                <Dropdown.Toggle className="Button" style={{width: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                    {getCompanyLabel(company?.id).toUpperCase() || "4140add6-22b3-1324-05bc-9000ec3039e2"}
                </Dropdown.Toggle>
                  <Dropdown.Menu style={{ width: "100%", maxHeight: "250px", overflowY: "auto", }} >
                    {companyLists.map((company, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => handleDropdownCompany(company.id)}
                      >
                        {company.description.toUpperCase()}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Working Schedule</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Dropdown>
                  <Dropdown.Toggle className="Button" style={{width: "100%"}}>{getScheduleLabel(working_schedule?.id).toUpperCase() || "S"}</Dropdown.Toggle>
                  <Dropdown.Menu style={{width: "100%"}}>
                    {WorkingSchedule.map((action, index) => (
                      <Dropdown.Item key={index} onClick={() => handleDropdownSchedule(action.id)}>
                        {action.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Employee Category</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Dropdown>
                  <Dropdown.Toggle className="Button" style={{width: "100%"}}>{getEmployeeCategoryLabel(employee_category?.id) || "S"}</Dropdown.Toggle>
                  <Dropdown.Menu style={{width: "100%"}}>
                    {EmployeeCategory.map((action, index) => (
                      <Dropdown.Item key={index} onClick={() => handleDropdownEmployeeCategory(action.id)}>
                        {action.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>

            {id ? (
              <Row className="my-3">
                <Col md={4} sm={4}>
                  <Form.Label className="FormLabel">Service Length</Form.Label>
                </Col>
                <Col md={8} sm={8}>
                  <Dropdown>
                    <Dropdown.Toggle className="Button" style={{width: "100%"}}>{getServiceLengthLabel(service_length?.id).toUpperCase() || "UK"}</Dropdown.Toggle>
                    <Dropdown.Menu style={{width: "100%"}}>
                      {ServiceLength.map((action, index) => (
                        <Dropdown.Item key={index} onClick={() => handleDropdownServiceLength(action.id)}>
                          {action.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            ) : (
              <>
              <hr/>
              <Row className="my-3">
                <Col md={4} sm={4}>
                  <Form.Label className="FormLabel">Entitled Leave to be deducted (%)</Form.Label>
                </Col>
                <Col md={8} sm={8}>
                  <Form.Control name="prorated_pct_first_year" value={prorated_pct_first_year} onChange={handleProrated}/>
                </Col>
              </Row>

              <Row className="my-3">
                <Col md={5} sm={1}>
                </Col>
                <Col md={4} sm={1}>
                  <Form.Label className="FormLabel">Awarded</Form.Label>
                </Col>
                <Col md={1} sm={6}>
                  <Form.Label className="FormLabel">Entitled</Form.Label>
                </Col>
              </Row>

              <Row className="my-3">
                <Col md={4} sm={4}>
                  <Form.Label className="FormLabel">Annual Leave</Form.Label>
                </Col>
                <Col md={4} sm={1}>
                  <Form.Control name="annual" value={annual} onChange={handleLeaveAwards}/>
                </Col>
                <Col md={4} sm={6}>
                  <Form.Control name="prorated_leave_awarded" value={prorated_leave_awarded} onChange={handleProrated}/>
                </Col>
              </Row>

              <Row className="my-3">
                <Col md={4} sm={4}>
                  <Form.Label className="FormLabel">Sick Leave</Form.Label>
                </Col>
                <Col md={4} sm={1}>
                  <Form.Control name="sick" value={sick} onChange={handleLeaveAwards}/>
                </Col>
                <Col md={4} sm={6}>
                  <Form.Control name="prorated_sickleave_awarded" value={prorated_sickleave_awarded} onChange={handleProrated}/>
                </Col>
              </Row>

              <Row className="my-3">
                <Col md={4} sm={4}>
                  <Form.Label className="FormLabel">Medical Claim</Form.Label>
                </Col>
                <Col md={4} sm={1}>
                  <Form.Control name="medical" value={medical} onChange={handleLeaveAwards}/>
                </Col>
                <Col md={4} sm={6}>
                  <Form.Control name="prorated_medicalbenefit_awarded" value={prorated_medicalbenefit_awarded} onChange={handleProrated}/>
                </Col>
              </Row>

              <Row className="my-3">
                <Col md={4} sm={4}>
                  <Form.Label className="FormLabel">Hospitalization Leave</Form.Label>
                </Col>
                <Col md={8} sm={8}>
                  <Form.Control name="hospitalization" value={hospitalization} onChange={handleLeaveAwards}/>
                </Col>
              </Row>

              <Row className="my-3">
                <Col md={4} sm={4}>
                  <Form.Label className="FormLabel">Maternity Leave</Form.Label>
                </Col>
                <Col md={8} sm={8}>
                  <Form.Control name="maternity" value={maternity} onChange={handleLeaveAwards}/>
                </Col>
              </Row>
              </>
            )}

            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Supervisor</Form.Label>
              </Col>
              <Col md={8} sm={8}>
              <Select
                name="supervisor"
                onChange={handleDropdownSupervisor}
                options={options}
                value={selectedOption}
                placeholder="Select Supervisor"
              />
              </Col>
            </Row>

            {id && status?.description!=="Active" && (
              <Row className="my-3">
                <Col md={4} sm={4}>
                  <Form.Label className="FormLabel">
                    Date Left
                  </Form.Label>
                </Col>
                <Col md={8} sm={8}>
                  <Form.Control type="date" value={moment(date_left).format("YYYY-MM-DD")}/>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        {showResetPwd == true && (
          <>
          <Card style={{ backgroundColor: "gray", marginBottom: "20px" }}>
            <Row className="my-3 mx-3">
              <Col md={6} sm={6}>
                <Form.Label className="FormLabel">New Password</Form.Label>
              </Col>
              <Col md={6} sm={6}>
                <Form.Control 
                  type="password" 
                  value={newPassword} 
                  onChange={(e) => setNewPassword(e.target.value)} 
                />
              </Col>
            </Row>
            <Row className="my-3 mx-3">
              <Col md={6} sm={6}>
                <Form.Label className="FormLabel">
                  Repeat New Password
                </Form.Label>
              </Col>
              <Col md={6} sm={6}>
                <Form.Control 
                  type="password" 
                  value={repeatNewPassword} 
                  onChange={(e) => setRepeatNewPassword(e.target.value)} 
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-between my-3 mx-3">
              <ButtonGroup style={{ marginLeft: "auto" }}>
                <Button className="Button" onClick={handleBtnCancel}>
                  Cancel
                </Button>
                &nbsp;&nbsp;
                <Button className="Button" onClick={handleBtnSubmit}>
                  Update
                </Button>
              </ButtonGroup>
            </div>
          </Card>
          <br/>
          </>
        )}

        {!id ? (
        <div className="d-flex justify-content-between my-2">
          <ButtonGroup>
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button className="Button" onClick={handleBtnAdd}>
              Submit
            </Button>
          </ButtonGroup>
        </div>
        ) : (
        <>
        <div className="d-flex justify-content-between my-2">
          <ButtonGroup>
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>

          
          <ButtonGroup>
            <Button className="Button" onClick={handleBtnDelete}>
              Delete
            </Button>
            &nbsp; &nbsp;

            {status?.description!=="Active" ? (
              <Button className="Button" disabled>
                Resign
              </Button>
            ):(
              <Button className="Button" onClick={() => handleResign(employee.id)}>
                Resign
              </Button>
            )}
            
            &nbsp; &nbsp;
            <Button className="Button" onClick={handleBtnDismiss}>
              Dismiss
            </Button>
            &nbsp; &nbsp;
            {id && authentication_type===1 && (
              <>
              <Button className="Button" onClick={handleBtnResetPwd}>
                Reset Password
              </Button>
              &nbsp; &nbsp;
              </>
            )}
            
            <Button className="Button" onClick={handleBtnUpdateDetails}>
              Update
            </Button>
          </ButtonGroup>
        </div>
        </>
        )}
      </Card>
    </Container>
  );
};
export default EmployeeForm;