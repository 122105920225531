// import { Container, Card, Form, Table, Button, ButtonGroup, Col } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import moment from "moment/moment";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
// import { ThreeDots } from 'react-loader-spinner';
// import { listStaffOnList, updateSearch, updateCurrentPage } from "../../3.Store/StaffOnLeaveSlice";

// const StaffOnLeaveList = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const lists = useSelector(state => state.StaffOnLeaveSlice.lists);
//   const search = useSelector((state) => state.StaffOnLeaveSlice.search);
//   const currentPage = useSelector((state) => state.StaffOnLeaveSlice.currentPage);
//   const totalPages = useSelector((state) => state.StaffOnLeaveSlice.totalPages);
//   const { searchText } = search;
//   const [loading, setLoading] = useState(false);

//   //use to filter the table based on searchText
//   const [filteredStaffList, setFilteredStaffList] = useState(lists);

//   const pageSize = 20; // Number of items per page

//   useEffect(() => {
//     setLoading(true);
//     dispatch(listStaffOnList({page:currentPage}))
//     .then(() => {
//       setLoading(false);
//     })
//     .catch(() => {
//       setLoading(false);
//     });
//   }, []);

//   useEffect(() => {
//     setFilteredStaffList(
//       lists.filter(employee =>
//         employee.employee.fullname.toLowerCase().includes(searchText.toLowerCase())
//       )
//     );
//   }, [lists, searchText]);

//   const handleSearch = (e) => {
//     const entity = {...search, searchText: e.target.value}
//     console.log(entity)
//     dispatch(updateSearch(entity));
//   }

//   const handleBtnBack = () => {
//     navigate(-1);
//   };

//   const handlePreviousPage = () => {
//     setLoading(true);
//     if (currentPage > 1) {
//       dispatch(updateCurrentPage(currentPage - 1));
//       dispatch(listStaffOnList({page:currentPage - 1}))
//       .then(() => {
//         setLoading(false);
//       })
//       .catch(() => {
//         setLoading(false);
//       });
//     }
//   };

//   const handleNextPage = () => {
//     setLoading(true);
//     if (currentPage < totalPages) {
//       dispatch(updateCurrentPage(currentPage + 1));
//       dispatch(listStaffOnList({page:currentPage + 1}))
//       .then(() => {
//           setLoading(false);
//       })
//       .catch(() => {
//           setLoading(false);
//       });
//     }
//   };

//   const renderStaffList = () => {
//     const startIndex = (currentPage - 1) * pageSize;
//     const endIndex = Math.min(startIndex + pageSize, filteredStaffList.length);
    
//     return filteredStaffList.slice(startIndex, endIndex).map(employee => (
//       <tr key={employee.id} style={{ cursor: "pointer" }}>
//         <td>{employee.employee.fullname}</td>
//         <td>{moment(employee.from_date.date).format("DD MMMM YYYY")} ({employee.from_ampm})</td>
//         <td>{moment(employee.to_date.date).format("DD MMMM YYYY")} ({employee.to_ampm})</td>
//         <td>{employee.number_of_days}</td>
//         <td>{employee.leave_type.description}</td>
//       </tr>
//     ));
//   };

//   return (
//     <Container>
//       {loading && (
//         <ThreeDots
//           visible={loading}
//           height="80"
//           width="80"
//           color="#4fa94d"
//           radius="9"
//           ariaLabel="three-dots-loading"
//           wrapperStyle={{
//             position: 'fixed',
//             top: '0',
//             left: '0',
//             width: '100%',
//             height: '100%',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             backgroundColor: 'rgba(0, 0, 0, 0.5)',
//             zIndex: '9999',
//           }}
//         />
//       )}
//       <Card className="CardContent">
//         <h1 className="TitleHeader">Staff On Leave</h1>

//         <Col style={{ marginLeft: "auto" }}>
//           <Form.Label className="FormLabel">{`${currentPage} of ${totalPages}`}</Form.Label> &ensp;
//           <Form.Label className="FormLabel" onClick={handlePreviousPage} disabled={currentPage === 1}><FontAwesomeIcon icon={faChevronLeft} style={{color: "#ffffff",}} /></Form.Label> &ensp;
//           <Form.Label className="FormLabel" onClick={handleNextPage} disabled={currentPage === totalPages}><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /></Form.Label>
//         </Col>

//         <Form.Control placeholder="Search" value={searchText} onChange={handleSearch} />

//         <Table className="mt-5">
//           <thead>
//             <tr>
//               <th>Staff Name</th>
//               <th>Date Start</th>
//               <th>Date End</th>
//               <th>No. of Days</th>
//               <th>Leave Type</th>
//             </tr>
//           </thead>
//           {!loading && (
//             <tbody>
//               {filteredStaffList.length > 0 ? (
//                 renderStaffList()
//               ) : (
//                 <tr>
//                   <td colSpan="5">No results found</td>
//                 </tr>
//               )}
//             </tbody>
//           )}
//         </Table>

//         <Col style={{ marginLeft: "auto" }}>
//           <Form.Label className="FormLabel">{`${currentPage} of ${totalPages}`}</Form.Label> &ensp;
//           <Form.Label className="FormLabel" onClick={handlePreviousPage} disabled={currentPage === 1}><FontAwesomeIcon icon={faChevronLeft} style={{color: "#ffffff",}} /></Form.Label> &ensp;
//           <Form.Label className="FormLabel" onClick={handleNextPage} disabled={currentPage === totalPages}><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /></Form.Label>
//         </Col>

//       </Card>
//     </Container>
//   );
// };
// export default StaffOnLeaveList;

import { Container, Card, Form, Table, Button, ButtonGroup, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { ThreeDots } from 'react-loader-spinner';
import { listStaffOnList, updateSearch, updateCurrentPage, CountStaffOnList } from "../../3.Store/StaffOnLeaveSlice";

const StaffOnLeaveList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lists = useSelector(state => state.StaffOnLeaveSlice.lists);
  const search = useSelector((state) => state.StaffOnLeaveSlice.search);
  const currentPage = useSelector((state) => state.StaffOnLeaveSlice.currentPage);
  const totalPages = useSelector((state) => state.StaffOnLeaveSlice.totalPages);
  const { searchText } = search;
  const [loading, setLoading] = useState(false);

  const roles = JSON.parse(localStorage.getItem("roles"));
  const isHR = roles.includes("HR-L-REC") || roles.includes("SU-L-APPROVAL") || roles.includes("SU-M-BENEFIT");

  //use to filter the table based on searchText
  const [filteredStaffList, setFilteredStaffList] = useState(lists);

  useEffect(() => {
    setLoading(true);
    dispatch(listStaffOnList({page:currentPage}))
    .then(() => {
      dispatch(CountStaffOnList());
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setFilteredStaffList(
      lists.filter(employee =>
        employee.employee.fullname.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [lists, searchText]);

  const handleSearch = (e) => {
    const entity = {...search, searchText: e.target.value}
    console.log(entity)
    dispatch(updateSearch(entity));
  }

  const handleBtnBack = () => {
    navigate(-1);
  };

  const handlePreviousPage = () => {
    setLoading(true);
    if (currentPage > 1) {
      dispatch(updateCurrentPage(currentPage - 1));
      dispatch(listStaffOnList({page:currentPage - 1}))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    }
  };

  const handleNextPage = () => {
    setLoading(true);
    if (currentPage < totalPages) {
      dispatch(updateCurrentPage(currentPage + 1));
      dispatch(listStaffOnList({page:currentPage + 1}))
      .then(() => {
          setLoading(false);
      })
      .catch(() => {
          setLoading(false);
      });
    }
  };

  const renderStaffList = () => {
    return filteredStaffList.map(employee => (
      <tr key={employee.id} style={{ cursor: "pointer" }}>
        <td>{employee.employee.fullname}</td>
        <td>{moment(employee.from_date.date).format("DD MMMM YYYY")} ({employee.from_ampm})</td>
        <td>{moment(employee.to_date.date).format("DD MMMM YYYY")} ({employee.to_ampm})</td>
        <td>{employee.number_of_days}</td>
        <td>{employee.leave_type.description}</td>
      </tr>
    ));
  };

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Staff On Leave</h1>

        <Col style={{ marginLeft: "auto" }}>
          <Form.Label className="FormLabel">{`${currentPage} of ${totalPages}`}</Form.Label> &ensp;
          {currentPage > 1 ? (
            <Form.Label className="FormLabel" onClick={handlePreviousPage}>
              <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#ffffff" }} />
            </Form.Label>
          ) : (
            <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Form.Label>
          )}
          &ensp;
          {currentPage < totalPages ? (
            <Form.Label className="FormLabel" onClick={handleNextPage}>
              <FontAwesomeIcon icon={faChevronRight} style={{ color: "#ffffff" }} />
            </Form.Label>
          ) : (
            <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
              <FontAwesomeIcon icon={faChevronRight} />
            </Form.Label>
          )}
        </Col>

        <Form.Control placeholder="Search" value={searchText} onChange={handleSearch} />

        <Table className="mt-5">
          <thead>
            <tr>
              <th>Staff Name</th>
              <th>Date Start</th>
              <th>Date End</th>
              <th>No. of Days</th>
              <th>Leave Type</th>
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {filteredStaffList.length > 0 ? (
                renderStaffList()
              ) : (
                <tr>
                  <td colSpan="5">No results found</td>
                </tr>
              )}
            </tbody>
          )}
        </Table>

        <Col style={{ marginLeft: "auto" }}>
          <Form.Label className="FormLabel">{`${currentPage} of ${totalPages}`}</Form.Label> &ensp;
          {currentPage > 1 ? (
            <Form.Label className="FormLabel" onClick={handlePreviousPage}>
              <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#ffffff" }} />
            </Form.Label>
          ) : (
            <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Form.Label>
          )}
          &ensp;
          {currentPage < totalPages ? (
            <Form.Label className="FormLabel" onClick={handleNextPage}>
              <FontAwesomeIcon icon={faChevronRight} style={{ color: "#ffffff" }} />
            </Form.Label>
          ) : (
            <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
              <FontAwesomeIcon icon={faChevronRight} />
            </Form.Label>
          )}
        </Col>

      </Card>
    </Container>
  );
};
export default StaffOnLeaveList;
