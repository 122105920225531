import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import AuthSlice from "./AuthSlice";
import LoginSlice from "./LoginSlice";
import ApplicationSlice from "./ApplicationSlice";
import HolidaySlice from "./HolidaySlice"
import LeaveMovementSlice from "./LeaveMovementSlice";
import LeaveSummarySlice from "./LeaveSummarySlice";
import LeaveWithdrawalSlice from "./LeaveWithdrawalSlice";
import ReviewMedicalClaimsSlice from "./ReviewMedicalClaimsSlice"
import LeaveAwardsSlice from "./LeaveAwardsSlice";
import UserAccessSlice from "./UserAccessSlice";
import ReviewLeaveApplicationSlice from "./ReviewLeaveApplicationSlice";
import EmployeeSlice from "./EmployeeSlice";
import StaffOnLeaveSlice from "./StaffOnLeaveSlice";
import LeaveAccrualSlice from "./LeaveAccrualSlice";
import LeaveApprovalSlice from "./LeaveApprovalSlice";
import SUReviewClaimSlice from "./SUReviewClaimSlice";
import MedicalClaimSlice from "./MedicalClaimSlice";

export default configureStore({
  reducer: {
    AuthSlice: AuthSlice,
    LoginSlice: LoginSlice,
    ApplicationSlice: ApplicationSlice,
    HolidaySlice: HolidaySlice,
    LeaveMovementSlice: LeaveMovementSlice,
    LeaveSummarySlice: LeaveSummarySlice,
    LeaveWithdrawalSlice: LeaveWithdrawalSlice,
    ReviewMedicalClaimsSlice: ReviewMedicalClaimsSlice,
    LeaveAwardsSlice: LeaveAwardsSlice,
    UserAccessSlice: UserAccessSlice,
    ReviewLeaveApplicationSlice: ReviewLeaveApplicationSlice,
    EmployeeSlice: EmployeeSlice,
    StaffOnLeaveSlice: StaffOnLeaveSlice,
    LeaveAccrualSlice: LeaveAccrualSlice,
    LeaveApprovalSlice: LeaveApprovalSlice,
    SUReviewClaimSlice: SUReviewClaimSlice,
    MedicalClaimSlice: MedicalClaimSlice,
  },
  middleware: [thunk],
});
