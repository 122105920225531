import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import Swal from "sweetalert2";
import {
  Container,
  Card,
  Form,
  Table,
  ButtonGroup,
  Button,
  Dropdown,
  InputGroup,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faBoltLightning,
  faHourglass,
  faCheck,
  faReply,
  faMinus,
  faTimes,
  faSearch,
  faChevronRight,
  faChevronLeft,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { ActionHR } from "../../2.Commons/DropdownOption";
import { 
  updateCurrentPage, 
  getHRReviewPagination, 
  getHRReviewPageCount,
  ReviewedApplication,
  ApplicationNotification,
  searchHRReviewPagination,
  searchHRReviewPageCount,
  updateSearch
} from "../../3.Store/ReviewLeaveApplicationSlice";
import { ThreeDots } from 'react-loader-spinner';

const ReviewLeaveList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const leaveLists = useSelector(state => state.ReviewLeaveApplicationSlice.lists) || [];
  const currentPage = useSelector(state => state.ReviewLeaveApplicationSlice.currentPage);
  const totalPages = useSelector(state => state.ReviewLeaveApplicationSlice.totalPages);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const search = useSelector((state) => state.ReviewLeaveApplicationSlice.search);
  const { 
    searchText, 
    advancedLeave, 
    annualLeave,
    hospitalizationLeave,
    maternityLeave,
    otherLeave,
    sickLeave,
    specialLeave,
    unpaidLeave 
  } = search;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isSearchActive) {
      setLoading(true);
      const isAnyLeaveTrue = search.advancedLeave || search.annualLeave || search.hospitalizationLeave || 
                             search.maternityLeave || search.otherLeave || search.sickLeave || 
                             search.specialLeave || search.unpaidLeave;
  
      if (searchText === "" && isAnyLeaveTrue) {
        const entity = { ...search };
        dispatch(searchHRReviewPagination({ body: entity, currentPage }))
          .then(() => {
            dispatch(searchHRReviewPageCount(entity));
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else if (searchText !== "") {
        const entity = { ...search };
        dispatch(searchHRReviewPagination({ body: entity, currentPage }))
          .then(() => {
            dispatch(searchHRReviewPageCount(entity));
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        dispatch(getHRReviewPagination({ currentPage }))
          .then(() => {
            dispatch(getHRReviewPageCount());
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  }, [currentPage, isSearchActive]);  

  const handleSearch = (e) => {
    const entity = {...search, searchText: e.target.value}
    dispatch(updateSearch(entity));
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleBtnSearch();
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    const entity = { ...search,  [name]: checked};
    console.log(entity)
    dispatch(updateSearch(entity));
  };

  const handleBtnSearch = () => {
    setLoading(true);
    setIsSearchActive(true);
    dispatch(updateCurrentPage(1)); // Set current page to 1
    const entity = { ...search };
    console.log(entity);
    dispatch(searchHRReviewPagination({ body:entity, currentPage:1 }))
      .then(() => {
        dispatch(searchHRReviewPageCount(entity));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleDropdownAction = (actionValue) => {
    if (actionValue === "reviewedAction") {
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          dispatch(ReviewedApplication(selectedCheckboxes))
            .unwrap()
            .then(() => {
              setLoading(false)
              Swal.fire("Leave Application Reviewed", "", "success", "OK");
              navigate(`/ReviewLeaveList`);
              if(searchText === ""){
                dispatch(getHRReviewPagination({ currentPage }))
                .then(() => {
                  dispatch(getHRReviewPageCount());
                  setLoading(false);
                })
                .catch(() => {
                  setLoading(false);
                });
              }
              else if (searchText !== ""){
                const entity = { ...search };
                console.log(entity);
                dispatch(searchHRReviewPagination({ body:entity, currentPage }))
                  .then(() => {
                    dispatch(searchHRReviewPageCount(entity));
                    setLoading(false);
                  })
                  .catch(() => {
                    setLoading(false);
                  });
              }
              setSelectedCheckboxes([]); // Clear checkboxes
            })
            .catch((err) => {
              Swal.fire("Error", err.response.data, "error", "OK");
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
              'Cancelled',
              'Review cancelled',
              'error'
          );
        }
      });
    }
    else if (actionValue === "sendNotificationAction") {
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Send Notification',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          dispatch(ApplicationNotification(selectedCheckboxes))
            .unwrap()
            .then(() => {
              setLoading(false);
              Swal.fire("Notification Sent", "", "success", "OK");
              navigate(`/ReviewLeaveList`);
              if(searchText === ""){
                dispatch(getHRReviewPagination({ currentPage }))
                .then(() => {
                  dispatch(getHRReviewPageCount());
                  setLoading(false);
                })
                .catch(() => {
                  setLoading(false);
                });
              }
              else if (searchText !== ""){
                const entity = { ...search };
                console.log(entity);
                dispatch(searchHRReviewPagination({ body:entity, currentPage }))
                  .then(() => {
                    dispatch(searchHRReviewPageCount(entity));
                    setLoading(false);
                  })
                  .catch(() => {
                    setLoading(false);
                  });
              }
              setSelectedCheckboxes([]); // Clear checkboxes
            })
            .catch((err) => {
              Swal.fire("Error", err.response.data, "error", "OK");
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
              'Cancelled',
              'Send notification cancelled',
              'error'
          );
        }
      });
    }
  };

  const handleBtnBack = () => {
    navigate(-1);
  };

  const handleClickView = (leaveId) => {
    navigate(`/ReviewLeaveForm/${leaveId}`)
  };

  const handleCheckChange = (e, id) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedCheckboxes(prevState => [...prevState, id]);
    } else {
      setSelectedCheckboxes(prevState => prevState.filter(item => item !== id));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      dispatch(updateCurrentPage(currentPage - 1));
      if (!isSearchActive) {
        dispatch(getHRReviewPageCount({ currentPage: currentPage - 1 }));
      }
      else {
        setLoading(true);
        const entity = { ...search };
        dispatch(searchHRReviewPagination({ currentPage: currentPage - 1, body:entity }))
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(updateCurrentPage(currentPage + 1));
      if(!isSearchActive) {
        dispatch(getHRReviewPagination({ currentPage: currentPage + 1 }));
      }
      else {
        setLoading(true);
        const entity = { ...search };
        dispatch(searchHRReviewPagination({ currentPage: currentPage + 1, body:entity }))
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    console.log(selectedCheckboxes);
  }, [selectedCheckboxes]);

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">HR Review Leave Applications</h1>
        <h1 className="MiniTitleHeader">List</h1>

        {/* Pagination */}
        <Col style={{ marginLeft: "auto" }}>
          <Form.Label className="FormLabel">{`${currentPage} of ${totalPages}`}</Form.Label> &ensp;
          <Form.Label className="FormLabel" onClick={handlePreviousPage} disabled={currentPage === 1}><FontAwesomeIcon icon={faChevronLeft} style={{color: "#ffffff",}} /></Form.Label> &ensp;
          <Form.Label className="FormLabel" onClick={handleNextPage} disabled={currentPage === totalPages}><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /></Form.Label>
        </Col>

        <InputGroup className="mb-3">
          <Form.Control placeholder="Search" name="searchText" value={searchText} onChange={handleSearch} onKeyPress={handleKeyPress} />
          <Button className="SearchIconBtn" onClick={handleBtnSearch}>
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroup>

        <div className="FormLabel">
          <Form.Check inline type="checkbox" label="Annual Leave" name="annualLeave" checked={annualLeave} onChange={handleCheckboxChange}/>
          <Form.Check inline type="checkbox" label="Advanced Leave" name="advancedLeave" checked={advancedLeave} onChange={handleCheckboxChange}/>
          <Form.Check inline type="checkbox" label="Sick Leave" name="sickLeave" checked={sickLeave} onChange={handleCheckboxChange}/>
          <Form.Check inline type="checkbox" label="Special Leave" name="specialLeave" checked={specialLeave} onChange={handleCheckboxChange}/>
          <Form.Check inline type="checkbox" label="Other Leave" name="otherLeave" checked={otherLeave} onChange={handleCheckboxChange}/>
          <Form.Check inline type="checkbox" label="Unpaid Leave" name="unpaidLeave" checked={unpaidLeave} onChange={handleCheckboxChange}/>
          <Form.Check inline type="checkbox" label="Maternity Leave" name="maternityLeave" checked={maternityLeave} onChange={handleCheckboxChange}/>
          <Form.Check inline type="checkbox" label="Hospitalization Leave" name="hospitalizationLeave" checked={hospitalizationLeave} onChange={handleCheckboxChange}/>
        </div>

        <Table className="mt-3">
          <thead>
            <tr>
              <th>Date Applied</th>
              <th>Employee</th>
              <th>Type</th>
              <th>No. of Days Applying</th>
              <th>Status</th>
              <th>
                <FontAwesomeIcon icon={faEye} />
              </th>
              <th>
                <FontAwesomeIcon icon={faBoltLightning} />
              </th>
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {leaveLists.length > 0 ? (
                leaveLists.map(employee => (
                  <tr key={employee.id} style={{ cursor: "pointer" }}>
                    <td onClick={() => handleClickView(employee.id)}>{moment(employee.created).format("YYYY-MM-DD")}</td>
                    <td onClick={() => handleClickView(employee.id)}>{employee.employee.fullname}</td>
                    <td onClick={() => handleClickView(employee.id)}>{employee?.leave_type?.description}</td>
                    <td onClick={() => handleClickView(employee.id)}>{employee.number_of_days}</td>
                    <td onClick={() => handleClickView(employee.id)}>
                      {employee.status.description == "Approved by Supervisor" && (
                        <FontAwesomeIcon icon={faCheck} color="#28A744" />
                      )}
                      {employee.status.description == "Rejected by Supervisor" && (
                        <FontAwesomeIcon icon={faTimes} color="#DC3545" />
                      )}
                      {employee.status.description == "Pending Approval" && (
                        <FontAwesomeIcon icon={faHourglass} style={{color: "#FFD43B",}} />
                      )}
                      {employee.status.description == "Approved by HR" && (
                        <FontAwesomeIcon icon={faCheck} color="#17A2B8" />
                      )}
                      {employee.status.description == "Rejected by HR" && (
                        <FontAwesomeIcon icon={faTimes} color="#17A2B8" />
                      )}
                      {employee.status.description == "Leave Withdrawn by staff with Approval" && (
                        <FontAwesomeIcon icon={faReply} color="#17A2B8" />
                      )}
                    </td>
            
                    <td onClick={() => handleClickView(employee.id)}>
                      {employee.reviewed == -1 && (
                        <FontAwesomeIcon icon={faClose} style={{color: "#f22c2c",}} />
                      )}
                      {employee.reviewed == 1 && (
                        <FontAwesomeIcon icon={faCheck} style={{color: "#3fa64b",}} />
                      )}
                    </td>
                    
                    <td>
                      <Form.Check
                        type="checkbox"
                        id={`checkbox-${employee.id}`}
                        name={`checkbox-${employee.id}`}
                        onChange={(e) => handleCheckChange(e, employee.id)}
                        checked={selectedCheckboxes.includes(employee.id)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">No results found</td>
                </tr>
              )}
            </tbody>
          )}
        </Table>

        <table>
          <tbody>
            <tr>
              <td className="FormLabel">
                <FontAwesomeIcon icon={faHourglass} color="#FFC107" />
                &nbsp;Pending
              </td>
              <td className="FormLabel">
                <FontAwesomeIcon icon={faCheck} color="#28A744" />
                &nbsp;Approved (Supervisor)
              </td>
              <td className="FormLabel">
                <FontAwesomeIcon icon={faCheck} color="#17A2B8" />
                &nbsp;Approved (HR)
              </td>
              <td className="FormLabel">
                <FontAwesomeIcon icon={faReply} color="#17A2B8" />
                &nbsp;Withdrawn
              </td>
            </tr>
            <tr>
              <td className="FormLabel">
                <FontAwesomeIcon icon={faMinus} color="#FFC107" />
                &nbsp;Deleted
              </td>
              <td className="FormLabel">
                <FontAwesomeIcon icon={faTimes} color="#DC3545" />
                &nbsp;Rejected (Supervisor)
              </td>
              <td className="FormLabel">
                <FontAwesomeIcon icon={faTimes} color="#17A2B8" />
                &nbsp;Rejected (HR)
              </td>
              <td className="FormLabel">
                <FontAwesomeIcon icon={faReply} color="#FFC107" />
                &nbsp;Withdrawning
              </td>
              <td className="FormLabel">
                <FontAwesomeIcon icon={faReply} color="#DC3545" />
                &nbsp;Withdrawn Rejected
              </td>
            </tr>
          </tbody>
        </table>

        <div className="d-flex justify-content-between my-2">
          <ButtonGroup>
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>
          <Dropdown>
            <Dropdown.Toggle className="Button">Action</Dropdown.Toggle>
            <Dropdown.Menu>
              {ActionHR.map((action, index) => (
                <Dropdown.Item key={index} onClick={() => handleDropdownAction(action.value)}>
                  {action.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card>
    </Container>
  );
};
export default ReviewLeaveList;
