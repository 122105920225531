import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../4.Utils/apiServices";
import moment from "moment/moment";

const namespace = "review/claims";

//Search of Review Claims + Pagination
export const searchReviewClaimsPagination = createAsyncThunk(
  `${namespace}/search`,
  async ({ entity, currentPage }, { rejectWithValue }) => {
      const api = "search_review_medical_claims_name+pagination";
      const replace = { 
          search: "{page}",
          replace: currentPage 
      };
      const opt = {
          replace: replace,
          body: entity
      };
      try {
      const response = await apiServices(api, opt);
      return response.data;
      } catch (err) {
      return rejectWithValue(err);
      }
  }
);

//Search of Review Claims Page Count
export const searchReviewClaimsPageCount = createAsyncThunk(
  `${namespace}/SearchReviewClaimsPageCount`,
  async (body, { rejectWithValue }) => {
    const api = "search_review_medical_claims_name_pageCount";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Lists of Review Claims + Pagination
export const getReviewClaimsPagination = createAsyncThunk(
    `${namespace}/page`,
    async ({ body, currentPage }, { rejectWithValue }) => {
        const api = "list_review_medical_claims+pagination";
        const replace = { 
            search: "{page}", 
            replace: currentPage 
        };
        const opt = {
            replace: replace,
            body: body
        };
        try {
        const response = await apiServices(api, opt);
        return response.data;
        } catch (err) {
        return rejectWithValue(err);
        }
    }
);

//Lists of Review Claims Page Count
export const getReviewClaimsPageCount = createAsyncThunk(
  `${namespace}/ReviewClaimsPageCount`,
  async (body, { rejectWithValue }) => {
    const api = "list_review_medical_claims_pageCount";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Lists of Review Claims Total Count
export const getReviewClaimsTotalCount = createAsyncThunk(
    `${namespace}/ReviewClaimsTotalCount`,
    async (body, { rejectWithValue }) => {
      const api = "list_review_medical_claims_totalCount";
      const opt = {
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Download Excel
export const downloadReviewMedicalClaims = createAsyncThunk(
  `${namespace}/download`,
  async (body, { rejectWithValue }) => {
    const api = "download_review_medical_claims";
    const replace = { 
      search: "{fromDate}",
      replace: body.fromDate 
    };
    const replace2 = { 
      search: "{toDate}",
      replace: body.toDate 
    };
    const replace3 = { 
      search: "{searchText}",
      replace: body.searchText 
    };
    const opt = {
      replace: [replace, replace2, replace3],
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Approve Claims
export const approveReviewMedicalClaims = createAsyncThunk(
  `${namespace}/approve`,
  async (body, { rejectWithValue }) => {
    const api = "approve_medical_claims";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Reject Claims
export const rejectReviewMedicalClaims = createAsyncThunk(
  `${namespace}/reject`,
  async (body, { rejectWithValue }) => {
    const api = "reject_medical_claims";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Attachments
export const getAttachments = createAsyncThunk(
  `${namespace}/get`,
  async (body, { rejectWithValue }) => {
    const api = "get_medical_benefits_attachment";
    const replace = {
      search: "{medical_benefit_attachment_id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Get A Medical Claim
export const getAMedicalClaim = createAsyncThunk(
  `${namespace}/medical/claim/details`,
  async (body, { rejectWithValue }) => {
    const api = "getAmedicalClaim";
    const replace = {
      search: "{medical_benefit_id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const getFirstDayOfPreviousMonth = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const firstDayOfPreviousMonth = new Date(year, month - 1, 1);
  return firstDayOfPreviousMonth;
};

const initialState = {
    search: {
      searchText: "",
      fromDate: moment(getFirstDayOfPreviousMonth()).format("YYYY-MM-DD"),
      toDate: moment(new Date()).format("YYYY-MM-DD")
    },
    lists: [],
    loading: false,
    error: false,
    currentPage: 1,
    totalPages: "",
    currentPageCount: 0,
    attachment: {},
    details: {}
}

const ReviewMedicalClaimsSlice = createSlice({
    name: namespace,
    initialState: initialState,
    reducers: {
      updateCurrentPage(state, action) {
        return { ...state, currentPage: action.payload };
      },

      updateAttachment(state, action) {
        return { ...state, attachment: action.payload };
      },

      // update entity
      updateSearch(state, action) {
        state.search = action.payload;
      },

      getList(state, action) {
        return {
            ...state,
            list: state.lists.find((list) => list.id == action.payload),
        };
      },
    },
    extraReducers: (builder) => {
      builder
      // Get Medical Claims by Page
      .addCase(getReviewClaimsPagination.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getReviewClaimsPagination.fulfilled, (state, action) => {
        state.loading = false;
        state.lists = action.payload;
      })
      .addCase(getReviewClaimsPagination.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      // Search Medical Claim by Name
      .addCase(searchReviewClaimsPagination.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(searchReviewClaimsPagination.fulfilled, (state, action) => {
        state.loading = false;
        state.lists = action.payload;
      })
      .addCase(searchReviewClaimsPagination.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      // Search Medical Claims by Page
      .addCase(searchReviewClaimsPageCount.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(searchReviewClaimsPageCount.fulfilled, (state, action) => {
        state.loading = false;
        state.totalPages = action.payload;
      })
      .addCase(searchReviewClaimsPageCount.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      // Get Medical Claims by Page
      .addCase(getReviewClaimsPageCount.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getReviewClaimsPageCount.fulfilled, (state, action) => {
        state.loading = false;
        state.totalPages = action.payload;
      })
      .addCase(getReviewClaimsPageCount.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      //Get Attachment
      .addCase(getAttachments.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAttachments.fulfilled, (state, action) => {
        state.loading = false;
        state.attachment = action.payload;
      })
      .addCase(getAttachments.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      //Get Attachment
      .addCase(getAMedicalClaim.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAMedicalClaim.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(getAMedicalClaim.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
    }
});

export const { updateCurrentPage, updateSearch, getList, updateAttachment } = ReviewMedicalClaimsSlice.actions;

export default ReviewMedicalClaimsSlice.reducer;

