import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../4.Utils/apiServices";

const namespace = "leaveApproval";

export const getLeaveApprovalListByUserID = createAsyncThunk(
    `${namespace}/lists`,
    async (body, { rejectWithValue }) => {
      const api = "get_su_approval_lists";
      const replace = {
        search: "{employee_id}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

export const ApproveLeave = createAsyncThunk(
    `${namespace}/approve`,
    async (body, { rejectWithValue }) => {
      const api = "su_approve_leave";
      const opt = {
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

export const RejectLeave = createAsyncThunk(
    `${namespace}/reject`,
    async (body, { rejectWithValue }) => {
      const api = "su_reject_leave";
      const opt = {
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

const initialState = {
    loading: false,
    error: false,
    currentPage: 1,
    approvalLists: [],
    leaveDetails: {}
};

const LeaveApprovalSlice = createSlice({
    name: "Application",
    initialState,
    reducers: {
        updateCurrentPage(state, action) {
            return { ...state, currentPage: action.payload };
        },
        getLeaveDetails(state, action) {
            return {
                ...state,
                leaveDetails: state.approvalLists.find((leaveDetails) => leaveDetails.id === action.payload),
            };
        },
    },
    extraReducers: (builder) => {
      builder
  
      .addCase(getLeaveApprovalListByUserID.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getLeaveApprovalListByUserID.fulfilled, (state, action) => {
        state.loading = false;
        state.approvalLists = action.payload;
      })
      .addCase(getLeaveApprovalListByUserID.rejected, (state, action) => {
        state.error = true;
      })

      // Approve
      .addCase(ApproveLeave.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(ApproveLeave.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(ApproveLeave.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      // Reject
      .addCase(RejectLeave.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(RejectLeave.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(RejectLeave.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
    },
  });
  
  export const { updateCurrentPage, getLeaveDetails } = LeaveApprovalSlice.actions;
  export default LeaveApprovalSlice.reducer;