import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  Container,
  Card,
  ButtonGroup,
  Button,
  Form,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { ThreeDots } from 'react-loader-spinner';
import { getUserAccessModules, resetUserAccess, updateUserAccess, updateUserAccessModules } from "../../3.Store/UserAccessSlice";

const UserAccessForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const modulelists = useSelector(state => state.UserAccessSlice.moduleList);
  const user = useSelector(state => state.UserAccessSlice.user);
  const [selectedModules, setSelectedModules] = useState([]);

  useEffect(() => {
    if(id) {
      setLoading(true);
      console.log(user);
      dispatch(getUserAccessModules())
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    }
  }, [id]);

  useEffect(() => {
    setSelectedModules(user.modules || []);
  }, [user]);

  // Group modulelists by module_level.id
  const groupedModules = modulelists.reduce((acc, module) => {
    const { id: levelId } = module.module_level;
    if (!acc[levelId]) {
      acc[levelId] = [];
    }
    acc[levelId].push(module);
    return acc;
  }, {});

  const handleCheckboxChange = (moduleId) => {
    setSelectedModules(prev =>
      prev.includes(moduleId) ? prev.filter(id => id !== moduleId) : [...prev, moduleId]
    );
  };

  const handleCheckAllChange = () => {
    const allChecked = modulelists.length === selectedModules.length;
    setSelectedModules(allChecked ? [] : modulelists.map(module => module.id));
  };

  const handleBtnUpdate = () => {
    const entity = { ...user, modules: selectedModules };
    setLoading(true);
    dispatch(updateUserAccessModules(entity))
      .unwrap()
      .then(() => {
        Swal.fire("Role Updated", "", "success").then(() => {
          navigate(`/UserAccessList`);
        });
      })
      .catch((err) => {
        Swal.fire("Error", err.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const handleBtnBack = () => {
    dispatch(resetUserAccess());
    navigate(-1);
  };

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Application Module Employee</h1><br/>
        <h1 className="MiniTitleHeader">Employee: {user.employee.fullname}</h1>
               
        <Form.Check 
          type="checkbox" 
          label="Check/Uncheck All"
          style={{ color: "#fff" }}
          onChange={handleCheckAllChange}
          checked={modulelists.length === selectedModules.length}
        />
        <br/>

        {Object.keys(groupedModules).map(levelId => (
          <Card key={levelId} className="mb-3">
            <Card.Header style={{ backgroundColor: "#f8f9fa" }}>
              {groupedModules[levelId][0].module_level.code}
            </Card.Header>
            <Card.Body>
              {groupedModules[levelId].map(module => (
                <OverlayTrigger
                  key={module.id}
                  placement="right"
                  overlay={<Tooltip id={`tooltip-${module.id}`}>{module.remark}</Tooltip>}
                >
                  <Form.Check
                    type="checkbox"
                    label={
                      <span>{module.description}</span>
                    }
                    checked={selectedModules.includes(module.id)}
                    onChange={() => handleCheckboxChange(module.id)}
                    style={{ color: "#000" }}
                  />
                </OverlayTrigger>
              ))}
            </Card.Body>
          </Card>
        ))}

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>
          {/* &nbsp; &nbsp; */}
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnUpdate}>
              Update
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};

export default UserAccessForm;