import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";

const Homepage = () => {
  let navigate = useNavigate();

  const handleBtnApplyLeave = () => {
    navigate("/LeaveApplicationForm");
  };
  const handleBtnApproveLeave = () => {
    navigate("/LeaveApprovalList");
  };
  const handleBtnMedicalClaim = () => {
    navigate("/MedicalClaimList");
  };

  return (
    <>
      <Container className="ContainerContent">
        <Row>
          <Col md={6} sm={12} xs={12}>
            <p class="MainTitle">Leave Application System</p>
          </Col>
          {/* <Col md={6} sm={12} xs={12}>
            <Row className="p-3 mt-3">
              <Button className="MainBtn" onClick={handleBtnApplyLeave}>
                <strong> Apply Leave </strong>
              </Button>
            </Row>
            <Row className="p-3 mt-3">
              <Button className="MainBtn" onClick={handleBtnApproveLeave}>
                <strong> Approve Leave </strong>
              </Button>
            </Row>
            <Row className="p-3 mt-3">
              <Button className="MainBtn" onClick={handleBtnMedicalClaim}>
                <strong> Medical Claim</strong>
              </Button>
            </Row>
          </Col> */}
        </Row>
      </Container>
    </>
  );
};
export default Homepage;
