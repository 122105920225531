import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiServices } from "../4.Utils/apiServices";

const namespace = "Auth";

export const postLogin = createAsyncThunk(
  `${namespace}/postLogin`,
  async (body, { rejectWithValue }) => {
    const api = "postLogin";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      console.log(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {};

const AuthSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    updateLoginEntity(state, action) {
      return { ...state, login: action.payload };
    },
  },
  extraReducers: {
    [postLogin.pending]: (state, action) => {
      state.loading = true;
    },

    [postLogin.fulfilled]: (state, action) => {
      state.loading = false;
      console.log("wow");
    },

    [postLogin.rejected]: (state, action) => {
      state.error = true;
    },
  },
});

export const { updateLoginEntity } = AuthSlice.actions;
export default AuthSlice.reducer;
