import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import Swal from "sweetalert2";
import { ThreeDots } from 'react-loader-spinner';
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import { 
  getList, 
  updateReview, 
  reviewWithdrawal, 
  getListsOfLeaveWithdrawal, 
  resetReview,
  getMedicalCert,
  resetCert,
} from "../../3.Store/LeaveWithdrawalSlice";

const LeaveWithdrawalForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get('page'); // Extracting page from URL params
  const detail = useSelector(state => state.LeaveWithdrawalSlice.withdrawlist);
  const list = useSelector(state => state.LeaveWithdrawalSlice.list);
  const { remark_withdrawal } = list;
  const cert = useSelector(state => state.LeaveWithdrawalSlice.cert);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(getList(id));
      const entity = { ...list, id: id };
      dispatch(updateReview(entity));
    } else {
      dispatch(resetReview());
    };
  }, [id])

  useEffect(() => {
    if (detail?.leave_application?.id) {
      dispatch(getMedicalCert({ id: detail.leave_application.id }));
    }
  }, [detail]);
  
  const handleRemarkChange = (e) => {
    const entity = { ...list, remark_withdrawal: e.target.value };
    console.log(entity)
    dispatch(updateReview(entity));
  };

  const handleBtnBack = () => {
    dispatch(resetReview());
    dispatch(resetCert());
    // navigate(-1);
    navigate(`/LeaveWithdrawalList?page=${currentPage}`);
  };

  const handleBtnReject = () => {
    Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const entity = { ...list, status: { id: "-200" } };
          dispatch(reviewWithdrawal(entity))
            .unwrap()
            .then(() => {
              setLoading(false);
              Swal.fire("Withdrawal Rejected", "", "success", "OK")
              .then(() => {
                dispatch(getListsOfLeaveWithdrawal());
                navigate(`/LeaveWithdrawalList`);
              })
            })
            .catch((err) => {
              Swal.fire("Error", err.response.data, "error", "OK");
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'The rejection has been cancelled',
              'error'
            );
        }
    });
  };

  const handleBtnApprove = () => {
    Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const entity = { ...list, status: { id: "200" } };
          dispatch(reviewWithdrawal(entity))
            .unwrap()
            .then(() => {
              setLoading(false);
              Swal.fire("Withdrawal Approved", "", "success", "OK")
              .then(() => {
                dispatch(getListsOfLeaveWithdrawal());
                navigate(`/LeaveWithdrawalList`);
              })
            })
            .catch((err) => {
              Swal.fire("Error", err.response.data, "error", "OK");
            })
            .finally(() => {
              setLoading(false);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'The approval has been cancelled',
              'error'
            );
        }
    });
  };

  return (
    <Container>
      <Card className="CardContent">
        <h1 className="TitleHeader">HR Leave Withdrawal Request</h1>
        <h1 className="MiniTitleHeader">View</h1>

        <Row className="my-3">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">
              Withdrawal Requested by
            </Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control value={detail?.leave_application.employee.fullname} readOnly />
          </Col>
        </Row>

        <Row className="my-3">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">
              Withdrawal Requested on
            </Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control value={moment(detail?.created).format("dddd, MMMM DD, YYYY")} readOnly />
          </Col>
        </Row>

        <Row className="my-3">
          <Col md={12} sm={12}>
            <Form.Label className="FormLabel">Reason For Withdrawal</Form.Label>
            <Form.Control as="textarea" value={detail?.reason} readOnly />
          </Col>
        </Row>

        <Row className="my-3">
          <Col md={12} sm={12}>
            <Form.Label className="FormLabel">
              HR Remark For Withdrawal
            </Form.Label>
            <Form.Control 
              as="textarea"
              name="remark_withdrawal"
              value={remark_withdrawal || detail?.remark}
              onChange={handleRemarkChange}
            />
          </Col>
        </Row>

        <hr />

        <Row className="my-3">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">Leave Type</Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control value={detail?.leave_application.leave_type.description} readOnly />
          </Col>
        </Row>

        {/* Leave Advanced */}
        {detail?.leave_application.leave_type.description == "Advance Leave" && (
          <Row className="my-2">
            <Col md={6} sm={6}>
              <Form.Label className="FormLabel">Leave Advanced</Form.Label>
            </Col>
            <Col md={6} sm={6}>
              <Form.Control />
            </Col>
          </Row>
        )}

        {/* Sick Leave */}
        {detail?.leave_application.leave_type.description === "Sick Leave" && (
          <Row className="my-2">
            <img src={cert?.file} alt="Medical Certificate" /><br /><br />
          </Row>
        )}

        {/* Special Leave */}
        {detail?.leave_application.leave_type.description == "Special Leave" && (
          <>
            <Row className="my-2">
              <Col md={6} sm={6}>
                <Form.Label className="FormLabel">
                  Special Leave Type
                </Form.Label>
              </Col>
              <Col md={6} sm={6}>
                <Form.Control />
              </Col>
            </Row>
            <Row className="my-2">
              <Col md={6} sm={6}>
                <Form.Label className="FormLabel">Attachment</Form.Label>
              </Col>
              <Col md={6} sm={6}>
                <Form.Control type="file" />
              </Col>
            </Row>
          </>
        )}

        <Row className="my-2">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">No. of Days</Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control value={detail?.leave_application.numberofday_type.description} readOnly />
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">Starting From</Form.Label>
          </Col>
          {detail?.leave_application.numberofday_type.description != "One Day" && (
            <Col md={3} sm={6}>
              <Form.Check label="AM" checked={detail?.leave_application.from_ampm == "AM"} style={{ color: "#fff" }} readOnly />
              <Form.Check label="PM" checked={detail?.leave_application.from_ampm == "PM"} style={{ color: "#fff" }} readOnly />
            </Col>
          )}
          <Col md={3} sm={6}>
            <Form.Control type="date" value={moment(detail?.leave_application.from_date.date).format("YYYY-MM-DD")} readOnly />
          </Col>
        </Row>
        {detail?.leave_application.numberofday_type.description == "More than One Day" && (
          <Row className="my-2">
            <Col md={6} sm={6}>
              <Form.Label className="FormLabel">To</Form.Label>
            </Col>
            <Col md={3} sm={6}>
              <Form.Check label="AM" checked={detail?.leave_application.to_ampm == "AM"} style={{ color: "#fff" }} readOnly />
              <Form.Check label="PM" checked={detail?.leave_application.to_ampm == "PM"} style={{ color: "#fff" }} readOnly />
            </Col>
            <Col md={3} sm={6}>
              <Form.Control type="date" value={moment(detail?.leave_application.to_date.date).format("YYYY-MM-DD")} readOnly />
            </Col>
          </Row>
        )}

        <Row className="my-2">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">Location</Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control value={detail?.leave_application.location_type.description} readOnly />
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">No. Days Applying</Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control value={detail?.leave_application.number_of_days} readOnly />
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">Description</Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control as="textarea" rows={3} value={detail?.leave_application.description} readOnly />
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">Reason</Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control as="textarea" rows={3} value={detail?.leave_application.reason_for_application} readOnly />
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">Remark</Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control as="textarea" rows={3} value={detail?.leave_application.remark} readOnly />
          </Col>
        </Row>

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>

          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnReject}>
              Reject
            </Button>
            &nbsp; &nbsp;
            <Button className="Button" onClick={handleBtnApprove}>
              Approve
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};
export default LeaveWithdrawalForm;