import { useState, useEffect } from "react";
import Select from "react-select";
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  ButtonGroup,
  Button,
  Image
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { ThreeDots } from 'react-loader-spinner';
import {
  getLeaveBalances,
  updateApplication,
  updateBalance,
  postLeaveApplication,
  getDaysApplying,
  resetForm,
  getLeaveDetailsByID,
  updateReason,
  withdrawLeaveApplication,
  getLeaveApplicationDetails,
  deleteLeaveApplication,
  getMedicalCert,
  addMedicalCertificate,
  editMedicalCertificate,
  updateApplicationDetails,
  editLeaveApplication,
  updateCert,
  addSpecialAttachment,
  getSpecialAttachment,
  editSpecialAttachment
} from "../../3.Store/ApplicationSlice";
import { getListsOfLeaveWithdrawal } from "../../3.Store/LeaveWithdrawalSlice";
import { openEditor } from "react-profile";
import "react-profile/themes/default";

const LeaveApplicationForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();
  const [loading, setLoading] = useState(false);
  const [fullImage, setFullImage] = useState(null);
  const withdrawalList = useSelector(state => state.LeaveWithdrawalSlice.lists);

  const employee = JSON.parse(localStorage.getItem("current_user"));
  const userId = employee?.id;

  const [showBalance, setShowBalance] = useState("");

  const { application, balance, applying, reason, cert, applicationDetails } = useSelector(
    (state) => state.ApplicationSlice
  );
  const {
    leave_type,
    location_type,
    reason_for_application,
    number_of_days,
    numberofday_type,
    from_date,
    from_ampm,
    to_date,
    to_ampm,
    description,
    status,
  } = application;
  const { reason_withdrawal } = reason;

  console.log(applicationDetails)

  useEffect(() => {
    setLoading(true)
    if(id){
      dispatch(getLeaveDetailsByID(id));
      dispatch(getLeaveApplicationDetails({id}))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    }else{
      dispatch(resetForm());
      dispatch(getLeaveBalances({ id: userId }))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    }
  }, [id, userId]);

  useEffect(() => {
    // Function to fetch data based on leave type
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true when useEffect starts

        if (leave_type?.id === "SI") {
          await dispatch(getMedicalCert({ id }));
        } else if (leave_type?.id === "SP") {
          await dispatch(getSpecialAttachment({ id }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after dispatch completes (success or failure)
      }
    };

    fetchData();

  }, [leave_type?.id]);

  useEffect(() => {
    if (status?.description.includes("Withdrawn") && status.description.includes("Withdrawn")) {
      console.log("Status contains 'withdraw'. Perform specific actions here.");
      dispatch(getListsOfLeaveWithdrawal());

      if (withdrawalList && withdrawalList.length > 0) {
        const matchedWithdrawal = withdrawalList.find(
          (withdrawal) => withdrawal.leave_application.id === application.id
        );
        console.log(matchedWithdrawal)
        dispatch(updateApplicationDetails({ ...matchedWithdrawal }));
      }
    }
  }, [status])

  useEffect(() => {
    updateBalance(balance[leave_type]);
    showBalanceAfter();

    // if (numberofday_type?.id == "H") {
    //   const entity = { ...application, number_of_days: "0.5" };
    //   console.log(entity);
    //   dispatch(updateApplication(entity));
    // }

    // if (numberofday_type?.id == "1") {
    //   const entity = {
    //     ...application,
    //     number_of_days: "1.0",
    //     from_ampm: "AM",
    //     to_ampm: "PM",
    //   };
    //   console.log(entity);
    //   dispatch(updateApplication(entity));
    // }

    // if (numberofday_type?.id == "M") {
    //   const newFromDateTime = new Date(from_date.date);
    //   const newToDateTime = new Date(to_date.date);
    
    //   // Normalize the dates to ensure the time component is the same
    //   newFromDateTime.setHours(0, 0, 0, 0);
    //   newToDateTime.setHours(0, 0, 0, 0);

    //   // Calculate the difference in milliseconds
    //   const differenceInMs = newToDateTime - newFromDateTime;

    //   // Convert milliseconds to days
    //   const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
    //   console.log(differenceInDays);
    //   const entity = { ...application, number_of_days: differenceInDays };
    //   console.log(entity);
    //   dispatch(updateApplication(entity));
    // }
  }, [balance, leave_type]);

  useEffect(() => {
    const appendTime = (date, ampm) => {
      return ampm === "AM" ? `${date} 08:00:00` : `${date} 13:00:00`;
    };
  
    const handleMCase = async () => {
      const fromDateString = appendTime(moment(from_date.date).format("YYYY-MM-DD"), from_ampm);
      const toDateString = appendTime(moment(to_date.date).format("YYYY-MM-DD"), to_ampm);
  
      console.log(fromDateString);
      console.log(leave_type.id);
  
      const entity = {
        ...applying,
        employee: { id: userId },
        from_datetime: fromDateString,
        leave_type: { id: leave_type.id },
        to_datetime: toDateString
      };
  
      console.log(entity);
  
      // Await the dispatch call to get the updated days applying
      try {
        const updatedDaysApply = await dispatch(getDaysApplying(entity));
        console.log(updatedDaysApply);
  
        // Update the application with the correct number of days
        const days_applying = { ...application, number_of_days: updatedDaysApply.payload };
        await dispatch(updateApplication(days_applying));
      } catch (error) {
        console.error('Error updating days applying:', error);
      }
    };
  
    if (!numberofday_type || !from_date || !to_date) {
      return;
    }
  
    if (numberofday_type.id === "H") {
      const updatedNumberOfDays = "0.5";
      const updatedToDate = { date: from_date.date };
      const updatedToAMPM = from_ampm;
  
      if (
        application.number_of_days !== updatedNumberOfDays ||
        application.to_date.date !== updatedToDate.date ||
        application.to_ampm !== updatedToAMPM
      ) {
        const entity = {
          ...application,
          number_of_days: updatedNumberOfDays,
          to_date: updatedToDate,
          to_ampm: updatedToAMPM
        };
        console.log(entity);
        dispatch(updateApplication(entity));
      }
    } else if (numberofday_type.id === "1") {
      const updatedNumberOfDays = "1.0";
      const updatedFromAMPM = "AM";
      const updatedToAMPM = "PM";
      const updatedToDate = { date: from_date.date };
  
      if (
        application.number_of_days !== updatedNumberOfDays ||
        application.from_ampm !== updatedFromAMPM ||
        application.to_ampm !== updatedToAMPM
      ) {
        const entity = {
          ...application,
          number_of_days: updatedNumberOfDays,
          from_ampm: updatedFromAMPM,
          to_ampm: updatedToAMPM,
          to_date: updatedToDate
        };
        console.log(entity);
        dispatch(updateApplication(entity));
      }
    } else if (numberofday_type.id === "M") {
      handleMCase();
    }
  }, [numberofday_type, from_date, to_date, from_ampm, to_ampm]);  

  const LeaveTypeOption = [
    { id: "A", label: "Annual Leave", value: "annual" },
    { id: "AD", label: "Advanced Leave", value: "advanced" },
    { id: "H", label: "Hospitalization", value: "hospitalization" },
    { id: "M", label: "Maternity Leave", value: "maternity" },
    { id: "O", label: "Other Leave", value: "other" },
    { id: "SI", label: "Sick Leave", value: "sick" },
    { id: "SP", label: "Special Leave", value: "special" },
    { id: "UP", label: "Unpaid Leave", value: "unpaid" },
  ];

  const SpecialLeaveTypeOption = [
    {
      id: "1da74498-1637-11e9-9e53-5254006876cf",
      label: "Convocation",
      value: "co",
    },
    {
      id: "287596e0-1637-11e9-9e53-5254006876cf",
      label: "Paternity Leave",
      value: "PL",
    },
    {
      id: "093f0e92-1637-11e9-9e53-5254006876cf",
      label: "Demise of Immediate Family",
      value: "DIF",
    },
    {
      id: "0ddd720c-1637-11e9-9e53-5254006876cf",
      label: "Demise of Family",
      value: "DF",
    },
    {
      id: "185632cb-1637-11e9-9e53-5254006876cf",
      label: "Wedding",
      value: "W",
    },
  ];

  const NoOfDaysOption = [
    { id: "H", label: "1/2 Day", value: "half" },
    { id: "1", label: "One Day", value: "one" },
    { id: "M", label: "More than 1 Day", value: "more_than_one" },
  ];

  const LocationOption = [
    { id: "I", label: "In Town", value: "in_town" },
    { id: "OT", label: "Out of Town", value: "out_town" },
    { id: "W", label: "West Malaysia", value: "w_msia" },
    { id: "OS", label: "Oversea", value: "oversea" },
  ];

  const showBalanceAfter = () => {
    if (leave_type.id == "A") {
      setShowBalance(balance?.annual);
    }
    if (leave_type.id == "AD") {
      setShowBalance(balance?.advanced);
    }
    if (leave_type.id == "H") {
      setShowBalance(balance?.hospitalization);
    }
    if (leave_type.id == "M") {
      setShowBalance(balance?.maternity);
    }
    if (leave_type.id == "SI") {
      setShowBalance(balance?.sick);
    }
    if (leave_type.id == "SP") {
      setShowBalance(balance?.special);
    }
  };

  const handleChangeForm = (e) => {
    console.log(e);
    const entity = { ...application, [e.target.name]: e.target.value };
    console.log(entity);
    dispatch(updateApplication(entity));
  };

  const handleChangeDropdownLeaveType = (value) => {
    if(value.id==="SP") {
      const entity = {
        ...application,
        leave_type: {
          id: value.id,
        },
        special_leave_type: {
          id: "1da74498-1637-11e9-9e53-5254006876cf"
        }
      };
      console.log(entity);
      dispatch(updateApplication(entity));
    } else {
      const entity = {
        ...application,
        leave_type: {
          id: value.id,
        }
      };
      console.log(entity);
      dispatch(updateApplication(entity));
    }
  };

  const getSpecialLeaveLabel = (value) => {
    const special = SpecialLeaveTypeOption.find(length => length.id === value);
    return special ? special.label : "";
  };

  const handleChangeDropdownSpecialLeaveType = (value) => {
    console.log(value);
    const entity = {
      ...application,
      special_leave_type: {
        id: value.id,
        // description: action.label,
      },
    };
    dispatch(updateApplication(entity));
    console.log(entity);
  };

  const handleChangeDropdownNoDays = (action) => {
    console.log(action);
    const entity = {
      ...application,
      numberofday_type: {
        id: action.id,
        // description: action.label,
      },
    };
    dispatch(updateApplication(entity));
    console.log(entity);
  };

  const handleChangeFromDate = (e) => {
    console.log(e);
    const entity = { ...application, from_date: { date: e.target.value } };
    console.log(entity);
    dispatch(updateApplication(entity));
  };

  const handleChangeToDate = (e) => {
    console.log(e);
    const entity = { ...application, to_date: { date: e.target.value } };
    console.log(entity);
    dispatch(updateApplication(entity));
  };

  const handleChangeDropdownLocation = (action) => {
    console.log(action);
    const entity = {
      ...application,
      location_type: {
        id: action.id,
        // description: action.label,
      },
    };
    dispatch(updateApplication(entity));
    console.log(entity);
  };

  const handleReasonWithdrawal = (e) => {
    const entity = {...reason, reason_withdrawal: e.target.value};
    console.log(entity)
    dispatch(updateReason(entity));
  }

  const handleBtnWithdraw = () => {
    if (!reason_withdrawal.trim()) {
      // Display an error message if the reason is empty
      Swal.fire("Error", "Please provide a reason for withdrawal", "error", "OK");
      return;
    }
  
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        dispatch(withdrawLeaveApplication({id:id, ...reason}))
          .unwrap()
          .then(() => {
            setLoading(false);
            Swal.fire({
              title: "Request for Withdrawal is submitted (subjected to approval)",
              icon: "success",
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/LeaveApplicationList");
              }
            });
          })
          .catch((err) => {
            Swal.fire("Error", err.response.data, "error", "OK");
            setLoading(false);
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'The approval has been cancelled',
            'error'
          );
      }
    });
  }  

  const handleBtnBack = () => {
    dispatch(resetForm());
    navigate(-1);
  };

  const handleBtnDelete = () => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        dispatch(deleteLeaveApplication({ id }))
          .unwrap()
          .then(() => {
            setLoading(false);
            Swal.fire('Deleted!', 'Leave application has been deleted.', 'success')
              .then(() => {
                navigate("/LeaveApplicationList");
              });
          })
          .catch((err) => {
            setLoading(false);
            Swal.fire("Error", err.response.data, "error");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Cancel Delete Leave Application', 'error');
      }
    });
  };

  const handleBtnSubmit = () => {
    setLoading(true);
    try {
      let day_description;
      let toDate=to_date.date;

      if (numberofday_type.id === "M") {
        day_description = `${number_of_days}D from ${from_date.date} ${from_ampm} to ${to_date.date} ${to_ampm}`;
      } else if (numberofday_type.id === "H") {
        day_description = `${number_of_days}D on ${from_date.date} ${from_ampm}`;
      } else if (numberofday_type.id === "1") {
        day_description = `${number_of_days}D on ${from_date.date}`;
        toDate = from_date.date;
      }

      const entity = {
        ...application,
        description: day_description,
        to_date: {date:toDate}
      };

      dispatch(postLeaveApplication(entity))
      .unwrap()
      .then((response) => {
        const applicationId = response.id; // Assume the response contains the ID of the created application

        // Add sick or special attachment based on leave type
        if (leave_type.id === "SI") {
          const entity2 = { ...cert, leave_application: {id:applicationId}};
          dispatch(addMedicalCertificate(entity2));
        } else if (leave_type.id === "SP") {
          const entity3 = { ...cert, leave_application: {id:applicationId}};
          dispatch(addSpecialAttachment(entity3));
        }
        
        setLoading(false);
        Swal.fire("Leave Application Created", "", "success", "OK").then(() => {  
          dispatch(resetForm());
          navigate("/LeaveApplicationList");
        });
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire("Error / Masalah", err.response.data, "error", "OK");
      });
    } catch (err) {
      setLoading(false);
      Swal.fire("Error / Masalah", err.response.data, "error", "OK");
    }
  };

  const handleBtnEdit = () => {
    setLoading(true);
    const entity = { ...application };
  
    dispatch(editLeaveApplication(entity))
      .unwrap()
      .then(() => {
        if (leave_type.id === "SI" && cert.id) {
          //const entity2 = { ...cert, leave_application: { id: entity.id } };
          dispatch(editMedicalCertificate({id:cert.id, ...cert}));
        }
        else if (leave_type.id === "SI" && !cert.id) {
          const entity2 = { ...cert, leave_application: { id: entity.id } };
          dispatch(addMedicalCertificate(entity2));
        }
        else if (leave_type.id === "SP" && cert.id) {
          //const entity2 = { ...cert, leave_application: { id: entity.id } };
          dispatch(editSpecialAttachment({id:cert.id, ...cert}));
        }
        else if (leave_type.id === "SP" && !cert.id) {
          const entity2 = { ...cert, leave_application: { id: entity.id } };
          dispatch(addSpecialAttachment(entity2));
        }
  
        setLoading(false);
        Swal.fire("Record Updated", "", "success", "OK").then(() => {
          navigate(`/LeaveApplicationList`);
        });
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire("Error", err.response.data, "error", "OK");
      });
  };

  const handleAttachmentChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
  
      reader.onloadend =  async () => {
        const fileResult = reader.result;
        let base64String;
  
        if (selectedFile.type === 'application/pdf') {
          // For PDF files, strip off the data URL prefix
          base64String = fileResult.split(',')[1];
        } else {
          // For images or other file types, use the full data URL
          setFullImage(fileResult);
          const editor = await openEditor({ src: selectedFile });
          base64String = editor?.editedImage?.getDataURL();
        }
  
        const entity = { ...cert,
          file: base64String,
          filename: selectedFile.name,
          filetype: selectedFile.type,
          filesize: selectedFile.size
        };
        console.log(entity);
        dispatch(updateCert(entity));
  
        // Log file details
        console.log("Base64 File:", base64String);
        console.log("Filename:", selectedFile.name);
        console.log("Filetype:", selectedFile.type);
        console.log("Filesize:", selectedFile.size, "bytes");
      };
  
      // Read the file as a data URL
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleEditImage = async () => {
    if (fullImage) {
      try {
        const editor = await openEditor({ src: fullImage });

        if (editor && editor.editedImage) {
          const updatedImage = editor.editedImage.getDataURL();
          const entity = { ...cert, file: updatedImage };
          dispatch(updateCert(entity));
        } else {
          console.error("Edited image not found.");
        }
      } catch (error) {
        console.error("Error opening editor:", error);
      }
    } else {
      console.error("Full image is not set.");
    }
  };

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Leave Application</h1>
        <Row className="my-3">
          <Col md={8} sm={8}>
            <Form.Label className="FormLabel">Leave Type</Form.Label>
          </Col>
          <Col md={4} sm={4}>
            {!id ? (
              <Select
                name="leave_type"
                options={LeaveTypeOption}
                value={LeaveTypeOption.find(
                  (option) => option.id === leave_type.id
                )}
                onChange={handleChangeDropdownLeaveType}
              />
            ) : (
              <Form.Control readOnly value={application?.leave_type?.description} />
            )}
            
          </Col>
        </Row>

        {/* Leave Available */}
        {!id && leave_type?.id !== "O" && leave_type?.id !== "UP" && leave_type?.id !== "AD" && (
          <Row className="my-2">
            <Col md={8} sm={8}>
              <Form.Label className="FormLabel">Leave Available</Form.Label>
            </Col>
            <Col md={4} sm={4}>
              <Form.Control readOnly value={showBalance} />
            </Col>
          </Row>
        )}

        {/* Leave Advanced */}
        {leave_type?.id == "AD" && (
          <Row className="my-2">
            <Col md={8} sm={8}>
              <Form.Label className="FormLabel">Leave Advanced</Form.Label>
            </Col>
            <Col md={4} sm={4}>
              <Form.Control readOnly value={showBalance} />
            </Col>
          </Row>
        )}

        {leave_type?.id == "SI" && (
          <Row className="my-2">
            <Col md={8} sm={8}>
              <Form.Label className="FormLabel">Medical Certificate</Form.Label>
            </Col>
            <Col md={4} sm={4}>
              <Form.Control type="file" onChange={handleAttachmentChange}/>
            </Col>
          </Row>
        )}

        {/* Special Leave */}
        {!id && leave_type?.id === "SP" && (
          <>
            <Row className="my-2">
              <Col md={8} sm={8}>
                <Form.Label className="FormLabel">
                  Special Leave Type
                </Form.Label>
              </Col>
              <Col md={4} sm={4}>
                <Select
                  name="special_leave_type"
                  options={SpecialLeaveTypeOption}
                  onChange={handleChangeDropdownSpecialLeaveType}
                />
              </Col>
            </Row>
          </>
        )}

        {leave_type?.id == "SP" && (
          <Row className="my-2">
            <Col md={8} sm={8}>
              <Form.Label className="FormLabel">Attachment</Form.Label>
            </Col>
            <Col md={4} sm={4}>
              <Form.Control type="file" onChange={handleAttachmentChange} />
            </Col>
          </Row>
        )}


        {/* Conditional rendering based on attachment existence */}
        {cert && cert.file && (
          <Row className="my-3">
            <Col md={12} sm={12} className="d-flex justify-content-center align-items-center">
              {/* Display image */}
              {cert.filetype.startsWith('image/') && (
                <div
                  style={{
                    display: 'flex', // Flexbox for centering
                    justifyContent: 'center', // Horizontal centering
                    alignItems: 'center', // Vertical centering
                    padding: '10px', // Space between the border and image
                    backgroundColor: '#373c40', // Grey background
                    height: '500px', // Set the max height for the container
                    width: "100%",
                    overflow: 'auto', // Enable scrolling if the content exceeds max height
                  }}
                >
                  <Image
                    src={cert.file}
                    alt="Uploaded file"
                    fluid
                    style={{
                      maxWidth: '100%',
                      height: '100%',
                    }}
                    onClick={handleEditImage} // Optional: Click handler
                  />
                </div>
              )}

              {/* Display PDF */}
              {!cert.filetype.startsWith('image/') && (
                <iframe
                  src={`data:${cert.filetype};base64,${cert.file}`}
                  title="PDF file"
                  width="100%"
                  height="500px"
                ></iframe>
              )}
            </Col>
          </Row>
        )}

        <Row className="my-2">
          <Col md={8} sm={8}>
            <Form.Label className="FormLabel">No. of Days</Form.Label>
          </Col>
          <Col md={4} sm={4}>
            <Select
              name="numberofday_type"
              options={NoOfDaysOption}
              value={NoOfDaysOption.find(
                (option) => option.id === numberofday_type.id
              )} // Correctly set the value to match the selected option
              onChange={handleChangeDropdownNoDays}
            />
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={4} sm={4}>
            {numberofday_type.id === "M" ? (
              <Form.Label className="FormLabel">From</Form.Label>
            ) : (
              <Form.Label className="FormLabel">Starting From</Form.Label>
            )}
          </Col>
          <Col md={4} sm={4}>
            {numberofday_type.id !== "1" && (
              <div>
                <Form.Check
                  type="radio"
                  label="AM"
                  name="from_ampm"
                  value="AM"
                  style={{ color: "#fff" }}
                  checked={from_ampm === "AM"}
                  onChange={handleChangeForm}
                />
                <Form.Check
                  type="radio"
                  label="PM"
                  name="from_ampm"
                  value="PM"
                  style={{ color: "#fff" }}
                  checked={from_ampm === "PM"}
                  onChange={handleChangeForm}
                />
              </div>
            )}
          </Col>
          <Col md={4} sm={4}>
            <Form.Control
              type="date"
              name="from_date"
              value={moment(from_date?.date).format("YYYY-MM-DD")}
              onChange={handleChangeFromDate}
            />
          </Col>
        </Row>
        {numberofday_type.id === "M" && (
          <Row className="my-2">
            <Col md={4} sm={4}>
              <Form.Label className="FormLabel">To</Form.Label>
            </Col>
            <Col md={4} sm={4}>
              <div>
                <Form.Check
                  type="radio"
                  label="AM"
                  name="to_ampm"
                  value="AM"
                  style={{ color: "#fff" }}
                  checked={to_ampm === "AM"}
                  onChange={handleChangeForm}
                />
                <Form.Check
                  type="radio"
                  label="PM"
                  name="to_ampm"
                  value="PM"
                  style={{ color: "#fff" }}
                  checked={to_ampm === "PM"}
                  onChange={handleChangeForm}
                />
              </div>
            </Col>
            <Col md={4} sm={4}>
              <Form.Control
                type="date"
                name="to_date"
                value={moment(to_date?.date).format("YYYY-MM-DD")}
                onChange={handleChangeToDate}
              />
            </Col>
          </Row>
        )}

        <Row className="my-2">
          <Col md={8} sm={8}>
            <Form.Label className="FormLabel">Location</Form.Label>
          </Col>
          <Col md={4} sm={4}>
            <Select
              name="location_type"
              options={LocationOption}
              value={LocationOption.find(
                (option) => option.id === location_type.id
              )} // Correctly set the value to match the selected option
              onChange={handleChangeDropdownLocation}
            />
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={8} sm={8}>
            <Form.Label className="FormLabel">No. Days Applying</Form.Label>
          </Col>
          <Col md={4} sm={4}>
            <Form.Control readOnly value={number_of_days} />
          </Col>
        </Row>

        {id && (
          <>
          <Row className="my-2">
            <Col md={8} sm={8}>
              <Form.Label className="FormLabel">Applied On</Form.Label>
            </Col>
            <Col md={4} sm={4}>
              <Form.Control readOnly value={moment(application.created).format("YYYY-MM-DD")} />
            </Col>
          </Row>

          <Row className="my-2">
            <Col md={8} sm={8}>
              <Form.Label className="FormLabel">Status</Form.Label>
            </Col>
            <Col md={4} sm={4}>
              <Form.Control readOnly value={status?.description} />
            </Col>
          </Row>

          <Row className="my-2">
            <Col md={12} sm={12}>
              <Form.Label className="FormLabel">Description</Form.Label>
              <Form.Control as="textarea" value={description} readOnly />
            </Col>
          </Row>
          </>
        )}

        <Row className="my-2">
          <Col md={12} sm={12}>
            <Form.Label className="FormLabel">Reason</Form.Label>
            <Form.Control 
              as="textarea"
              name="reason_for_application"
              value={reason_for_application}
              onChange={handleChangeForm}
            />
          </Col>
        </Row>

        {id && application?.status?.description !== "Pending Approval" && (
          <>
          <Row className="my-2">
            <Col md={12} sm={12}>
              <Form.Label className="FormLabel">Remark</Form.Label>
              <Form.Control as="textarea" value={application.remark} readOnly />
            </Col>
          </Row>
          <hr style={{ borderTop: '2px solid white' }}/>
          {(application?.status?.description === "Leave Withdrawn by staff (Pending Approval)" || application?.status?.description === "Leave Withdrawn by staff with Approval") && (
            <Row className="my-2">
              <Col md={12} sm={12}>
                <Form.Label className="FormLabel">HR Remark for Withdrawal</Form.Label>
                <Form.Control as="textarea" value={applicationDetails?.remark} readOnly/>
              </Col>
            </Row>
          )}
          {application?.status?.description === "Approved by Supervisor" && (
            <Row className="my-2">
            <Col md={12} sm={12}>
              <Form.Label className="FormLabel">Reason for Withdrawal</Form.Label>
              <Form.Control as="textarea" name="reason_withdrawal" value={reason_withdrawal} onChange={handleReasonWithdrawal}/>
            </Col>
          </Row>
          )}
          </>
        )}

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>
          <ButtonGroup className="my-2">
            {id && application?.status?.description === "Pending Approval" && (
              <>
              <Button className="Button" onClick={handleBtnDelete}>
                Delete
              </Button>
              &nbsp; &nbsp;
              </>
            )}

            {id && application?.status?.description === "Approved by Supervisor" && (
              <>
              <Button className="Button" onClick={handleBtnWithdraw}>
                Withdraw
              </Button>
              &nbsp; &nbsp;
              </>
            )}

            {id && application?.status?.description !== "Pending Approval" && (
              <Button className="Button" disabled>
                Submit
              </Button>
            )}
            {id && application?.status?.description === "Pending Approval" && (
              <Button className="Button" onClick={handleBtnEdit}>
                Submit
              </Button>
            )}
            {!id && (
              <Button className="Button" onClick={handleBtnSubmit}>
                Submit
              </Button>
            )}
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};
export default LeaveApplicationForm;


// import { useState, useEffect } from "react";
// import Select from "react-select";
// import {
//   Container,
//   Card,
//   Row,
//   Col,
//   Form,
//   ButtonGroup,
//   Button,
//   Image
// } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import moment from "moment";
// import Swal from "sweetalert2";
// import { ThreeDots } from 'react-loader-spinner';
// import {
//   getLeaveBalances,
//   updateApplication,
//   updateBalance,
//   postLeaveApplication,
//   getDaysApplying,
//   resetForm,
//   getLeaveDetailsByID,
//   updateReason,
//   withdrawLeaveApplication,
//   getLeaveApplicationDetails,
//   deleteLeaveApplication,
//   getMedicalCert,
//   addMedicalCertificate,
//   editMedicalCertificate,
//   updateApplicationDetails,
//   editLeaveApplication,
//   updateCert,
//   addSpecialAttachment,
//   getSpecialAttachment,
//   editSpecialAttachment
// } from "../../3.Store/ApplicationSlice";
// import { getListsOfLeaveWithdrawal } from "../../3.Store/LeaveWithdrawalSlice";
// import { openEditor } from "react-profile";
// import "react-profile/themes/default";

// const LeaveApplicationForm = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const {id} = useParams();
//   const [loading, setLoading] = useState(false);
//   const [fullImage, setFullImage] = useState(null);
//   const withdrawalList = useSelector(state => state.LeaveWithdrawalSlice.lists);

//   const employee = JSON.parse(localStorage.getItem("current_user"));
//   const userId = employee?.id;

//   const [showBalance, setShowBalance] = useState("");

//   const { application, balance, applying, reason, cert, applicationDetails } = useSelector(
//     (state) => state.ApplicationSlice
//   );
//   const {
//     leave_type,
//     location_type,
//     reason_for_application,
//     number_of_days,
//     numberofday_type,
//     from_date,
//     from_ampm,
//     to_date,
//     to_ampm,
//     description,
//     status,
//   } = application;
//   const { reason_withdrawal } = reason;

//   console.log(applicationDetails)

//   useEffect(() => {
//     setLoading(true)
//     if(id){
//       dispatch(getLeaveDetailsByID(id));
//       dispatch(getLeaveApplicationDetails({id}))
//       .then(() => {
//         //dispatch(getMedicalCert({id}));
//         setLoading(false);
//       })
//       .catch(() => {
//         setLoading(false);
//       });
//     }else{
//       dispatch(resetForm());
//       dispatch(getLeaveBalances({ id: userId }))
//       .then(() => {
//         setLoading(false);
//       })
//       .catch(() => {
//         setLoading(false);
//       });
//     }
//   }, [id, userId]);

//   useEffect(() => {
//     // Function to fetch data based on leave type
//     const fetchData = async () => {
//       try {
//         setLoading(true); // Set loading to true when useEffect starts

//         if (leave_type?.id === "SI") {
//           await dispatch(getMedicalCert({ id }));
//         } else if (leave_type?.id === "SP") {
//           await dispatch(getSpecialAttachment({ id }));
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false); // Set loading to false after dispatch completes (success or failure)
//       }
//     };

//     fetchData();

//   }, [leave_type?.id]);

//   useEffect(() => {
//     if (status?.description.includes("Withdrawn") && status.description.includes("Withdrawn")) {
//       console.log("Status contains 'withdraw'. Perform specific actions here.");
//       dispatch(getListsOfLeaveWithdrawal());

//       if (withdrawalList && withdrawalList.length > 0) {
//         const matchedWithdrawal = withdrawalList.find(
//           (withdrawal) => withdrawal.leave_application.id === application.id
//         );
//         console.log(matchedWithdrawal)
//         dispatch(updateApplicationDetails({ ...matchedWithdrawal }));
//       }
//     }
//   }, [status])

//   useEffect(() => {
//     updateBalance(balance[leave_type]);
//     showBalanceAfter();

//     // if (numberofday_type?.id == "H") {
//     //   const entity = { ...application, number_of_days: "0.5" };
//     //   console.log(entity);
//     //   dispatch(updateApplication(entity));
//     // }

//     // if (numberofday_type?.id == "1") {
//     //   const entity = {
//     //     ...application,
//     //     number_of_days: "1.0",
//     //     from_ampm: "AM",
//     //     to_ampm: "PM",
//     //   };
//     //   console.log(entity);
//     //   dispatch(updateApplication(entity));
//     // }

//     // if (numberofday_type?.id == "M") {
//     //   const newFromDateTime = new Date(from_date.date);
//     //   const newToDateTime = new Date(to_date.date);
    
//     //   // Normalize the dates to ensure the time component is the same
//     //   newFromDateTime.setHours(0, 0, 0, 0);
//     //   newToDateTime.setHours(0, 0, 0, 0);

//     //   // Calculate the difference in milliseconds
//     //   const differenceInMs = newToDateTime - newFromDateTime;

//     //   // Convert milliseconds to days
//     //   const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
//     //   console.log(differenceInDays);
//     //   const entity = { ...application, number_of_days: differenceInDays };
//     //   console.log(entity);
//     //   dispatch(updateApplication(entity));
//     // }
//   }, [balance, leave_type]);

//   useEffect(() => {
//     const appendTime = (date, ampm) => {
//       return ampm === "AM" ? `${date} 08:00:00` : `${date} 13:00:00`;
//     };
  
//     const handleMCase = async () => {
//       const fromDateString = appendTime(moment(from_date.date).format("YYYY-MM-DD"), from_ampm);
//       const toDateString = appendTime(moment(to_date.date).format("YYYY-MM-DD"), to_ampm);
  
//       console.log(fromDateString);
//       console.log(leave_type.id);
  
//       const entity = {
//         ...applying,
//         employee: { id: userId },
//         from_datetime: fromDateString,
//         leave_type: { id: leave_type.id },
//         to_datetime: toDateString
//       };
  
//       console.log(entity);
  
//       // Await the dispatch call to get the updated days applying
//       try {
//         const updatedDaysApply = await dispatch(getDaysApplying(entity));
//         console.log(updatedDaysApply);
  
//         // Update the application with the correct number of days
//         const days_applying = { ...application, number_of_days: updatedDaysApply.payload };
//         await dispatch(updateApplication(days_applying));
//       } catch (error) {
//         console.error('Error updating days applying:', error);
//       }
//     };
  
//     if (!numberofday_type || !from_date || !to_date) {
//       return;
//     }
  
//     if (numberofday_type.id === "H") {
//       const updatedNumberOfDays = "0.5";
//       const updatedToDate = { date: from_date.date };
//       const updatedToAMPM = from_ampm;
  
//       if (
//         application.number_of_days !== updatedNumberOfDays ||
//         application.to_date.date !== updatedToDate.date ||
//         application.to_ampm !== updatedToAMPM
//       ) {
//         const entity = {
//           ...application,
//           number_of_days: updatedNumberOfDays,
//           to_date: updatedToDate,
//           to_ampm: updatedToAMPM
//         };
//         console.log(entity);
//         dispatch(updateApplication(entity));
//       }
//     } else if (numberofday_type.id === "1") {
//       const updatedNumberOfDays = "1.0";
//       const updatedFromAMPM = "AM";
//       const updatedToAMPM = "PM";
//       const updatedToDate = { date: from_date.date };
  
//       if (
//         application.number_of_days !== updatedNumberOfDays ||
//         application.from_ampm !== updatedFromAMPM ||
//         application.to_ampm !== updatedToAMPM
//       ) {
//         const entity = {
//           ...application,
//           number_of_days: updatedNumberOfDays,
//           from_ampm: updatedFromAMPM,
//           to_ampm: updatedToAMPM,
//           to_date: updatedToDate
//         };
//         console.log(entity);
//         dispatch(updateApplication(entity));
//       }
//     } else if (numberofday_type.id === "M") {
//       handleMCase();
//     }
//   }, [numberofday_type, from_date, to_date, from_ampm, to_ampm]);  

//   const LeaveTypeOption = [
//     { id: "A", label: "Annual Leave", value: "annual" },
//     { id: "AD", label: "Advanced Leave", value: "advanced" },
//     { id: "H", label: "Hospitalization", value: "hospitalization" },
//     { id: "M", label: "Maternity Leave", value: "maternity" },
//     { id: "O", label: "Other Leave", value: "other" },
//     { id: "SI", label: "Sick Leave", value: "sick" },
//     { id: "SP", label: "Special Leave", value: "special" },
//     { id: "UP", label: "Unpaid Leave", value: "unpaid" },
//   ];

//   const SpecialLeaveTypeOption = [
//     {
//       id: "1da74498-1637-11e9-9e53-5254006876cf",
//       label: "Convocation",
//       value: "co",
//     },
//     {
//       id: "287596e0-1637-11e9-9e53-5254006876cf",
//       label: "Paternity Leave",
//       value: "PL",
//     },
//     {
//       id: "093f0e92-1637-11e9-9e53-5254006876cf",
//       label: "Demise of Immediate Family",
//       value: "DIF",
//     },
//     {
//       id: "0ddd720c-1637-11e9-9e53-5254006876cf",
//       label: "Demise of Family",
//       value: "DF",
//     },
//     {
//       id: "185632cb-1637-11e9-9e53-5254006876cf",
//       label: "Wedding",
//       value: "W",
//     },
//   ];

//   const NoOfDaysOption = [
//     { id: "H", label: "1/2 Day", value: "half" },
//     { id: "1", label: "One Day", value: "one" },
//     { id: "M", label: "More than 1 Day", value: "more_than_one" },
//   ];

//   const LocationOption = [
//     { id: "I", label: "In Town", value: "in_town" },
//     { id: "OT", label: "Out of Town", value: "out_town" },
//     { id: "W", label: "West Malaysia", value: "w_msia" },
//   ];

//   const showBalanceAfter = () => {
//     if (leave_type.id == "A") {
//       setShowBalance(balance?.annual);
//     }
//     if (leave_type.id == "AD") {
//       setShowBalance(balance?.advanced);
//     }
//     if (leave_type.id == "H") {
//       setShowBalance(balance?.hospitalization);
//     }
//     if (leave_type.id == "M") {
//       setShowBalance(balance?.maternity);
//     }
//     if (leave_type.id == "SI") {
//       setShowBalance(balance?.sick);
//     }
//     if (leave_type.id == "SP") {
//       setShowBalance(balance?.special);
//     }
//   };

//   const handleChangeForm = (e) => {
//     console.log(e);
//     const entity = { ...application, [e.target.name]: e.target.value };
//     console.log(entity);
//     dispatch(updateApplication(entity));
//   };

//   const handleChangeDropdownLeaveType = (value) => {
//     if(value.id==="SP") {
//       const entity = {
//         ...application,
//         leave_type: {
//           id: value.id,
//         },
//         special_leave_type: {
//           id: "1da74498-1637-11e9-9e53-5254006876cf"
//         }
//       };
//       console.log(entity);
//       dispatch(updateApplication(entity));
//     } else {
//       const entity = {
//         ...application,
//         leave_type: {
//           id: value.id,
//         }
//       };
//       console.log(entity);
//       dispatch(updateApplication(entity));
//     }
//   };

//   const getSpecialLeaveLabel = (value) => {
//     const special = SpecialLeaveTypeOption.find(length => length.id === value);
//     return special ? special.label : "";
//   };

//   const handleChangeDropdownSpecialLeaveType = (value) => {
//     console.log(value);
//     const entity = {
//       ...application,
//       special_leave_type: {
//         id: value.id,
//         // description: action.label,
//       },
//     };
//     dispatch(updateApplication(entity));
//     console.log(entity);
//   };

//   const handleChangeDropdownNoDays = (action) => {
//     console.log(action);
//     const entity = {
//       ...application,
//       numberofday_type: {
//         id: action.id,
//         // description: action.label,
//       },
//     };
//     dispatch(updateApplication(entity));
//     console.log(entity);
//   };

//   const handleChangeFromDate = (e) => {
//     console.log(e);
//     const entity = { ...application, from_date: { date: e.target.value } };
//     console.log(entity);
//     dispatch(updateApplication(entity));
//   };

//   const handleChangeToDate = (e) => {
//     console.log(e);
//     const entity = { ...application, to_date: { date: e.target.value } };
//     console.log(entity);
//     dispatch(updateApplication(entity));
//   };

//   const handleChangeDropdownLocation = (action) => {
//     console.log(action);
//     const entity = {
//       ...application,
//       location_type: {
//         id: action.id,
//         // description: action.label,
//       },
//     };
//     dispatch(updateApplication(entity));
//     console.log(entity);
//   };

//   const handleReasonWithdrawal = (e) => {
//     const entity = {...reason, reason_withdrawal: e.target.value};
//     console.log(entity)
//     dispatch(updateReason(entity));
//   }

//   const handleBtnWithdraw = () => {
//     if (!reason_withdrawal.trim()) {
//       // Display an error message if the reason is empty
//       Swal.fire("Error", "Please provide a reason for withdrawal", "error", "OK");
//       return;
//     }
  
//     Swal.fire({
//       title: 'Are you sure?',
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonText: 'Yes',
//       cancelButtonText: 'No',
//       reverseButtons: true
//     }).then((result) => {
//       if (result.isConfirmed) {
//         setLoading(true);
//         dispatch(withdrawLeaveApplication({id:id, ...reason}))
//           .unwrap()
//           .then(() => {
//             setLoading(false);
//             Swal.fire({
//               title: "Request for Withdrawal is submitted (subjected to approval)",
//               icon: "success",
//               showConfirmButton: true,
//             }).then((result) => {
//               if (result.isConfirmed) {
//                 navigate("/LeaveApplicationList");
//               }
//             });
//           })
//           .catch((err) => {
//             Swal.fire("Error", err.response.data, "error", "OK");
//             setLoading(false);
//           });
//       } else if (result.dismiss === Swal.DismissReason.cancel) {
//           Swal.fire(
//             'Cancelled',
//             'The approval has been cancelled',
//             'error'
//           );
//       }
//     });
//   }  

//   const handleBtnBack = () => {
//     dispatch(resetForm());
//     navigate(-1);
//   };

//   const handleBtnDelete = () => {
//     Swal.fire({
//       title: 'Are you sure?',
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonText: 'Yes',
//       cancelButtonText: 'No',
//       reverseButtons: true
//     }).then((result) => {
//       if (result.isConfirmed) {
//         setLoading(true);
//         dispatch(deleteLeaveApplication({ id }))
//           .unwrap()
//           .then(() => {
//             setLoading(false);
//             Swal.fire('Deleted!', 'Leave application has been deleted.', 'success')
//               .then(() => {
//                 navigate("/LeaveApplicationList");
//               });
//           })
//           .catch((err) => {
//             setLoading(false);
//             Swal.fire("Error", err.response.data, "error");
//           });
//       } else if (result.dismiss === Swal.DismissReason.cancel) {
//         Swal.fire('Cancelled', 'Cancel Delete Leave Application', 'error');
//       }
//     });
//   };

//   const handleBtnSubmit = () => {
//     setLoading(true);
//     try {
//       let day_description;
//       let toDate=to_date.date;

//       if (numberofday_type.id === "M") {
//         day_description = `${number_of_days}D from ${from_date.date} ${from_ampm} to ${to_date.date} ${to_ampm}`;
//       } else if (numberofday_type.id === "H") {
//         day_description = `${number_of_days}D on ${from_date.date} ${from_ampm}`;
//       } else if (numberofday_type.id === "1") {
//         day_description = `${number_of_days}D on ${from_date.date}`;
//         toDate = from_date.date;
//       }

//       const entity = {
//         ...application,
//         description: day_description,
//         to_date: {date:toDate}
//       };

//       dispatch(postLeaveApplication(entity))
//       .unwrap()
//       .then((response) => {
//         const applicationId = response.id; // Assume the response contains the ID of the created application

//         // Add sick or special attachment based on leave type
//         if (leave_type.id === "SI") {
//           const entity2 = { ...cert, leave_application: {id:applicationId}};
//           dispatch(addMedicalCertificate(entity2));
//         } else if (leave_type.id === "SP") {
//           const entity3 = { ...cert, leave_application: {id:applicationId}};
//           dispatch(addSpecialAttachment(entity3));
//         }
        
//         setLoading(false);
//         Swal.fire("Leave Application Created", "", "success", "OK").then(() => {  
//           dispatch(resetForm());
//           navigate("/LeaveApplicationList");
//         });
//       })
//       .catch((err) => {
//         setLoading(false);
//         Swal.fire("Error / Masalah", err.response.data, "error", "OK");
//       });
//     } catch (err) {
//       setLoading(false);
//       Swal.fire("Error / Masalah", err.response.data, "error", "OK");
//     }
//   };

//   const handleBtnEdit = () => {
//     setLoading(true);
//     const entity = { ...application };
  
//     dispatch(editLeaveApplication(entity))
//       .unwrap()
//       .then(() => {
//         if (leave_type.id === "SI" && cert.id) {
//           //const entity2 = { ...cert, leave_application: { id: entity.id } };
//           dispatch(editMedicalCertificate({id:cert.id, ...cert}));
//         }
//         else if (leave_type.id === "SI" && !cert.id) {
//           const entity2 = { ...cert, leave_application: { id: entity.id } };
//           dispatch(addMedicalCertificate(entity2));
//         }
//         else if (leave_type.id === "SP" && cert.id) {
//           //const entity2 = { ...cert, leave_application: { id: entity.id } };
//           dispatch(editSpecialAttachment({id:cert.id, ...cert}));
//         }
//         else if (leave_type.id === "SP" && !cert.id) {
//           const entity2 = { ...cert, leave_application: { id: entity.id } };
//           dispatch(addSpecialAttachment(entity2));
//         }
  
//         setLoading(false);
//         Swal.fire("Record Updated", "", "success", "OK").then(() => {
//           navigate(`/LeaveApplicationList`);
//         });
//       })
//       .catch((err) => {
//         setLoading(false);
//         Swal.fire("Error", err.response.data, "error", "OK");
//       });
//   };

//   const handleAttachmentChange = (event) => {
//     const selectedFile = event.target.files[0];

//     if (selectedFile) {
//       const reader = new FileReader();
  
//       reader.onloadend =  async () => {
//         const fileResult = reader.result;
//         let base64String;
  
//         if (selectedFile.type === 'application/pdf') {
//           // For PDF files, strip off the data URL prefix
//           base64String = fileResult.split(',')[1];
//         } else {
//           // For images or other file types, use the full data URL
//           setFullImage(fileResult);
//           const editor = await openEditor({ src: selectedFile });
//           base64String = editor?.editedImage?.getDataURL();
//         }
  
//         const entity = { ...cert,
//           file: base64String,
//           filename: selectedFile.name,
//           filetype: selectedFile.type,
//           filesize: selectedFile.size
//         };
//         console.log(entity);
//         dispatch(updateCert(entity));
  
//         // Log file details
//         console.log("Base64 File:", base64String);
//         console.log("Filename:", selectedFile.name);
//         console.log("Filetype:", selectedFile.type);
//         console.log("Filesize:", selectedFile.size, "bytes");
//       };
  
//       // Read the file as a data URL
//       reader.readAsDataURL(selectedFile);
//     }
//   };

//   const handleEditImage = async () => {
//     if (fullImage) {
//       try {
//         const editor = await openEditor({ src: fullImage });

//         if (editor && editor.editedImage) {
//           const updatedImage = editor.editedImage.getDataURL();
//           const entity = { ...cert, file: updatedImage };
//           dispatch(updateCert(entity));
//         } else {
//           console.error("Edited image not found.");
//         }
//       } catch (error) {
//         console.error("Error opening editor:", error);
//       }
//     } else {
//       console.error("Full image is not set.");
//     }
//   };

//   return (
//     <Container>
//       {loading && (
//         <ThreeDots
//           visible={loading}
//           height="80"
//           width="80"
//           color="#4fa94d"
//           radius="9"
//           ariaLabel="three-dots-loading"
//           wrapperStyle={{
//             position: 'fixed',
//             top: '0',
//             left: '0',
//             width: '100%',
//             height: '100%',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             backgroundColor: 'rgba(0, 0, 0, 0.5)',
//             zIndex: '9999',
//           }}
//         />
//       )}
//       <Card className="CardContent">
//         <h1 className="TitleHeader">Leave Application</h1>
//         <Row className="my-3">
//           <Col md={8} sm={8}>
//             <Form.Label className="FormLabel">Leave Type</Form.Label>
//           </Col>
//           <Col md={4} sm={4}>
//             {!id ? (
//               <Select
//                 name="leave_type"
//                 options={LeaveTypeOption}
//                 value={LeaveTypeOption.find(
//                   (option) => option.id === leave_type.id
//                 )}
//                 onChange={handleChangeDropdownLeaveType}
//               />
//             ) : (
//               <Form.Control readOnly value={application?.leave_type?.description} />
//             )}
            
//           </Col>
//         </Row>

//         {/* Leave Available */}
//         {!id && leave_type?.id !== "O" && leave_type?.id !== "UP" && leave_type?.id !== "AD" && (
//           <Row className="my-2">
//             <Col md={8} sm={8}>
//               <Form.Label className="FormLabel">Leave Available</Form.Label>
//             </Col>
//             <Col md={4} sm={4}>
//               <Form.Control readOnly value={showBalance} />
//             </Col>
//           </Row>
//         )}

//         {/* Leave Advanced */}
//         {leave_type?.id == "AD" && (
//           <Row className="my-2">
//             <Col md={8} sm={8}>
//               <Form.Label className="FormLabel">Leave Advanced</Form.Label>
//             </Col>
//             <Col md={4} sm={4}>
//               <Form.Control readOnly value={showBalance} />
//             </Col>
//           </Row>
//         )}

//         {leave_type?.id == "SI" && (
//           <Row className="my-2">
//             <Col md={8} sm={8}>
//               <Form.Label className="FormLabel">Medical Certificate</Form.Label>
//             </Col>
//             <Col md={4} sm={4}>
//               <Form.Control type="file" onChange={handleAttachmentChange}/>
//             </Col>
//           </Row>
//         )}

//         {/* Special Leave */}
//         {!id && leave_type?.id === "SP" && (
//           <>
//             <Row className="my-2">
//               <Col md={8} sm={8}>
//                 <Form.Label className="FormLabel">
//                   Special Leave Type
//                 </Form.Label>
//               </Col>
//               <Col md={4} sm={4}>
//                 <Select
//                   name="special_leave_type"
//                   options={SpecialLeaveTypeOption}
//                   onChange={handleChangeDropdownSpecialLeaveType}
//                 />
//               </Col>
//             </Row>
//           </>
//         )}

//         {leave_type?.id == "SP" && (
//           <Row className="my-2">
//             <Col md={8} sm={8}>
//               <Form.Label className="FormLabel">Attachment</Form.Label>
//             </Col>
//             <Col md={4} sm={4}>
//               <Form.Control type="file" onChange={handleAttachmentChange} />
//             </Col>
//           </Row>
//         )}


//         {/* Conditional rendering based on attachment existence */}
//         {cert && cert.file && (
//           <Row className="my-3">
//             <Col md={12} sm={12} className="d-flex justify-content-center align-items-center">
//               {/* Display image */}
//               {cert.filetype.startsWith('image/') ? (
//                 <Image src={cert.file} alt="Uploaded file" fluid onClick={handleEditImage}/>
//               ) : (
//                 // Display PDF
//                 <iframe
//                   src={`data:${cert.filetype};base64,${cert.file}`}
//                   title="PDF file"
//                   width="100%"
//                   height="500px"
//                 ></iframe>
//               )}
//             </Col>
//           </Row>
//         )}

//         <Row className="my-2">
//           <Col md={8} sm={8}>
//             <Form.Label className="FormLabel">No. of Days</Form.Label>
//           </Col>
//           <Col md={4} sm={4}>
//             <Select
//               name="numberofday_type"
//               options={NoOfDaysOption}
//               value={NoOfDaysOption.find(
//                 (option) => option.id === numberofday_type.id
//               )} // Correctly set the value to match the selected option
//               onChange={handleChangeDropdownNoDays}
//             />
//           </Col>
//         </Row>

//         <Row className="my-2">
//           <Col md={4} sm={4}>
//             {numberofday_type.id === "M" ? (
//               <Form.Label className="FormLabel">From</Form.Label>
//             ) : (
//               <Form.Label className="FormLabel">Starting From</Form.Label>
//             )}
//           </Col>
//           <Col md={4} sm={4}>
//             {numberofday_type.id !== "1" && (
//               <div>
//                 <Form.Check
//                   type="radio"
//                   label="AM"
//                   name="from_ampm"
//                   value="AM"
//                   style={{ color: "#fff" }}
//                   checked={from_ampm === "AM"}
//                   onChange={handleChangeForm}
//                 />
//                 <Form.Check
//                   type="radio"
//                   label="PM"
//                   name="from_ampm"
//                   value="PM"
//                   style={{ color: "#fff" }}
//                   checked={from_ampm === "PM"}
//                   onChange={handleChangeForm}
//                 />
//               </div>
//             )}
//           </Col>
//           <Col md={4} sm={4}>
//             <Form.Control
//               type="date"
//               name="from_date"
//               value={moment(from_date?.date).format("YYYY-MM-DD")}
//               onChange={handleChangeFromDate}
//             />
//           </Col>
//         </Row>
//         {numberofday_type.id === "M" && (
//           <Row className="my-2">
//             <Col md={4} sm={4}>
//               <Form.Label className="FormLabel">To</Form.Label>
//             </Col>
//             <Col md={4} sm={4}>
//               <div>
//                 <Form.Check
//                   type="radio"
//                   label="AM"
//                   name="to_ampm"
//                   value="AM"
//                   style={{ color: "#fff" }}
//                   checked={to_ampm === "AM"}
//                   onChange={handleChangeForm}
//                 />
//                 <Form.Check
//                   type="radio"
//                   label="PM"
//                   name="to_ampm"
//                   value="PM"
//                   style={{ color: "#fff" }}
//                   checked={to_ampm === "PM"}
//                   onChange={handleChangeForm}
//                 />
//               </div>
//             </Col>
//             <Col md={4} sm={4}>
//               <Form.Control
//                 type="date"
//                 name="to_date"
//                 value={moment(to_date?.date).format("YYYY-MM-DD")}
//                 onChange={handleChangeToDate}
//               />
//             </Col>
//           </Row>
//         )}

//         <Row className="my-2">
//           <Col md={8} sm={8}>
//             <Form.Label className="FormLabel">Location</Form.Label>
//           </Col>
//           <Col md={4} sm={4}>
//             <Select
//               name="location_type"
//               options={LocationOption}
//               value={LocationOption.find(
//                 (option) => option.id === location_type.id
//               )} // Correctly set the value to match the selected option
//               onChange={handleChangeDropdownLocation}
//             />
//           </Col>
//         </Row>

//         <Row className="my-2">
//           <Col md={8} sm={8}>
//             <Form.Label className="FormLabel">No. Days Applying</Form.Label>
//           </Col>
//           <Col md={4} sm={4}>
//             <Form.Control readOnly value={number_of_days} />
//           </Col>
//         </Row>

//         {id && (
//           <>
//           <Row className="my-2">
//             <Col md={8} sm={8}>
//               <Form.Label className="FormLabel">Applied On</Form.Label>
//             </Col>
//             <Col md={4} sm={4}>
//               <Form.Control readOnly value={moment(application.created).format("YYYY-MM-DD")} />
//             </Col>
//           </Row>

//           <Row className="my-2">
//             <Col md={8} sm={8}>
//               <Form.Label className="FormLabel">Status</Form.Label>
//             </Col>
//             <Col md={4} sm={4}>
//               <Form.Control readOnly value={status?.description} />
//             </Col>
//           </Row>

//           <Row className="my-2">
//             <Col md={12} sm={12}>
//               <Form.Label className="FormLabel">Description</Form.Label>
//               <Form.Control as="textarea" value={description} readOnly />
//             </Col>
//           </Row>
//           </>
//         )}

//         <Row className="my-2">
//           <Col md={12} sm={12}>
//             <Form.Label className="FormLabel">Reason</Form.Label>
//             <Form.Control 
//               as="textarea"
//               name="reason_for_application"
//               value={reason_for_application}
//               onChange={handleChangeForm}
//             />
//           </Col>
//         </Row>

//         {id && application?.status?.description !== "Pending Approval" && (
//           <>
//           <Row className="my-2">
//             <Col md={12} sm={12}>
//               <Form.Label className="FormLabel">Remark</Form.Label>
//               <Form.Control as="textarea" value={application.remark} readOnly />
//             </Col>
//           </Row>
//           <hr style={{ borderTop: '2px solid white' }}/>
//           {(application?.status?.description === "Leave Withdrawn by staff (Pending Approval)" || application?.status?.description === "Leave Withdrawn by staff with Approval") && (
//             <Row className="my-2">
//               <Col md={12} sm={12}>
//                 <Form.Label className="FormLabel">HR Remark for Withdrawal</Form.Label>
//                 <Form.Control as="textarea" value={applicationDetails?.remark} readOnly/>
//               </Col>
//             </Row>
//           )}
//           {application?.status?.description === "Approved by Supervisor" && (
//             <Row className="my-2">
//             <Col md={12} sm={12}>
//               <Form.Label className="FormLabel">Reason for Withdrawal</Form.Label>
//               <Form.Control as="textarea" name="reason_withdrawal" value={reason_withdrawal} onChange={handleReasonWithdrawal}/>
//             </Col>
//           </Row>
//           )}
//           </>
//         )}

//         <div className="d-flex justify-content-between">
//           <ButtonGroup className="my-2">
//             <Button className="Button" onClick={handleBtnBack}>
//               Back
//             </Button>
//           </ButtonGroup>
//           <ButtonGroup className="my-2">
//             {id && application?.status?.description === "Pending Approval" && (
//               <>
//               <Button className="Button" onClick={handleBtnDelete}>
//                 Delete
//               </Button>
//               &nbsp; &nbsp;
//               </>
//             )}

//             {id && application?.status?.description === "Approved by Supervisor" && (
//               <>
//               <Button className="Button" onClick={handleBtnWithdraw}>
//                 Withdraw
//               </Button>
//               &nbsp; &nbsp;
//               </>
//             )}

//             {id && application?.status?.description !== "Pending Approval" && (
//               <Button className="Button" disabled>
//                 Submit
//               </Button>
//             )}
//             {id && application?.status?.description === "Pending Approval" && (
//               <Button className="Button" onClick={handleBtnEdit}>
//                 Submit
//               </Button>
//             )}
//             {!id && (
//               <Button className="Button" onClick={handleBtnSubmit}>
//                 Submit
//               </Button>
//             )}
//           </ButtonGroup>
//         </div>
//       </Card>
//     </Container>
//   );
// };
// export default LeaveApplicationForm;