import {
  Container,
  Card,
  Row,
  Col,
  Form,
  ButtonGroup,
  Button,
  Image
} from "react-bootstrap";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  
  approveReviewMedicalClaims, 
  rejectReviewMedicalClaims,  
  getAttachments,
  getAMedicalClaim
} from "../../3.Store/ReviewMedicalClaimsSlice";
import { ThreeDots } from 'react-loader-spinner';
import jsPDF from 'jspdf';

const HRReviewMedicalForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const details = useSelector(state => state.ReviewMedicalClaimsSlice.details);
  const attachment = useSelector(state => state.ReviewMedicalClaimsSlice.attachment);
  const [loading, setLoading] = useState(false);
  const [pdfSrc, setPdfSrc] = useState(null);

  useEffect(() => {
    setLoading(true);
    if (id) {
      dispatch(getAMedicalClaim({id}))
        .then(() => {
          setLoading(false);
          dispatch(getAttachments({id}))
        })
    }
  }, [id]);

  useEffect(() => {
    const generatePDF = () => {
      if (attachment?.filetype?.startsWith('image/')) {
        // Strip off the data URL prefix to get the base64 string
        const base64String = attachment.file.split(',')[1];

        // Initialize jsPDF
        const pdf = new jsPDF();

        // Add image to PDF
        pdf.addImage(base64String, 'PNG', 10, 10, 180, 160, '', 'BASE64'); // Adjust position and size as needed

        // Generate the PDF as base64
        const pdfData = pdf.output('datauristring');
        console.log(pdfData);
        // Set the PDF source to be displayed in an iframe
        setPdfSrc(pdfData);
      }
    };

    generatePDF();
  }, [attachment]);

  const handleBtnReject = () => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        dispatch(rejectReviewMedicalClaims([details.id]))
          .unwrap()
          .then(() => {
            setLoading(false);
            Swal.fire("Medical Claim Rejected", "", "success", "OK")
            .then(() => {
              navigate(`/HRReviewMedicalList`);
            })
          })
          .catch((err) => {
            Swal.fire("Error", err.response.data, "error", "OK");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'The rejection has been cancelled',
            'error'
          );
      }
    });
  };

  const handleBtnBack = () => {
    const queryParams = new URLSearchParams(location.search);
    const currentPage = queryParams.get('currentPage') || 1;
    const searchText = queryParams.get('searchText') || ''; // Retrieve search text from query parameters
    const fromDate = queryParams.get('fromDate') || '';
    const toDate = queryParams.get('toDate') || '';
    navigate(`/HRReviewMedicalList?currentPage=${currentPage}&searchText=${searchText}&fromDate=${fromDate}&toDate=${toDate}`); // Navigate back with current page and search text
  };

  const handleBtnApprove = () => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        dispatch(approveReviewMedicalClaims([details.id]))
          .unwrap()
          .then(() => {
            setLoading(false);
            Swal.fire("Medical Claim Approved", "", "success", "OK")
            .then(() => {
              navigate(`/HRReviewMedicalList`);
            })
          })
          .catch((err) => {
            Swal.fire("Error", err.response.data, "error", "OK");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'The approval has been cancelled',
            'error'
          );
      }
    });
  };

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">HR Review Medical Claim</h1>
        <Row className="my-3">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">Staff Name</Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control value={details?.employee?.fullname} readOnly/>
          </Col>
        </Row>

        <Row className="my-3">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">Invoice Date</Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control type="date" value={moment(details?.claim_date).format("YYYY-MM-DD")} readOnly/>
          </Col>
        </Row>

        <Row className="my-3">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">Practitioner</Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control value={details?.receipt_no} readOnly />
          </Col>
        </Row>

        <Row className="my-3">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">Claim</Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control value={details?.amount} readOnly />
          </Col>
        </Row>

        <Row className="my-3">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">Receipt No</Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control value={details?.receipt_reference} readOnly />
          </Col>
        </Row>

        {/* Conditional rendering based on attachment existence */}
        {attachment && attachment.file && (
          <Row className="my-3">
            <Col md={12} sm={12} className="d-flex justify-content-center align-items-center">
              {/* Display image */}
              {attachment.filetype.startsWith('image/') ? (
                <Image src={attachment.file} alt="Uploaded file" fluid />
                // <iframe
                //   src={pdfSrc}
                //   title="PDF file"
                //   width="100%"
                //   height="500px"
                // ></iframe>
              ) : (
                // Display PDF
                <iframe
                  src={`data:application/pdf;base64,${attachment.file}`}
                  title="PDF file"
                  width="100%"
                  height="500px"
                ></iframe>
              )}
            </Col>
          </Row>
        )}

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>

          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnReject}>
              Reject
            </Button>
            &nbsp; &nbsp;
            <Button className="Button" onClick={handleBtnApprove}>
              Approve
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};
export default HRReviewMedicalForm;
