import React, { useState, useEffect } from 'react';
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  ButtonGroup,
  Button,
  Image
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { ThreeDots } from 'react-loader-spinner';
import Swal from "sweetalert2";
import { getLeaveBalances } from "../../3.Store/ApplicationSlice";
import { 
  resetClaim,
  getListDetails,
  updateMedicalClaim,
  getMedicalClaimLists,
  getAMedicalClaimAttachment,
  updateAttachment,
  editMedicalClaim,
  deleteMedicalClaim,
  editMedicalClaimAttachment,
  addMedicalClaim,
  addMedicalClaimAttachment,
  getMedicalClaimByLeaveApplicationID
} from '../../3.Store/MedicalClaimSlice';
import { openEditor } from "react-profile";
import "react-profile/themes/default";

const MedicalClaimForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, leaveApplicationId } = useParams();
  const [loading, setLoading] = useState(false);
  const [fullImage, setFullImage] = useState(null);
  const employee = JSON.parse(localStorage.getItem("current_user"));
  const userId = employee?.id;
  const medical_claim = useSelector(state => state.MedicalClaimSlice.medicalClaim);
  const currentPage = useSelector(state => state.MedicalClaimSlice.currentPage);
  const attachment = useSelector(state => state.MedicalClaimSlice.attachment);
  const { balance } = useSelector((state) => state.ApplicationSlice);
  const {
    amount,
    receipt_reference,
    receipt_no,
    claim_date
  } = medical_claim || {};

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (id) {
          await dispatch(getMedicalClaimLists({ id: userId, page: currentPage }));
          await dispatch(getLeaveBalances({ id: userId }));
          await dispatch(getListDetails(id));
          await dispatch(getAMedicalClaimAttachment({ id }));
        } 
        else if (leaveApplicationId) {
          const getMedical = await dispatch(getMedicalClaimByLeaveApplicationID({ id: leaveApplicationId })).unwrap();
          const claimID = getMedical.id;
          await dispatch(getAMedicalClaimAttachment({ id:claimID }));
          await dispatch(getLeaveBalances({ id: userId }));
        }
        else {
          dispatch(resetClaim());
          const entity = { ...medical_claim, claim_date: moment(new Date()).format("YYYY-MM-DD") };
          console.log(entity);
          dispatch(updateMedicalClaim(entity));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [id, leaveApplicationId, userId]);
  

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
  
    if (selectedFile) {
      const reader = new FileReader();
  
      reader.onloadend = async () => {
        const fileResult = reader.result;
        let base64String;
  
        if (selectedFile.type === 'application/pdf') {
          // For PDF files, strip off the data URL prefix
          base64String = fileResult.split(',')[1];
        } else {
          // For images or other file types, use the full data URL
          setFullImage(fileResult);
          const editor = await openEditor({ src: selectedFile });
          base64String = editor?.editedImage?.getDataURL();
        }
  
        const entity = { ...attachment,
          file: base64String,
          filename: selectedFile.name,
          filetype: selectedFile.type,
          filesize: selectedFile.size
        };
        console.log(entity);
        dispatch(updateAttachment(entity));
  
        // Log file details
        console.log("Base64 File:", base64String);
        console.log("Filename:", selectedFile.name);
        console.log("Filetype:", selectedFile.type);
        console.log("Filesize:", selectedFile.size, "bytes");
      };
  
      // Read the file as a data URL
      reader.readAsDataURL(selectedFile);
    }
  }; 

  const handleFormChange = (e) => {
    const entity = { ...medical_claim, [e.target.name]: e.target.value };
    console.log(entity);
    dispatch(updateMedicalClaim(entity));
  };

  const handleBtnBack = () => {
    dispatch(resetClaim());
    navigate(-1);
  };

  const handleBtnWithdraw = () => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const entity = { ...medical_claim, status: {id: -200}};
        dispatch(editMedicalClaim({id, ...entity}))
          .unwrap()
          .then(() => {
            setLoading(false);
            Swal.fire("Medical Claim Withdrawn", "", "success", "OK")
            .then(() => {
              navigate(-1);
            })
          })
          .catch((err) => {
            Swal.fire("Error", err.response.data, "error", "OK");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'The withdrawal has been cancelled',
            'error'
          );
      }
    });
  };

  const handleBtnSubmit = async () => {
    setLoading(true);
    try {
      await dispatch(editMedicalClaim({ id, ...medical_claim })).unwrap();
      const entity = { ...attachment, id: id}
      await dispatch(editMedicalClaimAttachment({ ...entity })).unwrap();
      setLoading(false);
      await Swal.fire("Medical Claim Submitted", "", "success");
      dispatch(resetClaim());
      navigate("/MedicalClaimList");
    } catch (err) {
      setLoading(false);
      Swal.fire("Error", err.response?.data || "An error occurred", "error");
    }
  };

  const handleBtnDelete = () => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        dispatch(deleteMedicalClaim({id}))
          .unwrap()
          .then(() => {
            setLoading(false);
            Swal.fire("Medical Claim Deleted", "", "success", "OK")
            .then(() => {
              navigate("/MedicalClaimList");
            })
          })
          .catch((err) => {
            Swal.fire("Error", err.response.data, "error", "OK");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Deletion Canceled',
            'error'
          );
      }
    });
  };

  const handleBtnAdd = async () => {
    setLoading(true);
    try {
      const addedClaim = await dispatch(addMedicalClaim({ ...medical_claim })).unwrap();
      const claimId = addedClaim.id;
      console.log(claimId)
      const entity = { ...attachment, id: {id: claimId}};
      await dispatch(addMedicalClaimAttachment({ ...entity })).unwrap();
      setLoading(false);
      await Swal.fire("Medical Claim Added", "", "success");
      navigate("/MedicalClaimList");
    } catch (err) {
      setLoading(false);
      Swal.fire("Error", err.response?.data || "An error occurred", "error");
    }
  };

  const handleBtnAddClaimLeave = async () => {
    setLoading(true);
    try {
      const entity = { ...medical_claim, leave_application: {id: leaveApplicationId}};
      const addedClaim = await dispatch(addMedicalClaim({ ...entity })).unwrap();
      const claimId = addedClaim.id;
      const entity2 = { ...attachment, id: {id: claimId}};
      await dispatch(addMedicalClaimAttachment({ ...entity2 })).unwrap();
      setLoading(false);
      await Swal.fire("Medical Claim Added", "", "success");
      navigate(-1);
    } catch (err) {
      setLoading(false);
      Swal.fire("Error", err.response?.data || "An error occurred", "error");
    }
  };

  const handleEditImage = async () => {
    if (fullImage) {
      try {
        const editor = await openEditor({ src: fullImage });

        if (editor && editor.editedImage) {
          const updatedImage = editor.editedImage.getDataURL();
          const entity = { ...attachment, file: updatedImage };
          dispatch(updateAttachment(entity));
        } else {
          console.error("Edited image not found.");
        }
      } catch (error) {
        console.error("Error opening editor:", error);
      }
    } else {
      console.error("Full image is not set.");
    }
  };

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Medical Claim</h1>
        <Row className="my-3">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">Invoice Date</Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control type="date" name="claim_date" value={moment(claim_date).format("YYYY-MM-DD")} onChange={handleFormChange}/>
          </Col>
        </Row>

        <Row className="my-3">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">Practitioner</Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control name="receipt_reference" value={receipt_reference} onChange={handleFormChange}/>
          </Col>
        </Row>

        <Row className="my-3">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">
              Claim (RM) Balance RM{balance.medical_benefit}
            </Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control name="amount" value={amount} onChange={handleFormChange}/>
          </Col>
        </Row>

        <Row className="my-3">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">Receipt No</Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control name="receipt_no" value={receipt_no} onChange={handleFormChange}/>
          </Col>
        </Row>

        <Row className="my-3">
          <Col md={12} sm={12}>
            <Form.Control type="file" onChange={handleFileChange} />
          </Col>
        </Row>

        {/* Conditional rendering based on attachment existence */}
        {attachment && attachment.file && (
          <Row className="my-3">
            <Col md={12} sm={12} className="d-flex justify-content-center align-items-center">
              {/* Display image */}
              {attachment.filetype.startsWith('image/') && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px',
                    backgroundColor: '#373c40',
                    height: '500px',
                    width: "100%",
                    overflow: 'auto',
                  }}
                >
                  <Image
                    src={attachment.file}
                    alt="Uploaded file"
                    fluid
                    style={{
                      maxWidth: '100%',
                      height: '100%',
                    }}
                    onClick={handleEditImage} // Optional: Click handler
                  />
                </div>
              )}

              {/* Display PDF */}
              {!attachment.filetype.startsWith('image/') && (
                <iframe
                  src={`data:${attachment.filetype};base64,${attachment.file}`}
                  title="PDF file"
                  width="100%"
                  height="500px"
                ></iframe>
              )}
            </Col>
          </Row>
        )}

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>
          <ButtonGroup className="my-2">
            {id && (
              <>
              {medical_claim?.status?.description === "PENDING" ? (
                <>
                <Button className="Button" onClick={handleBtnDelete}>
                  Delete
                </Button>
                &nbsp; &nbsp;
                <Button className="Button" onClick={handleBtnSubmit}>
                  Submit
                </Button>
                </>
              ) : (
                <>
                <Button className="Button" onClick={handleBtnWithdraw}>
                  Withdraw
                </Button>
                {/* &nbsp; &nbsp;
                <Button className="Button" disabled>
                  Submit
                </Button> */}
                </>
              )}
              </>
            )}

            {leaveApplicationId && (
              <>
              {/* <Button className="Button" onClick={handleBtnAddClaimLeave}>
                Submit
              </Button> */}
              {medical_claim?.status?.description === "PENDING" && (
                <>
                <Button className="Button" onClick={handleBtnDelete}>
                  Delete
                </Button>
                &nbsp; &nbsp;
                <Button className="Button" onClick={handleBtnSubmit}>
                  Submit
                </Button>
                </>
              )}

              {medical_claim?.status?.description === "REJECTED-HR" && (
                <Button className="Button" onClick={handleBtnWithdraw} disabled>
                  Withdraw
                </Button>
              )}
              </>
            )}

            {!id && !leaveApplicationId && (
              <Button className="Button" onClick={handleBtnAdd}>
                Submit
              </Button>
            )}
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};

export default MedicalClaimForm;