import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../4.Utils/apiServices";

const namespace="leave/summaries";

//Search Leave Summaries
export const searchLeaveSummary = createAsyncThunk(
    `${namespace}/search`,
    async (body, { rejectWithValue }) => {
        const api = "search_leave_summary";
        const opt = {
            body: body,
        };
        try {
        const response = await apiServices(api, opt);
        return response.data;
        } catch (err) {
        return rejectWithValue(err);
        }
    }
);

//List of Leave Summaries
export const getListOfLeaveSummary = createAsyncThunk(
    `${namespace}/lists`,
    async (body, { rejectWithValue }) => {
        const api = "list_all_leave_summary";
        const opt = {
            body: body,
        };
        try {
        const response = await apiServices(api, opt);
        return response.data;
        } catch (err) {
        return rejectWithValue(err);
        }
    }
);

//List of a Leave Summary
export const getALeaveSummary = createAsyncThunk(
    `${namespace}/summaries/lists`,
    async (body, { rejectWithValue }) => {
      const api = "list_a_leave_summary";
      const replace = {
        search: "{leave_summaries_id}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//List of Leave Summaries + Pagination
export const getLeaveSummaryPagination = createAsyncThunk(
    `${namespace}/summaries/page`,
    async (body, { rejectWithValue }) => {
      const api = "list_leave_summary+pagination";
      const replace = {
        search: "{year}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Leave Summaries Page Count
export const getLeaveSummaryPageCount = createAsyncThunk(
    `${namespace}/page/count`,
    async (body, { rejectWithValue }) => {
        const api = "leave_summary_page_count";
        const opt = {
            body: body,
        };
        try {
        const response = await apiServices(api, opt);
        return response.data;
        } catch (err) {
        return rejectWithValue(err);
        }
    }
);

//Leave Summaries Total Count
export const getLeaveSummaryTotalCount = createAsyncThunk(
    `${namespace}/year/count`,
    async (body, { rejectWithValue }) => {
      const api = "leave_summary_total_count";
      const replace = {
        search: "{year}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//List of Leave Summaries by Employee and Year
export const getLeaveSummaryEmployeeYear = createAsyncThunk(
    `${namespace}/employee/year`,
    async ({ id, year }, { rejectWithValue }) => {
      const api = "list_leave_summary_employee_year";
      const opt = {
        replace: [
          { search: "{employee_id}", replace: id },
          { search: "{year}", replace: year },
        ],
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Print Leave Summaries (excel)
export const printExcel = createAsyncThunk(
    `${namespace}/print/excel`,
    async (body, { rejectWithValue }) => {
      const api = "print_leave_summary_excel";
      const opt = {
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Print Leave Summaries (pdf)
export const printPDF = createAsyncThunk(
    `${namespace}/print/pdf`,
    async (body, { rejectWithValue }) => {
      const api = "print_leave_summary_pdf";
      const replace = {
        search: "{leave_summary_id}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

const initialState = {
    search: {
        searchText : "",
        year: new Date().getFullYear(),
    },
    loading: false,
    error: false,
    currentPage: 1,
    currentPageCount: 0,
    summaries: [],
}

const LeaveSummarySlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        // update entity
        updateSearch(state, action) {
            state.search = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(searchLeaveSummary.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(searchLeaveSummary.fulfilled, (state, action) => {
            state.loading = false;
            state.summaries = action.payload;
            console.log(action.payload);
        })
        .addCase(searchLeaveSummary.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //get list by employee id and year
        .addCase(getLeaveSummaryEmployeeYear.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(getLeaveSummaryEmployeeYear.fulfilled, (state, action) => {
            state.loading = false;
            state.summaries = action.payload;
            console.log(action.payload);
        })
        .addCase(getLeaveSummaryEmployeeYear.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
    }
});

export const { updateSearch } = LeaveSummarySlice.actions;

export default LeaveSummarySlice.reducer;