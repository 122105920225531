import {
    Container,
    Card,
    Row,
    Col,
    Form,
    ButtonGroup,
    Button,
} from "react-bootstrap";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAttachments } from "../../3.Store/ReviewMedicalClaimsSlice";
import { ThreeDots } from 'react-loader-spinner';
import jsPDF from 'jspdf';
import { getListDetails, getReviewClaimsPagination, SUapproveLeave, SUrejectLeave } from "../../3.Store/SUReviewClaimSlice";
  
  const SUReviewClaimForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const details = useSelector(state => state.SUReviewClaimSlice.list);
    const attachment = useSelector(state => state.ReviewMedicalClaimsSlice.attachment);
    const currentPage = useSelector(state => state.SUReviewClaimSlice.currentPage);
    const [loading, setLoading] = useState(false);
    const [pdfSrc, setPdfSrc] = useState(null);

    console.log(currentPage)
  
    useEffect(() => {
      setLoading(true);
      if (id) {
        dispatch(getReviewClaimsPagination({currentPage}))
        dispatch(getAttachments({id}))
        .then(() => {
            setLoading(false);
            dispatch(getListDetails(id));
        })
        .catch(() => {
            setLoading(false);
        });
    }
    }, [id]);
  
    useEffect(() => {
      const generatePDF = () => {
        if (attachment?.filetype?.startsWith('image/')) {
          // Strip off the data URL prefix to get the base64 string
          const base64String = attachment.file.split(',')[1];
  
          // Initialize jsPDF
          const pdf = new jsPDF();
  
          // Add image to PDF
          pdf.addImage(base64String, 'PNG', 10, 10, 180, 160, '', 'BASE64'); // Adjust position and size as needed
  
          // Generate the PDF as base64
          const pdfData = pdf.output('datauristring');
          console.log(pdfData);
          // Set the PDF source to be displayed in an iframe
          setPdfSrc(pdfData);
        }
      };
  
      generatePDF();
    }, [attachment]);
  
    const handleBtnReject = () => {
      if (details.status.description === 'APPROVED-HR') {
        Swal.fire('', 'The leave request has been approved by HR.', 'info');
        return;
      }
      else if (details.status.description === 'REJECTED-HR') {
        Swal.fire('', 'The leave request has been rejected by HR.', 'info');
        return;
      }

      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          dispatch(SUrejectLeave({id}))
            .unwrap()
            .then(() => {
              setLoading(false);
              Swal.fire("Leave Rejected", "", "success", "OK")
              .then(() => {
                navigate(`/SUReviewClaimList`);
              })
            })
            .catch((err) => {
              Swal.fire("Error", err.response.data, "error", "OK");
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'The rejection has been cancelled',
              'error'
            );
        }
      });
    };
  
    const handleBtnBack = () => {
      navigate(`/SUReviewClaimList`);
    };

    const handleBtnApprove = () => {
      if (details.status.description === 'APPROVED-HR') {
        Swal.fire('', 'The leave request has been approved by HR.', 'info');
        return;
      }
      else if (details.status.description === 'REJECTED-HR') {
        Swal.fire('', 'The leave request has been rejected by HR.', 'info');
        return;
      }
  
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          dispatch(SUapproveLeave({id}))
            .unwrap()
            .then(() => {
              setLoading(false);
              Swal.fire("Leave Approved", "", "success", "OK").then(() => {
                navigate(`/SUReviewClaimList`);
              });
            })
            .catch((err) => {
              setLoading(false); // Make sure to stop loading state on error
              Swal.fire("Error", err.response.data, "error", "OK");
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', 'The approval has been cancelled', 'error');
        }
      });
    };
  
    return (
      <Container>
        {loading && (
          <ThreeDots
            visible={loading}
            height="80"
            width="80"
            color="#4fa94d"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{
              position: 'fixed',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: '9999',
            }}
          />
        )}
        <Card className="CardContent">
          <h1 className="TitleHeader">Supervisor Review Medical Claim</h1>
          <h1 className="SecondMiniTitleHeader">Update</h1>

          <Row className="my-3">
            <Col md={6} sm={6}>
              <Form.Label className="FormLabel">Staff Name</Form.Label>
            </Col>
            <Col md={6} sm={6}>
              <Form.Control value={details?.employee?.fullname} readOnly/>
            </Col>
          </Row>
  
          <Row className="my-3">
            <Col md={6} sm={6}>
              <Form.Label className="FormLabel">Invoice Date</Form.Label>
            </Col>
            <Col md={6} sm={6}>
              <Form.Control type="date" value={moment(details?.claim_date).format("YYYY-MM-DD")} readOnly/>
            </Col>
          </Row>
  
          <Row className="my-3">
            <Col md={6} sm={6}>
              <Form.Label className="FormLabel">Practitioner</Form.Label>
            </Col>
            <Col md={6} sm={6}>
              <Form.Control value={details?.receipt_no} readOnly />
            </Col>
          </Row>
  
          <Row className="my-3">
            <Col md={6} sm={6}>
              <Form.Label className="FormLabel">Claim</Form.Label>
            </Col>
            <Col md={6} sm={6}>
              <Form.Control value={details?.amount} readOnly />
            </Col>
          </Row>
  
          <Row className="my-3">
            <Col md={6} sm={6}>
              <Form.Label className="FormLabel">Receipt No</Form.Label>
            </Col>
            <Col md={6} sm={6}>
              <Form.Control value={details?.receipt_reference} readOnly />
            </Col>
          </Row>
  
          {/* Conditional rendering based on attachment existence */}
          {attachment && attachment.file && (
            <Row className="my-3">
              <Col md={12} sm={12} className="d-flex justify-content-center align-items-center">
                {/* Display image */}
                {attachment.filetype.startsWith('image/') ? (
                  // <Image src={attachment.file} alt="Uploaded file" fluid />
                  <iframe
                    src={pdfSrc}
                    title="PDF file"
                    width="100%"
                    height="500px"
                  ></iframe>
                ) : (
                  // Display PDF
                  <iframe
                    src={`data:application/pdf;base64,${attachment.file}`}
                    title="PDF file"
                    width="100%"
                    height="500px"
                  ></iframe>
                )}
              </Col>
            </Row>
          )}
  
          <div className="d-flex justify-content-between">
            <ButtonGroup className="my-2">
              <Button className="Button" onClick={handleBtnBack}>
                Back
              </Button>
            </ButtonGroup>
  
            <ButtonGroup className="my-2">
              <Button className="Button" onClick={handleBtnReject}>
                Reject
              </Button>
              &nbsp; &nbsp;
              <Button className="Button" onClick={handleBtnApprove}>
                Approve
              </Button>
            </ButtonGroup>
          </div>
        </Card>
      </Container>
    );
  };
  export default SUReviewClaimForm;  