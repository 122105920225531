import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { getLeaveSummaryEmployeeLastYear, getLeaveAwardedThisYear, getListsBeforeForfeit } from "../3.Store/LeaveAccrualYearEndSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment/moment";

export const styles = {
  container: { backgroundColor: "rgba(0, 0, 0, 0.85)" },
  footerTitle: { color: "#fff" },
  footerItem: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
    marginBottom: "30px",
  },
  footerItemLink: {
    color: "#e2e0d4",
    fontSize: "18px",
    textDecoration: "none",
  },
};

const FooterMenu = () => {
  const dispatch = useDispatch();
  const summariesLastYear = useSelector(state => state.LeaveAccrualYearEndSlice.summaries);
  const awarded = useSelector(state => state.LeaveAccrualYearEndSlice.awarded);
  const bfForfeit = useSelector(state => state.LeaveAccrualYearEndSlice.bfForfeit);
  const lastYear = moment().subtract(1, 'year').format('YYYY');
  const [excessLeave, setexcessLeave] = useState();

  const roles = JSON.parse(localStorage.getItem("roles"));
  const user = JSON.parse(localStorage.getItem("current_user"));
  const name = user?.fullname;

  useEffect(() => {
    if(name) {
      dispatch(getLeaveSummaryEmployeeLastYear({id: user.id, year: lastYear}));
      dispatch(getLeaveAwardedThisYear({id: user.id, year: moment().format('YYYY')}));
      dispatch(getListsBeforeForfeit({id: user.id, year: moment().format('YYYY')}))
        .then(() => {
        })
        .catch(() => {
        });
    };
  }, [name]);

  useEffect(() => {
    // Calculation
    const leaveEntitlement = Number(awarded?.annual);
    const leaveBalance = Number(summariesLastYear[0]?.cf);
    const leaveBalanceBefore15CNY = Number(bfForfeit[0]?.cf);

    const leaveUtilized = leaveEntitlement + leaveBalance - leaveBalanceBefore15CNY;
    const exLeave = leaveBalance - leaveUtilized - 5;

    if (exLeave <= 0) {
      setexcessLeave(0);
    }
    else {
      setexcessLeave(leaveBalance - leaveUtilized - 5);
    }

    //setexcessLeave(7);
  }, [summariesLastYear, awarded, bfForfeit])

  return (
    <Container fluid style={styles.container}>
      <Row className="pt-3" style={{ alignContent: "center" }}>
        <Col sm={6} md={2} className="mx-5">
        </Col>
        <Col sm={6} md={3} className="mx-5">
          <h2 style={styles.footerTitle}>Human Resources</h2>
          <ul style={styles.footerItem}>

          {roles?.includes('HR-L-AWARD') && (
            <li>
              <a style={styles.footerItemLink} href="/LeaveAwardList">
                Leave Awards
              </a>
            </li>
          )}

          {roles?.includes('HR-L-WITHDRAWN') && (
            <li>
              <a style={styles.footerItemLink} href="/LeaveWithdrawalList">
                Leave Withdrawals
              </a>
            </li>
          )}

          {roles?.includes('HR-L-MOVE') && (
            <li>
              <a style={styles.footerItemLink} href="/HRLeaveReportList">
                Leave Movement Records
              </a>
            </li>
          )}      

          {roles?.includes('HR-L-ACCRUAL') && (
            <li>
              <a style={styles.footerItemLink} href="/LeaveAccrualList">
                Leave Accruals
              </a>
            </li>
          )}             

          {roles?.includes('HR-L-APPROVAL') && (
            <li>
              <a style={styles.footerItemLink} href="/ReviewLeaveList">
              Review Leave Applications
              </a>
            </li>
          )}                     

          {roles?.includes('HR-M-BENEFIT') && (
            <li>
              <a style={styles.footerItemLink} href="/HRReviewMedicalList">
              Review Medical Claims
              </a>
            </li>
          )}           

          {roles?.includes('STAFF-MGT') && (
            <li>
              <a style={styles.footerItemLink} href="/EmployeeList">
                Employees
              </a>
            </li>
          )}                   

          {roles?.includes('MOD') && (
            <li>
              <a style={styles.footerItemLink} href="/UserAccessList">
                User Access
              </a>
            </li>
          )}   

          {excessLeave > 5 && (
            <li>
              <a style={styles.footerItemLink} href="/LeaveAccrualYearEndList" className="footerli">
                Leave Accrual
              </a>
              &nbsp;
              <span>
                <FontAwesomeIcon icon={faExclamationCircle} style={{color: "#f22c2c", fontSize: "20px"}} />
              </span>
            </li>
          )}

          </ul>
        </Col>
        <Col sm={6} md={3} className="mx-5">
          <h2 style={styles.footerTitle}>Hock Seng Lee Berhad</h2>
          <p style={styles.footerItemLink}>
            No 1, HSL Tower,
            <br />
            Lorong La Promenade 2, Kuching-Samarahan Expressway,
            <br />
            94300 Samarahan, Sarawak, Malaysia.
          </p>
        </Col>
      </Row>
    </Container>
  );
};
export default FooterMenu;

// const FooterMenu = () => {
//   return (
//     <Container fluid style={styles.container}>
//       <Row className="pt-3" style={{ alignContent: "center" }}>
//         <Col sm={6} md={3} className="mx-5">
//           <h2 style={styles.footerTitle}>Others</h2>
//           <ul style={styles.footerItem}>
//             <li>
//               <a style={styles.footerItemLink} href="/LeaveApplicationList">
//                 Leave Application
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/Holidays">
//                 Holidays
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/StaffInformations">
//                 Staff Informations
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/StaffOnLeave">
//                 Staff on Leave
//               </a>
//             </li>
//           </ul>

//           <h2 style={styles.footerTitle}>Report</h2>
//           <ul style={styles.footerItem}>
//             <li>
//               <a style={styles.footerItemLink} href="/LeaveReportList">
//                 Leave Report
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/LeaveSummaryList">
//                 Leave Summary
//               </a>
//             </li>
//           </ul>
//         </Col>
//         <Col sm={6} md={3} className="mx-5">
//           <h2 style={styles.footerTitle}>Human Resources</h2>
//           <ul style={styles.footerItem}>
//             <li>
//               <a style={styles.footerItemLink} href="/LeaveAwardList">
//                 Leave Awards
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/LeaveWithdrawalList">
//                 Leave Withdrawal
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/ReviewLeaveList">
//                 Review Leave
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/HRLeaveReportList">
//                 Leave Report
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/HRReviewMedicalList">
//                 Review Claim
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/LeaveAccrualList">
//                 Leave Accrual
//               </a>
//             </li>
//           </ul>

//           <h2 style={styles.footerTitle}>Supervisor</h2>
//           <ul style={styles.footerItem}>
//             <li>
//               <a style={styles.footerItemLink} href="/">
//                 Supervisor Leave Approval
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/">
//                 Supervisor Review Claim
//               </a>
//             </li>
//           </ul>

//           <h2 style={styles.footerTitle}>Setting</h2>
//           <ul style={styles.footerItem}>
//             <li>
//               <a style={styles.footerItemLink} href="/EmployeeList">
//                 Staffs
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/">
//                 User Access
//               </a>
//             </li>
//           </ul>
//         </Col>
//         <Col sm={6} md={3} className="mx-5">
//           <h2 style={styles.footerTitle}>Hock Seng Lee Berhad</h2>
//           <p style={styles.footerItemLink}>
//             No 1, HSL Tower,
//             <br />
//             Lorong La Promenade 2, Kuching-Samarahan Expressway,
//             <br />
//             94300 Samarahan, Sarawak, Malaysia.
//           </p>
//         </Col>
//       </Row>
//     </Container>
//   );
// };
// export default FooterMenu;
