import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../4.Utils/apiServices";

const namespace = "suReviewClaim";

//Get Review Claims + Pagination
export const getReviewClaimsPagination = createAsyncThunk(
    `${namespace}/medicalClaim`,
    async ({ entity, currentPage }, { rejectWithValue }) => {
        const api = "su_medical_lists";
        const replace = { 
            search: "{page}", 
            replace: currentPage 
        };
        const opt = {
            replace: replace,
            body: entity
        };
        try {
        const response = await apiServices(api, opt);
        return response.data;
        } catch (err) {
        return rejectWithValue(err);
        }
    }
);

//Get Max Count
export const getMaxCount = createAsyncThunk(
    `${namespace}/maxCount`,
    async (body, { rejectWithValue }) => {
      const api = "su_medical_lists_count";
      const opt = {
        body: body,
      };
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Approve
export const SUapproveLeave = createAsyncThunk(
  `${namespace}/approve`,
  async (body, { rejectWithValue }) => {
    const api = "su_approve_medical_claim";
    const replace = { 
      search: "{id}", 
      replace: body.id 
    };
    const opt = {
      replace: replace,
      body: body,
    };
    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Reject
export const SUrejectLeave = createAsyncThunk(
  `${namespace}/reject`,
  async (body, { rejectWithValue }) => {
    const api = "su_medical_lists_count";
    const replace = { 
      search: "{id}", 
      replace: body.id 
    };
    const opt = {
      replace: replace,
      body: body,
    };
    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
    claimlists: [],
    loading: false,
    error: false,
    currentPage: 1,
    totalPages: "",
    currentPageCount: 0,
    list: {}
};

const SUReviewClaimSlice = createSlice({
    name: namespace,
    initialState: initialState,
    reducers: {
      updateCurrentPage(state, action) {
        return { ...state, currentPage: action.payload };
      },

      getListDetails(state, action) {
        return {
            ...state,
            list: state.claimlists.find((list) => list.id == action.payload),
        };
      },
    },
    extraReducers: (builder) => {
      builder
      // Get Medical Claims by Page
      .addCase(getReviewClaimsPagination.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getReviewClaimsPagination.fulfilled, (state, action) => {
        state.loading = false;
        state.claimlists = action.payload;
      })
      .addCase(getReviewClaimsPagination.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      // Get Medical Claims by Page
      .addCase(getMaxCount.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getMaxCount.fulfilled, (state, action) => {
        state.loading = false;
        state.totalPages = action.payload;
      })
      .addCase(getMaxCount.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      // Approve
      .addCase(SUapproveLeave.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(SUapproveLeave.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(SUapproveLeave.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      // Reject
      .addCase(SUrejectLeave.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(SUrejectLeave.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(SUrejectLeave.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
    }
});

export const { updateCurrentPage, getListDetails } = SUReviewClaimSlice.actions;

export default SUReviewClaimSlice.reducer;
