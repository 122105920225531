import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../4.Utils/apiServices";
import moment from "moment";

const namespace = "Application";

export const getLeaveApplicationListByUserID = createAsyncThunk(
  `${namespace}/lists`,
  async (body, { rejectWithValue }) => {
    const api = "get_leave_application_lists";
    const replace = {
      search: "{id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getLeaveBalances = createAsyncThunk(
  `${namespace}/leave/balance`,
  async (body, { rejectWithValue }) => {
    const api = "get_leave_balance";
    const replace = {
      search: "{id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postLeaveApplication = createAsyncThunk(
  `${namespace}/add`,
  async (body, { rejectWithValue }) => {
    const api = "add_leave_application";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDaysApplying = createAsyncThunk(
  `${namespace}/days/apply`,
  async (body, { rejectWithValue }) => {
    const api = "post_days_apply";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const withdrawLeaveApplication = createAsyncThunk(
  `${namespace}/withdraw`,
  async (body, { rejectWithValue }) => {
    const api = "withdraw_leave_application";
    const replace = {
      search: "{leave_application_id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getLeaveApplicationDetails = createAsyncThunk(
  `${namespace}/details`,
  async (body, { rejectWithValue }) => {
    const api = "get_leave_application_details";
    const replace = {
      search: "{leave_application_id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//delete button
export const deleteLeaveApplication = createAsyncThunk(
  `${namespace}/delete`,
  async (body, { rejectWithValue }) => {
    const api = "delete_leave_application_details";
    const replace = {
      search: "{leave_application_id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Get Medical Cert
export const getMedicalCert = createAsyncThunk(
  `${namespace}/medicalCert`,
  async (body, { rejectWithValue }) => {
    const api = "get_medical_certificate_attachment";
    const replace = {
      search: "{id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Edit
export const editLeaveApplication = createAsyncThunk(
  `${namespace}/edit`,
  async (body, { rejectWithValue }) => {
    const api = "edit_leave_application";
    const replace = {
      search: "{leave_application_id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Add Attachment
export const addMedicalCertificate = createAsyncThunk(
  `${namespace}/add/cert`,
  async (body, { rejectWithValue }) => {
    const api = "add_medical_certificate_attachment";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Edit Attachment
export const editMedicalCertificate = createAsyncThunk(
  `${namespace}/edit/cert`,
  async (body, { rejectWithValue }) => {
    const api = "edit_medical_certificate_attachment";
    const replace = {
      search: "{medical_certificate_id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Get Special Attachment
export const getSpecialAttachment = createAsyncThunk(
  `${namespace}/special/attachment`,
  async (body, { rejectWithValue }) => {
    const api = "get_special_leave_attachment";
    const replace = {
      search: "{id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Add Special Attachment
export const addSpecialAttachment = createAsyncThunk(
  `${namespace}/add/attachment`,
  async (body, { rejectWithValue }) => {
    const api = "add_special_leave_attachment";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Edit Attachment
export const editSpecialAttachment = createAsyncThunk(
  `${namespace}/edit/attachment`,
  async (body, { rejectWithValue }) => {
    const api = "edit_special_leave_attachment";
    const replace = {
      search: "{attachment}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  application: {
    leave_type: {
      id: "A",
    },
    // // special_leave_type: {
    // //   id: "",
    // // },
    location_type: {
      id: "I",
    },
    reason_for_application: "",
    number_of_days: "",
    numberofday_type: {
      id: "1",
    },
    from_date: {
      date: moment().format("YYYY-MM-DD"), //YYYY-mm-dd
    },
    from_ampm: "AM",
    to_date: {
      date: moment().format("YYYY-MM-DD"), //YYYY-mm-dd
    },
    to_ampm: "PM",
    description: "",
  },
  applying: {
    employee: {
      id: ""
    },
    from_datetime: "",
    leave_type: {
      id: ""
    },
    to_datetime: ""
  },
  applicationLists: [],
  balance: [],
  days_apply: "",
  reason: {reason_withdrawal: ""},
  medical_claim: {},
  applicationDetails: {},
  cert: {}
};

const ApplicationSlice = createSlice({
  name: "Application",
  initialState,
  reducers: {
    updateApplication(state, action) {
      state.application = {
        ...state.application,
        ...action.payload, // Merge application substate with new values
      };
    },

    resetForm(state) {
      return {
        ...state,
        days_apply: initialState.days_apply,
        application: initialState.application,
        reason: initialState.reason,
        medical_claim: initialState.medical_claim,
        applicationDetails: initialState.applicationDetails,
        cert: initialState.cert,
      };
    },

    getLeaveDetailsByID(state, action) {
      return {
        ...state,
        applicationDetails: state.applicationLists.find((applicationDetails) => applicationDetails.id === action.payload),
      };
    },

    updateBalance(state, action) {
      return { ...state, balance: action.payload };
    },

    updateReason(state, action) {
      return { ...state, reason: action.payload };
    },

    updateApplicationDetails(state, action) {
      return { ...state, applicationDetails: action.payload };
    },

    updateCert(state, action) {
      return { ...state, cert: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder

    .addCase(getLeaveApplicationListByUserID.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getLeaveApplicationListByUserID.fulfilled, (state, action) => {
      state.loading = false;
      state.applicationLists = action.payload;
      console.log(action.payload);
    })
    .addCase(getLeaveApplicationListByUserID.rejected, (state, action) => {
      state.error = true;
      console.error(action.payload);
    })

    .addCase(getLeaveApplicationDetails.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getLeaveApplicationDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.application = action.payload;
      console.log(action.payload);
    })
    .addCase(getLeaveApplicationDetails.rejected, (state, action) => {
      state.error = true;
      console.error(action.payload);
    })

    .addCase(getLeaveBalances.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getLeaveBalances.fulfilled, (state, action) => {
      state.balance = false;
      state.balance = action.payload;
      console.log(action.payload);
    })
    .addCase(getLeaveBalances.rejected, (state, action) => {
      state.error = true;
      console.error(action.payload);
    })

    .addCase(postLeaveApplication.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(postLeaveApplication.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(postLeaveApplication.rejected, (state, action) => {
      state.error = true;
      console.error(action.payload);
    })

    .addCase(getDaysApplying.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getDaysApplying.fulfilled, (state, action) => {
      state.days_apply = false;
      state.days_apply = action.payload;
      console.log(action.payload);
    })
    .addCase(getDaysApplying.rejected, (state, action) => {
      state.error = true;
      console.error(action.payload);
    })

    .addCase(withdrawLeaveApplication.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(withdrawLeaveApplication.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(withdrawLeaveApplication.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      console.error(action.payload);
    })

    // Medical Cert
    .addCase(getMedicalCert.pending, (state) => {
      state.loading = true;
      state.error = false;
    })
    .addCase(getMedicalCert.fulfilled, (state, action) => {
        state.loading = false;
        state.cert =action.payload;
    })
    .addCase(getMedicalCert.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
    })

    //Add Medical Cert
    .addCase(addMedicalCertificate.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(addMedicalCertificate.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(addMedicalCertificate.rejected, (state, action) => {
      state.error = true;
    })

    //Edit Medical Cert
    .addCase(editMedicalCertificate.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(editMedicalCertificate.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(editMedicalCertificate.rejected, (state, action) => {
      state.error = true;
    })

    // Attachment
    .addCase(getSpecialAttachment.pending, (state) => {
      state.loading = true;
      state.error = false;
    })
    .addCase(getSpecialAttachment.fulfilled, (state, action) => {
        state.loading = false;
        state.cert =action.payload;
    })
    .addCase(getSpecialAttachment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
    })

    //Add Attachment
    .addCase(addSpecialAttachment.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(addSpecialAttachment.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(addSpecialAttachment.rejected, (state, action) => {
      state.error = true;
    })

    //Edit Attachment
    .addCase(editSpecialAttachment.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(editSpecialAttachment.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(editSpecialAttachment.rejected, (state, action) => {
      state.error = true;
    })
  },
});

export const { updateCert, updateApplication, updateBalance, resetForm, getLeaveDetailsByID, updateReason, updateApplicationDetails } = ApplicationSlice.actions;
export default ApplicationSlice.reducer;