import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../4.Utils/apiServices";

const namespace = "holidays";

export const getHolidays = createAsyncThunk(
    `${namespace}/lists`,
    async (body, { rejectWithValue }) => {
      const api = "get_holidays_all";
      const opt = {
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

export const postHoliday = createAsyncThunk(
  `${namespace}/add`,
  async (body, { rejectWithValue }) => {
    const api = "create_holiday";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const putHoliday = createAsyncThunk(
  `${namespace}/putHoliday`,
  async (body, { rejectWithValue }) => {
    const api = "put_holiday";
    const replace = {
      search: "{id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getHolidayYear = createAsyncThunk(
  `${namespace}/holiday/by/year`,
  async (body, { rejectWithValue }) => {
    const api = "getHolidayByYear";
    const replace = {
      search: "{year}",
      replace: body.year,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
    //entity
    holiday: {
      id: "",
      year: new Date().getFullYear(),
      description: "",
      is_cny: "",
      date: {
        date: new Date()
      },
      status: {
        id: "1"
      }
    },
    //entities
    holidays: [],
    loading: false,
    error: false,
};

const HolidaySlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
      // update entity
      updateHoliday(state, action) {
        return { ...state, holiday: action.payload };
      },

      // reset entity
      resetHoliday(state) {
        return {
          ...state,
          holiday: initialState.holiday,
        };
      },

      getHoliday(state, action) {
      return {
          ...state,
          holiday: state.holidays.find((holiday) => holiday.id === action.payload),
      };
      },
    },
    extraReducers: (builder) => {
        builder
          .addCase(getHolidays.pending, (state, action) => {
            state.loading = true;
          })
          .addCase(getHolidays.fulfilled, (state, action) => {
            state.loading = false;
            state.holidays = action.payload;
            console.log(action.payload);
          })
          .addCase(getHolidays.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
            console.error(action.payload);
          })

          // post
          .addCase(postHoliday.pending, (state, action) => {
            state.loading = true;
          })
          .addCase(postHoliday.fulfilled, (state, action) => {
            state.loading = false;
            state.holidays.push(action.payload);
          })
          .addCase(postHoliday.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
            console.error(action.payload);
          })

          //Put
          .addCase(putHoliday.pending, (state, action) => {
            state.loading = true;
          })
          .addCase(putHoliday.fulfilled, (state, action) => {
            state.loading = false;
          })
          .addCase(putHoliday.rejected, (state, action) => {
            state.error = true;
            console.error(action.payload);
          })

          .addCase(getHolidayYear.pending, (state, action) => {
            state.loading = true;
          })
          .addCase(getHolidayYear.fulfilled, (state, action) => {
            state.loading = false;
            state.holidays = action.payload;
            console.log(action.payload);
          })
          .addCase(getHolidayYear.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
            console.error(action.payload);
          })
    },
});

export const { getHoliday, resetHoliday, updateHoliday } = HolidaySlice.actions;
export default HolidaySlice.reducer;