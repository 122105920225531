import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../4.Utils/apiServices";
import moment from "moment/moment";

const namespace = "leave/accruals";

//List of Leave Accruals + Pagination
export const getLeaveAccrualsListsByYear = createAsyncThunk(
    `${namespace}/lists`,
    async (body, { rejectWithValue }) => {
      const api = "get_leave_accruals_lists";
      const replace = {
        search: "{year}",
        replace: body.year,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//List of a Leave Accrual
export const getLeaveAccrual = createAsyncThunk(
    `${namespace}/details`,
    async (body, { rejectWithValue }) => {
      const api = "get_a_leave_accrual_list";
      const replace = {
        search: "{leave_accrual_id}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Submit Encashment
export const submitEncashment = createAsyncThunk(
    `${namespace}/submit/encashment`,
    async (body, { rejectWithValue }) => {
      const api = "submit_leave_accrual_encashment";
      const replace = {
        search: "{leave_accrual_id}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Update Encashment
export const updateEncashmentDetail = createAsyncThunk(
    `${namespace}/update/encashment`,
    async (body, { rejectWithValue }) => {
      const api = "update_leave_accrual_encashment";
      const replace = {
        search: "{encashment_id}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Update Forfeit
export const updateForfeitDetail = createAsyncThunk(
  `${namespace}/forfeit`,
  async (body, { rejectWithValue }) => {
    const api = "update_leave_accrual_forfeit";
    const replace = {
      search: "{leave_accrual_id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Download Leave Accrual Excel
export const downloadLeaveAccrualsListsByYear = createAsyncThunk(
  `${namespace}/downoad`,
  async (body, { rejectWithValue }) => {
    const api = "print_leave_accrual_excel";
    const replace = {
      search: "{year}",
      replace: body.year,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
    encashment: {
      day_encashment: 0,
      encashment_date: moment(new Date()).format("YYYY-MM-DD"),
      status: {id:1}
    },
    forfeit: {
      accrual_date: moment(new Date()).format("YYYY-MM-DD"),
      forfeit: 0,
      status: {id:1}
    },
    loading: false,
    error: false,
    currentPage: 1,
    accrualLists: [],
    accrualDetails: null,
}

const LeaveAccrualSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        resetList(state) {
          return {
            ...state,
            encashment: initialState.encashment
          };
        },

        // update encashment
        updateEncashment(state, action) {
          return { ...state, encashment: action.payload };
        },

        // update forfeit
        updateForfeit(state, action) {
          return { ...state, forfeit: action.payload };
        },

        updateList(state, action) {
          return { ...state, accrualDetails: action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
        //lists of leave accruals
        .addCase(getLeaveAccrualsListsByYear.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(getLeaveAccrualsListsByYear.fulfilled, (state, action) => {
            state.loading = false;
            state.accrualLists = action.payload;
        })
        .addCase(getLeaveAccrualsListsByYear.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //get a leave accrual details
        .addCase(getLeaveAccrual.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(getLeaveAccrual.fulfilled, (state, action) => {
            state.loading = false;
            state.accrualDetails = action.payload;
            console.log(action.payload);
        })
        .addCase(getLeaveAccrual.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
    }
});

export const { resetList, updateEncashment, updateList } = LeaveAccrualSlice.actions;
export default LeaveAccrualSlice.reducer;