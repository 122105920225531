import { Container, Row, Col } from "react-bootstrap";

export const styles = {
  container: { backgroundColor: "rgba(0, 0, 0, 0.85)" },
  footerTitle: { color: "#fff" },
  footerItem: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
    marginBottom: "30px",
  },
  footerItemLink: {
    color: "#e2e0d4",
    fontSize: "18px",
    textDecoration: "none",
  },
};

const FooterMenu = () => {

  const roles = JSON.parse(localStorage.getItem("roles"));


  return (
    <Container fluid style={styles.container}>
      <Row className="pt-3" style={{ alignContent: "center" }}>
        <Col sm={6} md={2} className="mx-5">
        </Col>
        <Col sm={6} md={3} className="mx-5">
          <h2 style={styles.footerTitle}>Human Resources</h2>
          <ul style={styles.footerItem}>

          {roles?.includes('HR-L-AWARD') && (
            <li>
              <a style={styles.footerItemLink} href="/LeaveAwardList">
                Leave Awards
              </a>
            </li>
          )}

          {roles?.includes('HR-L-WITHDRAWN') && (
            <li>
              <a style={styles.footerItemLink} href="/LeaveWithdrawalList">
                Leave Withdrawals
              </a>
            </li>
          )}

          {roles?.includes('HR-L-MOVE') && (
            <li>
              <a style={styles.footerItemLink} href="/HRLeaveReportList">
                Leave Movement Records
              </a>
            </li>
          )}      

          {roles?.includes('HR-L-ACCRUAL') && (
            <li>
              <a style={styles.footerItemLink} href="/LeaveAccrualList">
                Leave Accruals
              </a>
            </li>
          )}             

          {roles?.includes('HR-L-APPROVAL') && (
            <li>
              <a style={styles.footerItemLink} href="/ReviewLeaveList">
              Review Leave Applications
              </a>
            </li>
          )}                     

          {roles?.includes('HR-M-BENEFIT') && (
            <li>
              <a style={styles.footerItemLink} href="/HRReviewMedicalList">
              Review Medical Claims
              </a>
            </li>
          )}           

          {roles?.includes('STAFF-MGT') && (
            <li>
              <a style={styles.footerItemLink} href="/EmployeeList">
                Employees
              </a>
            </li>
          )}                   

          {roles?.includes('MOD') && (
            <li>
              <a style={styles.footerItemLink} href="/UserAccessList">
                User Access
              </a>
            </li>
          )}    

          </ul>
        </Col>
        <Col sm={6} md={3} className="mx-5">
          <h2 style={styles.footerTitle}>Hock Seng Lee Berhad</h2>
          <p style={styles.footerItemLink}>
            No 1, HSL Tower,
            <br />
            Lorong La Promenade 2, Kuching-Samarahan Expressway,
            <br />
            94300 Samarahan, Sarawak, Malaysia.
          </p>
        </Col>
      </Row>
    </Container>
  );
};
export default FooterMenu;

// const FooterMenu = () => {
//   return (
//     <Container fluid style={styles.container}>
//       <Row className="pt-3" style={{ alignContent: "center" }}>
//         <Col sm={6} md={3} className="mx-5">
//           <h2 style={styles.footerTitle}>Others</h2>
//           <ul style={styles.footerItem}>
//             <li>
//               <a style={styles.footerItemLink} href="/LeaveApplicationList">
//                 Leave Application
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/Holidays">
//                 Holidays
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/StaffInformations">
//                 Staff Informations
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/StaffOnLeave">
//                 Staff on Leave
//               </a>
//             </li>
//           </ul>

//           <h2 style={styles.footerTitle}>Report</h2>
//           <ul style={styles.footerItem}>
//             <li>
//               <a style={styles.footerItemLink} href="/LeaveReportList">
//                 Leave Report
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/LeaveSummaryList">
//                 Leave Summary
//               </a>
//             </li>
//           </ul>
//         </Col>
//         <Col sm={6} md={3} className="mx-5">
//           <h2 style={styles.footerTitle}>Human Resources</h2>
//           <ul style={styles.footerItem}>
//             <li>
//               <a style={styles.footerItemLink} href="/LeaveAwardList">
//                 Leave Awards
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/LeaveWithdrawalList">
//                 Leave Withdrawal
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/ReviewLeaveList">
//                 Review Leave
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/HRLeaveReportList">
//                 Leave Report
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/HRReviewMedicalList">
//                 Review Claim
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/LeaveAccrualList">
//                 Leave Accrual
//               </a>
//             </li>
//           </ul>

//           <h2 style={styles.footerTitle}>Supervisor</h2>
//           <ul style={styles.footerItem}>
//             <li>
//               <a style={styles.footerItemLink} href="/">
//                 Supervisor Leave Approval
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/">
//                 Supervisor Review Claim
//               </a>
//             </li>
//           </ul>

//           <h2 style={styles.footerTitle}>Setting</h2>
//           <ul style={styles.footerItem}>
//             <li>
//               <a style={styles.footerItemLink} href="/EmployeeList">
//                 Staffs
//               </a>
//             </li>
//             <li>
//               <a style={styles.footerItemLink} href="/">
//                 User Access
//               </a>
//             </li>
//           </ul>
//         </Col>
//         <Col sm={6} md={3} className="mx-5">
//           <h2 style={styles.footerTitle}>Hock Seng Lee Berhad</h2>
//           <p style={styles.footerItemLink}>
//             No 1, HSL Tower,
//             <br />
//             Lorong La Promenade 2, Kuching-Samarahan Expressway,
//             <br />
//             94300 Samarahan, Sarawak, Malaysia.
//           </p>
//         </Col>
//       </Row>
//     </Container>
//   );
// };
// export default FooterMenu;
