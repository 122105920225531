import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getLeaveApplicationDetails } from "../../3.Store/LeaveMovementSlice";
import moment from "moment/moment";
import { ThreeDots } from 'react-loader-spinner';

const HRLeaveReportView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useParams();
  const [loading, setLoading] = useState(false);
  const details = useSelector((state) => state.LeaveMovementSlice.details);

  console.log(details);

  useEffect(() => {
    setLoading(true);
    if(id) {
      dispatch(getLeaveApplicationDetails(id)).then(() => {
        setLoading(false);
      })
    }
  }, [id]);

  const handleBtnBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">View Leave Application</h1>

        <Row className="my-3">
          <Col md={8} sm={8}>
            <Form.Label className="FormLabel">Leave Type</Form.Label>
          </Col>
          <Col md={4} sm={4}>
            <Form.Control value={details?.leave_type?.description} readOnly/>
          </Col>
        </Row>

        {/* Special Leave */}
        {details?.leave_type?.id == "SP" && (
          <>
            <Row className="my-2">
              <Col md={8} sm={8}>
                <Form.Label className="FormLabel">
                  Special Leave Type
                </Form.Label>
              </Col>
              <Col md={4} sm={4}>
                <Form.Control value={details?.special_leave_type?.description} readOnly />
              </Col>
            </Row>
          </>
        )}

        <Row className="my-2">
          <Col md={8} sm={8}>
            <Form.Label className="FormLabel">No. of Days</Form.Label>
          </Col>
          <Col md={4} sm={4}>
            <Form.Control value={details?.numberofday_type?.description} readOnly />
          </Col>
        </Row>

        {details?.numberofday_type?.id !== "1" ? (
          <Row className="my-2">
            <Col md={6} sm={6}>
              <Form.Label className="FormLabel">Starting From</Form.Label>
            </Col>
            <Col md={2} sm={6}>
              <Form.Check 
                type="radio"
                label="AM" 
                name="from_ampm"
                value="AM"
                checked={details?.from_ampm == "AM"} 
                style={{ color: "#fff" }} 
              />
              <Form.Check 
                type="radio"
                label="PM" 
                value="PM"
                name="from_ampm"
                checked={details?.from_ampm == "PM"} 
                style={{ color: "#fff" }} 
              />
            </Col>
            <Col md={4} sm={4}>
              <Form.Control 
                value={moment(details?.from_date?.date).format("YYYY-MM-DD")}
                readOnly
              />
            </Col>
          </Row>
        ) : (
          <Row className="my-2">
            <Col md={8} sm={8}>
              <Form.Label className="FormLabel">On</Form.Label>
            </Col>
            <Col md={4} sm={4}>
              <Form.Control 
                value={moment(details?.from_date?.date).format("YYYY-MM-DD")}
                readOnly
              />
            </Col>
          </Row>
        )}

        {details?.numberofday_type?.id == "M" && (
          <Row className="my-2">
            <Col md={6} sm={6}>
              <Form.Label className="FormLabel">To</Form.Label>
            </Col>
            <Col md={2} sm={6}>
              <Form.Check 
                type="radio"
                label="AM" 
                checked={details?.to_ampm == "AM"} 
                style={{ color: "#fff" }} 
                name="to_ampm"
                value="AM"
              />
              <Form.Check 
                type="radio"
                label="PM" 
                checked={details?.to_ampm == "PM"} 
                style={{ color: "#fff" }} 
                name="to_ampm"
                value="PM"
              />
            </Col>
            <Col md={4} sm={4}>
              <Form.Control 
                value={moment(details?.to_date?.date).format("YYYY-MM-DD")}
                readOnly
              />
            </Col>
          </Row>
        )}

        <Row className="my-2">
          <Col md={8} sm={8}>
            <Form.Label className="FormLabel">Location</Form.Label>
          </Col>
          <Col md={4} sm={4}>
            <Form.Control value={details?.location_type?.description} readOnly/>
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={8} sm={8}>
            <Form.Label className="FormLabel">No. Days Applying</Form.Label>
          </Col>
          <Col md={4} sm={4}>
            <Form.Control value={details?.number_of_days} readOnly/>
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={12} sm={12}>
            <Form.Label className="FormLabel">Reason</Form.Label>
            <Form.Control as="textarea" value={details?.reason_for_application} readOnly/>
          </Col>
        </Row>

        <ButtonGroup className="my-2" style={{ marginRight: "auto" }}>
          <Button className="Button" onClick={handleBtnBack}>
            Back
          </Button>
        </ButtonGroup>
      </Card>
    </Container>
  );
};
export default HRLeaveReportView;