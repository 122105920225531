import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import Swal from "sweetalert2";
import Select from "react-select";
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  ButtonGroup,
  Button,
  Image
} from "react-bootstrap";
import {
  getMedicalCert,
  updateList,
  getSpecialLeaveAttachment,
} from "../../3.Store/ReviewLeaveApplicationSlice";
import { editLeaveApplication, getDaysApplying } from "../../3.Store/ApplicationSlice";
import { LeaveType, LeaveNumberDay, Location, SpecialLeaveTypeOption } from "../../2.Commons/DropdownOption";
import { ThreeDots } from 'react-loader-spinner';
import { getLeaveDetails, ApproveLeave, RejectLeave } from "../../3.Store/LeaveApprovalSlice";

const LeaveApprovalForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const details = useSelector(state => state.LeaveApprovalSlice.leaveDetails);
  const cert = useSelector(state => state.ReviewLeaveApplicationSlice.cert);
  const special_attachment = useSelector(state => state.ReviewLeaveApplicationSlice.special_attachment);
  const lists = useSelector(state => state.LeaveApprovalSlice.approvalLists);
  const [loading, setLoading] = useState(false);
  const { applying } = useSelector(
    (state) => state.ApplicationSlice
  );

  console.log(lists);

  useEffect(() => {
    const fetchLeaveDetails = async () => {
      if (id) {
        setLoading(true);
        try {
          await dispatch(getLeaveDetails(id));
        } catch (error) {
          console.error("Failed to fetch leave details:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchLeaveDetails();
  }, [id]);

  useEffect(() => {
      const appendTime = (date, ampm) => {
        return ampm === "AM" ? `${date} 08:00:00` : `${date} 13:00:00`;
      };
    
      const handleMCase = async () => {
        const fromDateString = appendTime(moment(details?.from_date.date).format("YYYY-MM-DD"), details?.from_ampm);
        const toDateString = appendTime(moment(details?.to_date.date).format("YYYY-MM-DD"), details?.to_ampm);
  
        console.log(fromDateString);
        console.log(details?.leave_type.id);
  
        const entity = {
          ...applying,
          employee: { id: details.employee.id },
          from_datetime: fromDateString,
          leave_type: { id: details?.leave_type.id },
          to_datetime: toDateString
        };
  
        console.log(entity);
      
          // Await the dispatch call to get the updated days applying
          try {
            const updatedDaysApply = await dispatch(getDaysApplying(entity));
            console.log(updatedDaysApply);
      
            // Update the application with the correct number of days
            const days_applying = { ...details, number_of_days: updatedDaysApply.payload };
            await dispatch(updateList(days_applying));
          } catch (error) {
            console.error('Error updating days applying:', error);
          }
      };
    
      if (!details?.numberofday_type || !details?.from_date || !details?.to_date) {
        return;
      }
    
      if (details?.numberofday_type.id === "H") {
        const updatedNumberOfDays = "0.5";
        const updatedToDate = { date: details.from_date.date };
        const updatedToAMPM = details.from_ampm;
    
        if (
          details.number_of_days !== updatedNumberOfDays ||
          details.to_date.date !== updatedToDate.date ||
          details.to_ampm !== updatedToAMPM
        ) {
          const entity = {
            ...details,
            number_of_days: updatedNumberOfDays,
            to_date: updatedToDate,
            to_ampm: updatedToAMPM
          };
          console.log(entity);
          dispatch(updateList(entity));
        }
      } else if (details.numberofday_type.id === "1") {
        const updatedNumberOfDays = "1.0";
        const updatedFromAMPM = "AM";
        const updatedToAMPM = "PM";
        const updatedToDate = { date: details.from_date.date };
    
        if (
          details.number_of_days !== updatedNumberOfDays ||
          details.from_ampm !== updatedFromAMPM ||
          details.to_ampm !== updatedToAMPM
        ) {
          const entity = {
            ...details,
            number_of_days: updatedNumberOfDays,
            from_ampm: updatedFromAMPM,
            to_ampm: updatedToAMPM,
            to_date: updatedToDate
          };
          console.log(entity);
          dispatch(updateList(entity));
        }
      } else if (details.numberofday_type.id === "M") {
        handleMCase();
      }
    }, [details?.numberofday_type,details?.from_date,details?.to_date,details?.from_ampm,details?.to_ampm]);

  useEffect(() => {
    // Function to fetch data based on leave type
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true when useEffect starts

        if (details.leave_type.id === "SI") {
          await dispatch(getMedicalCert({ id }));
        } else if (details?.leave_type?.id === "SP") {
          await dispatch(getSpecialLeaveAttachment({ id }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after dispatch completes (success or failure)
      }
    };
    fetchData();
  }, [details?.leave_type?.id]);

  const handleBtnBack = () => {
    navigate(-1);
  };

  const handleBtnReject = () => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        
        const entity = { ...details, to_date: { date: details.to_date.date } };
        
        dispatch(editLeaveApplication(entity))
          .unwrap()
          .then(() => {
            return dispatch(RejectLeave([...details])).unwrap();
          })
          .then(() => {
            Swal.fire("Leave Application Rejected", "", "success", "OK").then(() => {
              navigate(`/LeaveApprovalList`);
            })
          })
          .catch((err) => {
            Swal.fire("Error", err.response?.data || 'An error occurred', "error", "OK");
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Rejection has been cancelled',
          'error'
        );
      }
    });
  };  

  const handleBtnApprove = () => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        
        const entity = { ...details, to_date: { date: details.to_date.date } };
        
        dispatch(editLeaveApplication(entity))
          .unwrap()
          .then(() => {
            return dispatch(ApproveLeave([...details])).unwrap();
          })
          .then(() => {
            Swal.fire("Leave Application Approved", "", "success", "OK").then(() => {
              navigate(`/LeaveApprovalList`);
            })
          })
          .catch((err) => {
            Swal.fire("Error", err.response?.data || 'An error occurred', "error", "OK");
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Approval has been cancelled',
          'error'
        );
      }
    });
  };  

  const handleRemarkChange = (e) => {
    const entity = { ...details, remark: e.target.value };
    console.log(entity);
    dispatch(updateList(entity));
  };

  const handleChangeDropdownLeaveType = (value) => {
    console.log(value);
    const entity = {
      ...details,
      leave_type: {
        id: value.id,
      },
    };
    dispatch(updateList(entity));
    console.log(entity);
  };

  const handleChangeDropdownSpecialLeaveType = (value) => {
    console.log(value);
    const entity = {
      ...details,
      special_leave_type: {
        id: value.id,
      },
    };
    console.log(entity);
    dispatch(updateList(entity));
  };

  const handleChangeDropdownNumberOfDay = (value) => {
    console.log(value);
    const entity = {
      ...details,
      numberofday_type: {
        id: value.id,
      },
    };
    dispatch(updateList(entity));
    console.log(entity);
  };

  const handleChangeDropdownLocation = (value) => {
    console.log(value);
    const entity = {
      ...details,
      location_type: {
        id: value.id,
      },
    };
    dispatch(updateList(entity));
    console.log(entity);
  };

  const handleCheckForm = (e) => {
    const entity = { ...details, [e.target.name]: e.target.value };
    console.log(entity);
    dispatch(updateList(entity));
  };

  const handleChangeFromDate = (e) => {
    const entity = { ...details, from_date: { date: e.target.value } };
    console.log(entity);
    dispatch(updateList(entity));
  };

  const handleChangeToDate = (e) => {
    const entity = { ...details, to_date: { date: e.target.value } };
    console.log(entity);
    dispatch(updateList(entity));
  };

  // Format the max date from details
  const maxFromDate = moment(details?.from_datetime?.date).format("YYYY-MM-DD");
  const maxToDate = moment(details?.to_datetime?.date).format("YYYY-MM-DD");

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Leave Approval</h1>
        <h1 className="MiniTitleHeader">View</h1>

        <Row className="my-3">
          <Col md={6} sm={6}>
            <Form.Label className="FormLabel">Staff Name</Form.Label>
          </Col>
          <Col md={6} sm={6}>
            <Form.Control value={details?.employee?.fullname} readOnly />
          </Col>
        </Row>

        <Row className="my-3">
          <Col md={8} sm={8}>
            <Form.Label className="FormLabel">Leave Type</Form.Label>
          </Col>
          <Col md={4} sm={4}>
            <Select
              name="leave_type"
              options={LeaveType}
              value={LeaveType.find(
                (option) => option.id === details?.leave_type?.id
              )}
              onChange={handleChangeDropdownLeaveType}
            />
          </Col>
        </Row>

        {details?.leave_type?.id === "SI" && cert && cert.file && (
          <Row className="my-3">
            <Col md={12} sm={12} className="d-flex justify-content-center align-items-center">
              {/* Display image */}
              {cert.filetype.startsWith('image/') ? (
                <Image src={cert.file} alt="Uploaded file" fluid />
              ) : (
                // Display PDF
                <iframe
                  src={`data:${cert.filetype};base64,${cert.file}`}
                  title="PDF file"
                  width="100%"
                  height="500px"
                ></iframe>
              )}
            </Col>
          </Row>
        )}

        {/* Special Leave */}
        {details?.leave_type?.id == "SP" && (
          <>
            <Row className="my-2">
              <Col md={8} sm={8}>
                <Form.Label className="FormLabel">
                  Special Leave Type
                </Form.Label>
              </Col>
              <Col md={4} sm={4}>
                <Select
                  name="special_leave_type"
                  options={SpecialLeaveTypeOption}
                  value={SpecialLeaveTypeOption.find(
                    (option) => option.id === details?.special_leave_type?.id
                  )}
                  onChange={handleChangeDropdownSpecialLeaveType}
                />
              </Col>
            </Row>
          </>
        )}

        {details?.leave_type?.id === "SP" && special_attachment && special_attachment.file && (
          <Row className="my-3">
            <Col md={12} sm={12} className="d-flex justify-content-center align-items-center">
              {/* Display image */}
              {special_attachment.filetype.startsWith('image/') ? (
                <Image src={special_attachment.file} alt="Uploaded file" fluid />
              ) : (
                // Display PDF
                <iframe
                  src={`data:${special_attachment.filetype};base64,${special_attachment.file}`}
                  title="PDF file"
                  width="100%"
                  height="500px"
                ></iframe>
              )}
            </Col>
          </Row>
        )}

        <Row className="my-2">
          <Col md={8} sm={8}>
            <Form.Label className="FormLabel">No. of Days</Form.Label>
          </Col>
          <Col md={4} sm={4}>
            <Select
              options={LeaveNumberDay}
              value={LeaveNumberDay.find(
                (option) => option.id === details?.numberofday_type?.id
              )}
              onChange={handleChangeDropdownNumberOfDay}
            />
          </Col>
        </Row>

        {details?.numberofday_type?.id !== "1" ? (
          <Row className="my-2">
            <Col md={6} sm={6}>
              <Form.Label className="FormLabel">Starting From</Form.Label>
            </Col>
            <Col md={2} sm={6}>
              <Form.Check 
                type="radio"
                label="AM" 
                name="from_ampm"
                value="AM"
                checked={details?.from_ampm == "AM"} 
                style={{ color: "#fff" }} 
                onChange={handleCheckForm}
              />
              <Form.Check 
                type="radio"
                label="PM" 
                value="PM"
                name="from_ampm"
                checked={details?.from_ampm == "PM"} 
                style={{ color: "#fff" }} 
                onChange={handleCheckForm}
              />
            </Col>
            <Col md={4} sm={4}>
              <Form.Control 
                type="date" 
                value={moment(details?.from_date?.date).format("YYYY-MM-DD")} 
                onChange={handleChangeFromDate}
                min={maxFromDate}
                max={maxToDate}
              />
            </Col>
          </Row>
        ) : (
          <Row className="my-2">
            <Col md={8} sm={8}>
              <Form.Label className="FormLabel">On</Form.Label>
            </Col>
            <Col md={4} sm={4}>
              <Form.Control 
                type="date" 
                value={moment(details?.from_date?.date).format("YYYY-MM-DD")} 
                onChange={handleChangeFromDate}
                max={maxToDate}
              />
            </Col>
          </Row>
        )}

        {details?.numberofday_type?.id == "M" && (
          <Row className="my-2">
            <Col md={6} sm={6}>
              <Form.Label className="FormLabel">To</Form.Label>
            </Col>
            <Col md={2} sm={6}>
              <Form.Check 
                type="radio"
                label="AM" 
                checked={details?.to_ampm == "AM"} 
                style={{ color: "#fff" }} 
                name="to_ampm"
                value="AM"
                onChange={handleCheckForm}
              />
              <Form.Check 
                type="radio"
                label="PM" 
                checked={details?.to_ampm == "PM"} 
                style={{ color: "#fff" }} 
                name="to_ampm"
                value="PM"
                onChange={handleCheckForm}
              />
            </Col>
            <Col md={4} sm={4}>
              <Form.Control 
                type="date" 
                value={moment(details?.to_date?.date).format("YYYY-MM-DD")} 
                onChange={handleChangeToDate}
                min={maxFromDate}
                max={maxToDate}
              />
            </Col>
          </Row>
        )}

        <Row className="my-2">
          <Col md={8} sm={8}>
            <Form.Label className="FormLabel">No. Days Applying</Form.Label>
          </Col>
          <Col md={4} sm={4}>
            <Form.Control value={details?.number_of_days} readOnly/>
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={8} sm={8}>
            <Form.Label className="FormLabel">Location</Form.Label>
          </Col>
          <Col md={4} sm={4}>
            <Select
              options={Location}
              value={Location.find(
                (option) => option.id === details?.location_type?.id
              )}
              onChange={handleChangeDropdownLocation}
            />
          </Col>
        </Row>
        
        <Row className="my-2">
          <Col md={12} sm={12}>
            <Form.Label className="FormLabel">Reason</Form.Label>
            <Form.Control as="textarea" value={details?.reason_for_application} readOnly/>
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={12} sm={12}>
            <Form.Label className="FormLabel">Description</Form.Label>
            <Form.Control as="textarea" value={details?.description} readOnly />
          </Col>
        </Row>

        <Row className="my-2">
          <Col md={12} sm={12}>
            <Form.Label className="FormLabel">Remark</Form.Label>
            <Form.Control as="textarea" value={details?.remark} onChange={handleRemarkChange}/>
          </Col>
        </Row>

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>

          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnReject}>
              Reject
            </Button>
            &nbsp; &nbsp;
            <Button className="Button" onClick={handleBtnApprove}>
              Approve
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};
export default LeaveApprovalForm;
