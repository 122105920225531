// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ellipsis {
    max-width: 100px; /* Set your desired width */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.custom-tooltip .tooltip-inner {
    background-color: black !important;
    color: white !important; /* Optional: Change text color */
    border-radius: 5px; /* Optional: Customize border radius */
    padding: 5px; /* Optional: Customize padding */
}
  `, "",{"version":3,"sources":["webpack://./src/1.Components/HRLeaveReport/HRLeaveReport.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB,EAAE,2BAA2B;IAC7C,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kCAAkC;IAClC,uBAAuB,EAAE,gCAAgC;IACzD,kBAAkB,EAAE,sCAAsC;IAC1D,YAAY,EAAE,gCAAgC;AAClD","sourcesContent":[".ellipsis {\r\n    max-width: 100px; /* Set your desired width */\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n    white-space: nowrap;\r\n}\r\n\r\n.custom-tooltip .tooltip-inner {\r\n    background-color: black !important;\r\n    color: white !important; /* Optional: Change text color */\r\n    border-radius: 5px; /* Optional: Customize border radius */\r\n    padding: 5px; /* Optional: Customize padding */\r\n}\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
