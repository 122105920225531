import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiServices } from "../4.Utils/apiServices";

const namespace = "employees";

//Get All Employees Name
export const getEmployeesChronically = createAsyncThunk(
    `${namespace}/employees/lists/chronically`,
    async (body, { rejectWithValue }) => {
      const api = "get_employees_lists_chronically";
      const opt = {
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//search employee
export const postsearchText = createAsyncThunk(
    `${namespace}/search`,
    async (body, { rejectWithValue }) => {
        const api = "search_employees_active";
        const opt = {
            body: body,
        };
    
        try {
            const response = await apiServices(api, opt);
            //return response.data;
            const responseData = response.data;

            // Filter the records based on specific criteria (e.g., active status)
            const filteredRecords = responseData.filter(record =>
                body.active === 1 ? record.status.description === 'Active' : true
            );

            return filteredRecords;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

//get photo
export const getEmployeePhoto = createAsyncThunk(
    `${namespace}/photo`,
    async (body, { rejectWithValue }) => {
      const api = "get_employee_image";
      const replace = {
        search: "{employee_id}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

export const getEmployeeEdit = createAsyncThunk(
    `${namespace}/employee/detail`,
    async (body, { rejectWithValue }) => {
      const api = "get_employee_list_edit";
      const replace = {
        search: "{employee_id}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//company lists
export const getCompanyLists = createAsyncThunk(
    `${namespace}/company/lists`,
    async (body, { rejectWithValue }) => {
      const api = "get_company_lists";
      const opt = {
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Get All Employees Name
export const getEmployees = createAsyncThunk(
    `${namespace}/employee`,
    async (body, { rejectWithValue }) => {
      const api = "get_employees_lists";
      const opt = {
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//dismiss
export const dismissUser = createAsyncThunk(
    `${namespace}/dismiss/user`,
    async (body, { rejectWithValue }) => {
      const api = "dismiss_user";
      const replace = {
        search: "{id}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

export const dismissEmployee = createAsyncThunk(
    `${namespace}/dismiss/employee`,
    async (body, { rejectWithValue }) => {
      const api = "dismiss_employee";
      const replace = {
        search: "{id}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//List of Leave Summaries by Employee and Year
export const getLeaveSummaryEmployeeYear = createAsyncThunk(
  `${namespace}/employee/year`,
  async ({ id, year }, { rejectWithValue }) => {
    const api = "list_leave_summary_employee_year";
    const opt = {
      replace: [
        { search: "{employee_id}", replace: id },
        { search: "{year}", replace: year },
      ],
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//List of Sick Leave Summaries by Employee and Year
export const getSickleaveSummaryEmployeeYear = createAsyncThunk(
  `${namespace}/sickleave/employee/year`,
  async ({ id, year }, { rejectWithValue }) => {
    const api = "list_sickleave_summary_employee_year";
    const opt = {
      replace: [
        { search: "{employee_id}", replace: id },
        { search: "{year}", replace: year },
      ],
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//List of Sick Leave Summaries by Employee and Year
export const getMedicalbenefitLeaveSummaryEmployeeYear = createAsyncThunk(
  `${namespace}/medicalbenefit/employee/year`,
  async ({ id, year }, { rejectWithValue }) => {
    const api = "list_medicalbenefit_summary_employee_year";
    const opt = {
      replace: [
        { search: "{employee_id}", replace: id },
        { search: "{year}", replace: year },
      ],
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//add username
export const addUsername = createAsyncThunk(
  `${namespace}/add/username`,
  async (body, { rejectWithValue }) => {
    const api = "add_username";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//add user
export const addUser = createAsyncThunk(
  `${namespace}/add/user`,
  async (body, { rejectWithValue }) => {
    const api = "add_user";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//add user
export const addEmployee = createAsyncThunk(
  `${namespace}/add/employee`,
  async (body, { rejectWithValue }) => {
    const api = "add_employee";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//add leaveprorated
export const addLeaveProrated = createAsyncThunk(
  `${namespace}/add/leaveprorated`,
  async (body, { rejectWithValue }) => {
    const api = "add_leaveprorated";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//employee photo
export const uploadEmployeePhoto = createAsyncThunk(
  `${namespace}/employee/photo`,
  async (body, { rejectWithValue }) => {
    const api = "add_employeePhoto";
    const replace = {
      search: "{id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//employee photo
export const replaceEmployeePhoto = createAsyncThunk(
  `${namespace}/employee/photo/replace`,
  async (body, { rejectWithValue }) => {
    const api = "replace_employeePhoto";
    const replace = {
      search: "{id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//resign
export const resignEmployee = createAsyncThunk(
  `${namespace}/employee/resign`,
  async (body, { rejectWithValue }) => {
    const api = "resign_employee";
    const replace = {
      search: "{employee_id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//get a employee
export const getAEmployee = createAsyncThunk(
  `${namespace}/employee/details`,
  async (body, { rejectWithValue }) => {
    const api = "get_a_employee";
    const replace = {
      search: "{employee_id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//print employee
export const printEmployee = createAsyncThunk(
  `${namespace}/employee/print`,
  async (body, { rejectWithValue }) => {
    const api = "print_employee";
    const replace = {
      search: "{employee_id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
    search: {
        searchText : "",
        active: 1, //1=action 0=unactive
    },
    employeeDetail: {
      id: "",
      fullname: "",
      username: "",
      sid: "",
      gender: "M",
      date_joined: new Date(),
      date_left: "",
      email: "",
      company: {
          id: "4140add6-22b3-1324-05bc-9000ec3039e2"
      },
      working_schedule: {
          id: "S"
      },
      employee_category: {
          id: "S"
      },
      service_length: {
          id: "P"
      },    
      supervisor: {
          id: ""
      },
      status: {
          id: "0"
      }
    },
    user: {
      id: "",
      authentication_type: 3,
      createdby: {},
      fullname: "",
      password: "",
      sid: "",
      status: {id: 0},
      temp_password: null,
      username: "",
    },
    leaveProrated: {
      current_leave_balance: 0,
      deduction_required: 0,
      employee: {
        id: ""
      },
      leave_awarded_this_year: 0,
      leave_bf: 0,
      leave_taken_this_year: 0,
      medicalbenefit_awarded_for_this_year: 0,
      new_leave_balance: 0,
      prorate_date: new Date(),
      prorate_type: 1,
      prorated_leave_awarded: 0,
      prorated_medicalbenefit_awarded: 0,
      prorated_pct_final_year: 0,
      prorated_pct_first_year: 0,
      prorated_sickleave_awarded: 0,
      sickleave_awarded_for_this_year: 0,
      status: { id: 1 },
      year: new Date().getFullYear()
    },
    loading: false,
    error: false,
    currentPage: 1,
    currentPageCount: 0,
    employees: [],
    photo: null,
    company: [],
    supervisor: [],
    summaries: [],
    sickLeaveSummaries: [],
    medicalBenefitSummaries: []
}

const EmployeeSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        // update entity
        updateSearch(state, action) {
            state.search = action.payload;
        },

        updatePhoto(state, action) {
          state.photo = action.payload;
        },

        updateForm(state, action) {
            state.employeeDetail = action.payload;
        },

        updateUser(state, action) {
          state.user = action.payload;
        },

        updateProrated(state, action) {
          state.leaveProrated = action.payload;
        },

        resetForm(state) {
          return {
            ...state,
            employeeDetail: initialState.employeeDetail,
            photo: initialState.photo,
            user: initialState.user,
          };
        },

        resetProrated(state) {
          return {
            ...state,
            leaveProrated: initialState.leaveProrated,
          };
        },
    },
    extraReducers: (builder) => {
        builder
        //Get Employees
        .addCase(getEmployeesChronically.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(getEmployeesChronically.fulfilled, (state, action) => {
            state.loading = false; // Set loading to false
            state.employees = action.payload;
        })
        .addCase(getEmployeesChronically.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
            console.error(action.payload);
        })

        //get a employee
        .addCase(getAEmployee.pending, (state, action) => {
          state.loading = true;
        })
        .addCase(getAEmployee.fulfilled, (state, action) => {
            state.loading = false; // Set loading to false
            state.employeeDetail = action.payload;
        })
        .addCase(getAEmployee.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
            console.error(action.payload);
        })

        //Search
        .addCase(postsearchText.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(postsearchText.fulfilled, (state, action) => {
            state.loading = false; // Set loading to false
            state.employees = action.payload;
        })
        .addCase(postsearchText.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
            console.error(action.payload);
        })

        .addCase(getEmployeePhoto.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(getEmployeePhoto.fulfilled, (state, action) => {
            state.loading = false;
            state.photo = action.payload;
            console.log(action.payload);
        })
        .addCase(getEmployeePhoto.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        .addCase(getEmployeeEdit.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(getEmployeeEdit.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            console.log(action.payload);
        })
        .addCase(getEmployeeEdit.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //Get Employees for supervisor column
        .addCase(getEmployees.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(getEmployees.fulfilled, (state, action) => {
            state.loading = false; // Set loading to false
            state.supervisor = action.payload;
        })
        .addCase(getEmployees.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
            console.error(action.payload);
        })

        //get company lists
        .addCase(getCompanyLists.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(getCompanyLists.fulfilled, (state, action) => {
            state.loading = false;
            state.company = action.payload;
            console.log(action.payload);
        })
        .addCase(getCompanyLists.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //dismiss
        .addCase(dismissUser.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(dismissUser.fulfilled, (state, action) => {
            state.loading = false;
        })
        .addCase(dismissUser.rejected, (state, action) => {
            state.error = true;
            console.error(action.payload);
        })

        .addCase(dismissEmployee.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(dismissEmployee.fulfilled, (state, action) => {
            state.loading = false;
        })
        .addCase(dismissEmployee.rejected, (state, action) => {
            state.error = true;
            console.error(action.payload);
        })

        //get list by employee id and year
        .addCase(getLeaveSummaryEmployeeYear.pending, (state) => {
          state.loading = true;
          state.error = false;
        })
        .addCase(getLeaveSummaryEmployeeYear.fulfilled, (state, action) => {
            state.loading = false;
            state.summaries = action.payload;
            console.log(action.payload);
        })
        .addCase(getLeaveSummaryEmployeeYear.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //get sickleave summaries by employee id and year
        .addCase(getSickleaveSummaryEmployeeYear.pending, (state) => {
          state.loading = true;
          state.error = false;
        })
        .addCase(getSickleaveSummaryEmployeeYear.fulfilled, (state, action) => {
            state.loading = false;
            state.sickLeaveSummaries = action.payload;
            console.log(action.payload);
        })
        .addCase(getSickleaveSummaryEmployeeYear.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        
        //get medicalbenefit by employee id and year
        .addCase(getMedicalbenefitLeaveSummaryEmployeeYear.pending, (state) => {
          state.loading = true;
          state.error = false;
        })
        .addCase(getMedicalbenefitLeaveSummaryEmployeeYear.fulfilled, (state, action) => {
            state.loading = false;
            state.medicalBenefitSummaries = action.payload;
            console.log(action.payload);
        })
        .addCase(getMedicalbenefitLeaveSummaryEmployeeYear.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })        

        //add username
        .addCase(addUsername.pending, (state, action) => {
          state.loading = true;
        })
        .addCase(addUsername.fulfilled, (state, action) => {
            state.loading = false;
        })
        .addCase(addUsername.rejected, (state, action) => {
            state.error = true;
            console.error(action.payload);
        })

        //add user
        .addCase(addUser.pending, (state, action) => {
          state.loading = true;
        })
        .addCase(addUser.fulfilled, (state, action) => {
            state.loading = false;
        })
        .addCase(addUser.rejected, (state, action) => {
            state.error = true;
            console.error(action.payload);
        })

        //add employee
        .addCase(addEmployee.pending, (state, action) => {
          state.loading = true;
        })
        .addCase(addEmployee.fulfilled, (state, action) => {
            state.loading = false;
        })
        .addCase(addEmployee.rejected, (state, action) => {
            state.error = true;
            console.error(action.payload);
        })

        //add leaveProrated
        .addCase(addLeaveProrated.pending, (state, action) => {
          state.loading = true;
        })
        .addCase(addLeaveProrated.fulfilled, (state, action) => {
            state.loading = false;
        })
        .addCase(addLeaveProrated.rejected, (state, action) => {
            state.error =true;
            console.error(action.payload);
        })

        //employee photo
        .addCase(uploadEmployeePhoto.pending, (state, action) => {
          state.loading = true;
        })
        .addCase(uploadEmployeePhoto.fulfilled, (state, action) => {
          state.loading = false;
        })
        .addCase(uploadEmployeePhoto.rejected, (state, action) => {
          state.error = true;
          console.error(action.payload);
        })

        .addCase(replaceEmployeePhoto.pending, (state, action) => {
          state.loading = true;
        })
        .addCase(replaceEmployeePhoto.fulfilled, (state, action) => {
          state.loading = false;
        })
        .addCase(replaceEmployeePhoto.rejected, (state, action) => {
          state.error = true;
          console.error(action.payload);
        })

        .addCase(resignEmployee.pending, (state, action) => {
          state.loading = true;
        })
        .addCase(resignEmployee.fulfilled, (state, action) => {
          state.loading = false;
        })
        .addCase(resignEmployee.rejected, (state, action) => {
          state.error = true;
          console.error(action.payload);
        })
    }
});

export const { updateSearch, updatePhoto, resetForm, updateForm, updateUser, updateProrated, resetProrated } = EmployeeSlice.actions;

export default EmployeeSlice.reducer;