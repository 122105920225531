// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pink-background {
    background-color: pink !important;
  }

/* General tooltip style */
.custom-tooltip {
  position: relative;
  display: inline-block;
}

.custom-tooltip:hover::after {
  content: attr(data-toggle);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  color: #fff;
  background-color: #000; /* Black background */
  border-radius: 5px;
  white-space: nowrap;
  z-index: 10;
}
`, "",{"version":3,"sources":["webpack://./src/1.Components/HRReviewClaim/styles.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;EACnC;;AAEF,0BAA0B;AAC1B;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;EAC1B,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,2BAA2B;EAC3B,YAAY;EACZ,WAAW;EACX,sBAAsB,EAAE,qBAAqB;EAC7C,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".pink-background {\r\n    background-color: pink !important;\r\n  }\r\n\r\n/* General tooltip style */\r\n.custom-tooltip {\r\n  position: relative;\r\n  display: inline-block;\r\n}\r\n\r\n.custom-tooltip:hover::after {\r\n  content: attr(data-toggle);\r\n  position: absolute;\r\n  bottom: 100%;\r\n  left: 50%;\r\n  transform: translateX(-50%);\r\n  padding: 5px;\r\n  color: #fff;\r\n  background-color: #000; /* Black background */\r\n  border-radius: 5px;\r\n  white-space: nowrap;\r\n  z-index: 10;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
