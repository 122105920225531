// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.relative-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.oval-image {
    width: 200px;
    height: 230px;
    border-radius: 50%;
    object-fit: cover;
}

.ovalImageStyle {
    width: 140px;
    height: 160px;
    border-radius: 50%;
    object-fit: cover;
}

.containerStyle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-button-style {
    position: absolute;
    width: 45%;
    height: 30%;
    background-color: #1e2833;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/1.Components/Employee/Employee.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".relative-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.oval-image {\r\n    width: 200px;\r\n    height: 230px;\r\n    border-radius: 50%;\r\n    object-fit: cover;\r\n}\r\n\r\n.ovalImageStyle {\r\n    width: 140px;\r\n    height: 160px;\r\n    border-radius: 50%;\r\n    object-fit: cover;\r\n}\r\n\r\n.containerStyle {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.upload-button-style {\r\n    position: absolute;\r\n    width: 45%;\r\n    height: 30%;\r\n    background-color: #1e2833;\r\n    color: white;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    border-radius: 5px;\r\n    cursor: pointer;\r\n    margin-bottom: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
