// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-sm-button {
    padding: 0.02rem 0.30rem; /* Adjust padding to make the button smaller */
    font-size: 0.75rem; /* Adjust font size for smaller text */
    font-weight: bold;
    margin-right: 0.5rem; /* Add margin-right for space between buttons */
}  `, "",{"version":3,"sources":["webpack://./src/1.Components/UserAccess/UserAccess.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB,EAAE,8CAA8C;IACxE,kBAAkB,EAAE,sCAAsC;IAC1D,iBAAiB;IACjB,oBAAoB,EAAE,+CAA+C;AACzE","sourcesContent":[".custom-sm-button {\r\n    padding: 0.02rem 0.30rem; /* Adjust padding to make the button smaller */\r\n    font-size: 0.75rem; /* Adjust font size for smaller text */\r\n    font-weight: bold;\r\n    margin-right: 0.5rem; /* Add margin-right for space between buttons */\r\n}  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
