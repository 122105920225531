import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  Container,
  Card,
  Form,
  Table,
  ButtonGroup,
  Button,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { ThreeDots } from 'react-loader-spinner';
import { getUserAccess, resetSearchText, resetUserAccess, updateSearch, updateUserAccess } from "../../3.Store/UserAccessSlice";
import './UserAccess.css';

const UserAccessList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // State for the current page
  const lists = useSelector(state => state.UserAccessSlice.userAccessList);
  const user = useSelector(state => state.UserAccessSlice.user);
  const search = useSelector(state => state.UserAccessSlice.search);
  const { searchText } = search;

  useEffect(() => {
    setLoading(true);
    dispatch(getUserAccess())
    .then(() => {
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
  }, []);

  const handleSearch = (e) => {
    const entity = { ...search, searchText: e.target.value }
    dispatch(updateSearch(entity));
  };

  const handleBtnBack = () => {
    dispatch(resetSearchText());
    navigate(-1);
  };

  // Group by employee id and merge modules
  const groupedUsers = lists.reduce((acc, user) => {
    const { id, fullname } = user.employee;
    const { id: moduleId } = user.module;

    if (!acc[id]) {
      acc[id] = {
        id,
        fullname,
        modules: new Set(),
      };
    }

    acc[id].modules.add(`${moduleId}`);
    return acc;
  }, {});

  // Convert modules Set to array and create final list
  const mergedList = Object.values(groupedUsers).map(user => ({
    ...user,
    modules: Array.from(user.modules),
  }));

  // Filter the mergedList based on the search term
  const filteredList = mergedList.filter(employee =>
    employee.fullname.toLowerCase().includes(searchText.toLowerCase())
  );

  // Pagination logic
  const itemsPerPage = 20;
  const totalPages = Math.ceil(filteredList.length / itemsPerPage);
  const currentItems = filteredList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleClickView = (id) => {
    const selectedEmployee = currentItems.find(emp => emp.id === id);
    if (selectedEmployee) {
      const entity = { ...user, 
        employee: {
          id: selectedEmployee.id,
          fullname: selectedEmployee.fullname
        },
        modules: selectedEmployee.modules
      }
      dispatch(updateUserAccess(entity));
      navigate(`/UserAccessForm/${id}`);
    };
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Application Module Employee</h1>
      
        <br/><br/>
        <Form.Control placeholder="Search Employee Name" name="searchText" value={searchText} onChange={handleSearch} />
        <br/>

        <Table borderless>
          <thead>
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Action</th>
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {currentItems.length > 0 ? (
                currentItems.map(employee => (
                  <tr key={employee.id} style={{ cursor: "pointer" }}>
                    <td>{employee.fullname}</td>
                    <td>
                      {employee.modules.map((module, index) => (
                        <Button key={index} variant="secondary" size="sm" className="custom-sm-button">
                          {module}
                        </Button>
                      ))}
                    </td>
                    <td onClick={() => handleClickView(employee.id)}>
                      <FontAwesomeIcon icon={faPlusCircle} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No results found</td>
                </tr>
              )}
            </tbody>
          )}
        </Table>

        <Col style={{ marginLeft: "auto" }}>
          <Form.Label className="FormLabel">{`${currentPage} of ${totalPages}`}</Form.Label> &ensp;
          <Form.Label className="FormLabel" onClick={handlePreviousPage} disabled={currentPage === 1} style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon={faChevronLeft} style={{color: "#ffffff",}} />
          </Form.Label> &ensp;
          <Form.Label className="FormLabel" onClick={handleNextPage} style={{ cursor: 'pointer' }} disabled={currentPage === totalPages}>
            <FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} />
          </Form.Label>
        </Col>

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};
export default UserAccessList;