import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import {
  Container,
  Card,
  Form,
  Table,
  ButtonGroup,
  Button,
  InputGroup,
  Col
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLeaf,
  faStethoscope,
  faHospital,
  faChild,
  faStar,
  faMoneyBill,
  faPencilSquare,
  faPenToSquare,
  faSearch,
  faChevronRight,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import { getLeaveAwardsYearPagination, updateSearch } from "../../3.Store/LeaveAwardsSlice";
import { ThreeDots } from 'react-loader-spinner';

const LeaveAwardList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const getYearFromQuery = () => {
    const params = new URLSearchParams(location.search);
    const year = params.get('years');
    return year ? parseInt(year) : new Date().getFullYear();
  };

  const getPageFromQuery = () => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    return page ? parseInt(page) : 1;
  };

  const [selectedYear, setSelectedYear] = useState(getYearFromQuery());
  const awardLists = useSelector(state => state.LeaveAwardsSlice.awardLists) || [];
  const search = useSelector((state) => state.LeaveAwardsSlice.search);
  const { searchText } = search;
  const [loading, setLoading] = useState(false);

  //use to filter the table based on searchText
  const [filteredStaffList, setFilteredStaffList] = useState(awardLists);

  const [currentPage, setCurrentPage] = useState(getPageFromQuery());
  const pageSize = 20; // Number of items per page
  const totalPages = Math.ceil(filteredStaffList.length / pageSize);

  useEffect(() => {
    setLoading(true);
    dispatch(getLeaveAwardsYearPagination({ year: selectedYear }))
    .then(() => {
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
  }, [])

  useEffect(() => {
    setFilteredStaffList(
      awardLists.filter(employee =>
        employee.employee.fullname.toLowerCase().includes(searchText.toLowerCase())
      )
    );
    setCurrentPage(1);
  }, [awardLists, searchText]);

  const handleBtnSearchYear = () => {
    setLoading(true);
    dispatch(getLeaveAwardsYearPagination({ year: selectedYear }))
    .then(() => {
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
  };

  const handleSearch = (e) => {
    const entity = {...search, searchText: e.target.value}
    console.log(entity)
    dispatch(updateSearch(entity));
  }

  const handleBtnBack = () => {
    navigate(-1);
  };

  const handleBtnCreate = () => {
    navigate(`/LeaveAwardForm?page=${currentPage}&years=${selectedYear}`);
  };

  const handleClickView = (id) => {
    navigate(`/LeaveAwardForm/${id}?page=${currentPage}&years=${selectedYear}`)
  };

  const renderStaffList = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, filteredStaffList.length);
    
    return filteredStaffList.slice(startIndex, endIndex).map(employee => (
      <tr key={employee.id} style={{ cursor: "pointer" }}>
        <td>{employee.employee.fullname}</td>
        <td>{employee.employee.company.short_description}</td>
        <td>{moment(employee.effective_date).format("YYYY-MM-DD")}</td>
        <td>{employee.employee.service_length.short_description}</td>
        <td>{employee.employee_category.description}</td>
        <td>{employee.annual}</td>
        <td>{employee.advanced}</td>
        <td>{employee.sick}</td>
        <td>{employee.hospitalization}</td>
        <td>{employee.maternity}</td>
        <td>{employee.special}</td>
        <td>{employee.medical}</td>
        <td onClick={() => handleClickView(employee.id)}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </td>
      </tr>
    ));
  };

  // Function to generate options for the dropdown menu
  const renderYearOptions = () => {
    const endYear = new Date().getFullYear() + 1; // One year ahead
    const startYear = endYear - 4;
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
        years.push(
            <option key={year} value={year}>{year}</option>
        );
    }
    return years;
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Leave Awards</h1>
        <h1 className="MiniTitleHeader">List</h1>

        <InputGroup className="mb-3">
          <Form.Select value={selectedYear} onChange={(e) => setSelectedYear(parseInt(e.target.value))}>
              {renderYearOptions()}
          </Form.Select>
          <Button className="SearchIconBtn" onClick={handleBtnSearchYear}>
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroup>

        <InputGroup className="mb-3">
          <Form.Control 
            placeholder="Search by Employee Name" 
            value={searchText}
            onChange={handleSearch} 
          />
        </InputGroup>

        <hr className="hr-white " />

        <Table borderless>
          <thead>
            <tr>
              <th>Employee</th>
              <th>Company</th>
              <th>Effective Date</th>
              <th>Service Length</th>
              <th>Employee Category</th>
              <th>
                <FontAwesomeIcon icon={faLeaf} />
              </th>
              <th>
                <FontAwesomeIcon icon={faLeaf} />
                <FontAwesomeIcon icon={faLeaf} />
              </th>
              <th>
                <FontAwesomeIcon icon={faStethoscope} />
              </th>
              <th>
                <FontAwesomeIcon icon={faHospital} />
              </th>
              <th>
                <FontAwesomeIcon icon={faChild} />
              </th>
              <th>
                <FontAwesomeIcon icon={faStar} />
              </th>
              <th>
                <FontAwesomeIcon icon={faMoneyBill} />
              </th>
              <th>
                <FontAwesomeIcon icon={faPencilSquare} />
              </th>
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {filteredStaffList.length > 0 ? (
                renderStaffList()
              ) : (
                <tr>
                  <td colSpan="13">No results found</td>
                </tr>
              )}
            </tbody>
          )}
        </Table>

        <Col style={{ marginLeft: "auto" }}>
          <Form.Label className="FormLabel">{`${currentPage} of ${totalPages}`}</Form.Label> &ensp;
          {currentPage > 1 ? (
            <Form.Label className="FormLabel" onClick={handlePreviousPage}>
              <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#ffffff" }} />
            </Form.Label>
          ) : (
            <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Form.Label>
          )}
          &ensp;
          {currentPage < totalPages ? (
            <Form.Label className="FormLabel" onClick={handleNextPage}>
              <FontAwesomeIcon icon={faChevronRight} style={{ color: "#ffffff" }} />
            </Form.Label>
          ) : (
            <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
              <FontAwesomeIcon icon={faChevronRight} />
            </Form.Label>
          )}
        </Col>

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>
          &nbsp; &nbsp;
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnCreate}>
              Create
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};
export default LeaveAwardList;
