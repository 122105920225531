import { useNavigate } from "react-router-dom";
import { Container, Card, Table, ButtonGroup, Button, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getReviewClaimsPagination, updateCurrentPage, getMaxCount } from "../../3.Store/SUReviewClaimSlice";
import moment from "moment/moment";
import { ThreeDots } from 'react-loader-spinner';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const SUReviewClaimList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const lists = useSelector(state => state.SUReviewClaimSlice.claimlists);
    const currentPage = useSelector(state => state.SUReviewClaimSlice.currentPage);
    const totalPages = useSelector(state => state.SUReviewClaimSlice.totalPages);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        if(currentPage) {
            dispatch(getReviewClaimsPagination({currentPage}))
            .then(() => {
                dispatch(getMaxCount())
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
        }
    }, [currentPage]);

    const handleBtnBack = () => {
        navigate(-1);
    };

    const handleClickView = (id) => {
        navigate(`/SUReviewClaimForm/${id}`)
    };

    const handlePreviousPage = () => {
        setLoading(true);
        if (currentPage > 1) {
          dispatch(updateCurrentPage(currentPage - 1));
          dispatch(getReviewClaimsPagination({ currentPage: currentPage - 1 }))
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
        }
    };
    
    const handleNextPage = () => {
        setLoading(true);
        if (currentPage < totalPages) {
            dispatch(updateCurrentPage(currentPage + 1));
            dispatch(getReviewClaimsPagination({ currentPage: currentPage + 1 }))
            .then(() => {
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
        }
    };

    return (
        <Container>
        {loading && (
            <ThreeDots
            visible={loading}
            height="80"
            width="80"
            color="#4fa94d"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{
                position: 'fixed',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: '9999',
            }}
            />
        )}
        <Card className="CardContent">
            <h1 className="TitleHeader">Supervisor Medical Claims Review</h1>
            <h1 className="MiniTitleHeader">List</h1>

            <Col style={{ marginLeft: "auto" }}>
                <Form.Label className="FormLabel">{`${currentPage} of ${totalPages}`}</Form.Label> &ensp;
                <Form.Label className="FormLabel" onClick={handlePreviousPage} disabled={currentPage === 1}><FontAwesomeIcon icon={faChevronLeft} style={{color: "#ffffff",}} /></Form.Label> &ensp;
                <Form.Label className="FormLabel" onClick={handleNextPage} disabled={currentPage === totalPages}><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /></Form.Label>
            </Col>

            <Table>
            <thead>
                <tr>
                <th>Date Applied</th>
                <th>Employee</th>
                <th>Practitioner</th>
                <th>Receipt No.</th>
                <th>Claim (RM)</th>
                <th>Status</th>
                </tr>
            </thead>
            {!loading && (
            <tbody>
                {lists.length > 0 ? (
                    lists.map(employee => (
                    <tr onClick={() => handleClickView(employee.id)} key={employee.id} style={{ cursor: "pointer" }}>
                        <td>{moment(employee.created).format("YYYY-MM-DD")}</td>
                        <td>{employee.employee.fullname}</td>
                        <td>{employee.receipt_no}</td>
                        <td>{employee.receipt_reference}</td>
                        <td>{employee.amount}</td>
                        <td>{employee.status.description}</td>
                    </tr>
                    ))
                    ) : (
                    <tr>
                    <td colSpan="6">No results found</td>
                    </tr>
                )}
            </tbody>
            )}
            </Table>

            <Col style={{ marginLeft: "auto" }}>
                <Form.Label className="FormLabel">{`${currentPage} of ${totalPages}`}</Form.Label> &ensp;
                <Form.Label className="FormLabel" onClick={handlePreviousPage} disabled={currentPage === 1}><FontAwesomeIcon icon={faChevronLeft} style={{color: "#ffffff",}} /></Form.Label> &ensp;
                <Form.Label className="FormLabel" onClick={handleNextPage} disabled={currentPage === totalPages}><FontAwesomeIcon icon={faChevronRight} style={{color: "#ffffff",}} /></Form.Label>
            </Col>

            <div className="d-flex justify-content-between">
            <ButtonGroup className="my-2">
                <Button className="Button" onClick={handleBtnBack}>
                Back
                </Button>
            </ButtonGroup>
            </div>
        </Card>
        </Container>
    );
};
export default SUReviewClaimList;