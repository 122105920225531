import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../4.Utils/apiServices";

const namespace = "hr/review";

//List of HR Review + Pagination
export const getHRReviewPagination = createAsyncThunk(
    `${namespace}/page`,
    async ({ body, currentPage }, { rejectWithValue }) => {
        const api = "get_HR_review+pagination";
        const replace = { 
            search: "{page}", 
            replace: currentPage 
        };
        const opt = {
            replace: replace,
            body: body
        };
        try {
        const response = await apiServices(api, opt);
        return response.data;
        } catch (err) {
        return rejectWithValue(err);
        }
    }
);

//Lists of HR Review Page Count
export const getHRReviewPageCount = createAsyncThunk(
    `${namespace}/PageCount`,
    async (body, { rejectWithValue }) => {
      const api = "get_HR_review_pagecount";
      const opt = {
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Mark Reviewed
export const ReviewedApplication = createAsyncThunk(
    `${namespace}/reviewed`,
    async (body, { rejectWithValue }) => {
      const api = "HR_mark_reviewed";
      const opt = {
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Approved Application
export const ApprovedApplication = createAsyncThunk(
    `${namespace}/approved`,
    async (body, { rejectWithValue }) => {
      const api = "HR_Approved_Application";
      const opt = {
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Reject Application
export const RejectedApplication = createAsyncThunk(
    `${namespace}/rejected`,
    async (body, { rejectWithValue }) => {
      const api = "HR_Reject_Application";
      const opt = {
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Send Notification
export const ApplicationNotification = createAsyncThunk(
    `${namespace}/notification`,
    async (body, { rejectWithValue }) => {
      const api = "HR_Send_Notification";
      const opt = {
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Search HR Review by Employee's Name + Pagination
export const searchHRReviewPagination = createAsyncThunk(
  `${namespace}/search`,
  async ({ body, currentPage }, { rejectWithValue }) => {
      const api = "HR_Search_Leave";
      const replace = { 
          search: "{page}", 
          replace: currentPage 
      };
      const opt = {
          replace: replace,
          body: body
      };
      try {
      const response = await apiServices(api, opt);
      return response.data;
      } catch (err) {
      return rejectWithValue(err);
      }
  }
);

//Search HR Review Page Count
export const searchHRReviewPageCount = createAsyncThunk(
  `${namespace}/serach/PageCount`,
  async (body, { rejectWithValue }) => {
    const api = "HR_Search_Leave_PageCount";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Get Medical Cert
export const getMedicalCert = createAsyncThunk(
  `${namespace}/medicalCert`,
  async (body, { rejectWithValue }) => {
    const api = "get_medical_certificate_attachment";
    const replace = {
      search: "{id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Get Special Leave Attachment
export const getSpecialLeaveAttachment = createAsyncThunk(
  `${namespace}/specialAttachment`,
  async (body, { rejectWithValue }) => {
    const api = "get_special_leave_attachment";
    const replace = {
      search: "{id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Get Details
export const getLeaveApplicationDetails = createAsyncThunk(
  `${namespace}/details`,
  async (body, { rejectWithValue }) => {
    const api = "get_leave_application_details";
    const replace = {
      search: "{leave_application_id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Update Details
export const updateLeaveApplicationDetails = createAsyncThunk(
  `${namespace}/details/update`,
  async (body, { rejectWithValue }) => {
    const api = "update_review_leave";
    const replace = {
      search: "{leave_application_id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);


const initialState = {
    search: {
      "searchText": "",
      "advancedLeave": false,
      "annualLeave": false,
      "hospitalizationLeave": false,
      "maternityLeave": false,
      "otherLeave": false,
      "sickLeave": false,
      "specialLeave": false,
      "unpaidLeave": false
    },
    lists: [],
    cert: [],
    loading: false,
    error: false,
    currentPage: 1,
    totalPages: "",
    list: {},
    special_attachment: [],
}

const ReviewLeaveApplicationSlice = createSlice({
    name: namespace,
    initialState: initialState,
    reducers: {
      updateCurrentPage(state, action) {
        return { ...state, currentPage: action.payload };
      },

      updateList(state, action) {
        state.list = action.payload;
      },

      // update entity
      updateSearch(state, action) {
        state.search = action.payload;
      },

      resetList(state) {
        return {
          ...state,
          list: initialState.list,
          cert: initialState.cert,
          special_attachment: initialState.special_attachment
        };
      },

      getList(state, action) {
        return {
            ...state,
            list: state.lists.find((list) => list.id == action.payload),
        };
      },
    },
    extraReducers: (builder) => {
      builder
      // Get Leave Application by Pagination
      .addCase(getHRReviewPagination.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getHRReviewPagination.fulfilled, (state, action) => {
        state.loading = false;
        state.lists = action.payload;
      })
      .addCase(getHRReviewPagination.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      // Get Leave Application Page Count
      .addCase(getHRReviewPageCount.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getHRReviewPageCount.fulfilled, (state, action) => {
        state.loading = false;
        state.totalPages = action.payload;
      })
      .addCase(getHRReviewPageCount.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      // Get Leave Application by Pagination
      .addCase(searchHRReviewPagination.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(searchHRReviewPagination.fulfilled, (state, action) => {
        state.loading = false;
        state.lists = action.payload;
      })
      .addCase(searchHRReviewPagination.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      // Get Leave Application Page Count
      .addCase(searchHRReviewPageCount.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(searchHRReviewPageCount.fulfilled, (state, action) => {
        state.loading = false;
        state.totalPages = action.payload;
      })
      .addCase(searchHRReviewPageCount.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      // Medical Cert
      .addCase(getMedicalCert.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getMedicalCert.fulfilled, (state, action) => {
          state.loading = false;
          state.cert = action.payload;
          console.log(action.payload);
      })
      .addCase(getMedicalCert.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
      })

      //Special Leave Attachment
      .addCase(getSpecialLeaveAttachment.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSpecialLeaveAttachment.fulfilled, (state, action) => {
          state.loading = false;
          state.special_attachment = action.payload;
          console.log(action.payload);
      })
      .addCase(getSpecialLeaveAttachment.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
      })

      //Details
      .addCase(getLeaveApplicationDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getLeaveApplicationDetails.fulfilled, (state, action) => {
          state.loading = false;
          state.list = action.payload;
          console.log(action.payload);
      })
      .addCase(getLeaveApplicationDetails.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
      })

      //update
      .addCase(updateLeaveApplicationDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateLeaveApplicationDetails.fulfilled, (state, action) => {
          state.loading = false;
          // state.list = action.payload;
          // console.log(action.payload);
      })
      .addCase(updateLeaveApplicationDetails.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
      })
    }
});

export const { updateCurrentPage, updateSearch, getList, updateList, resetList } = ReviewLeaveApplicationSlice.actions;

export default ReviewLeaveApplicationSlice.reducer;