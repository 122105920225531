import { useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  Table,
  InputGroup,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faEye } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { postsearchText, updateSearch } from "../../3.Store/LeaveMovementSlice";
import { ThreeDots } from 'react-loader-spinner';
import { useState } from "react";

const HRLeaveReportList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchResult = useSelector(state => state.LeaveMovementSlice.employees);
  const search = useSelector((state) => state.LeaveMovementSlice.search);
  const { searchText } = search;
  const [loading, setLoading] = useState(false);

  const handleSearch = (e) => {
    const entity = {...search, searchText: e.target.value}
    console.log(entity)
    dispatch(updateSearch(entity));
  }

  const handleBtnSearch = () => {
    const entity = { ...search };
    setLoading(true);
    dispatch(postsearchText(entity))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleBtnSearch();
    }
  };

  const handleClickView = (employeeId) => {
    navigate(`/HRLeaveReportForm/${employeeId}`);
  };

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Leave Movement Records</h1>

        <InputGroup className="mb-3">
          <Form.Control placeholder="Search" value={searchText} onChange={handleSearch} onKeyPress={handleKeyPress}/>
          <Button className="SearchIconBtn" onClick={handleBtnSearch}>
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroup>

        <Table>
          <thead>
            <tr>
              <th>Staff Name</th>
              <th>Company</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {searchResult.length > 0 ? (
                searchResult.map(employee => (
                  <tr key={employee.id} style={{ cursor: "pointer" }}>
                    <td onClick={() => handleClickView(employee.id)}>{employee.fullname}</td>
                    <td onClick={() => handleClickView(employee.id)}>{employee.company.description}</td>
                    <td onClick={() => handleClickView(employee.id)}>{employee.status.description}</td>
                    <td onClick={() => handleClickView(employee.id)}>
                      <FontAwesomeIcon icon={faEye} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No results found</td>
                </tr>
              )}
            </tbody>
          )}
        </Table>
      </Card>
    </Container>
  );
};
export default HRLeaveReportList;