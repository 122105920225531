import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiServices } from "../4.Utils/apiServices";
import moment from "moment/moment";

const namespace="medical_claim";

//List of Leave Summaries by Employee and Year
export const getMedicalClaimLists = createAsyncThunk(
    `${namespace}/medical/claim/lists`,
    async ({ id, page }, { rejectWithValue }) => {
      const api = "get_medicalClaimByEmployeeId";
      const opt = {
        replace: [
          { search: "{employee_id}", replace: id },
          { search: "{page}", replace: page },
        ],
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Medical Claim Page Count
export const getMedicalClaimListsCount = createAsyncThunk(
    `${namespace}/medical/claim/count`,
    async (body, { rejectWithValue }) => {
      const api = "get_medicalClaimByEmployeeId_count";
      const replace = {
        search: "{employee_id}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Get A Medical Claim
export const getAMedicalClaim = createAsyncThunk(
    `${namespace}/medical/claim/details`,
    async (body, { rejectWithValue }) => {
      const api = "medical_claim_by_leaveapplication";
      const replace = {
        search: "{id}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Add Medical Claim
export const addMedicalClaim = createAsyncThunk(
    `${namespace}/medical/claim/add`,
    async (body, { rejectWithValue }) => {
      const api = "add_medicalClaim";
      const opt = {
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Add Medical Claim Attachment
export const addMedicalClaimAttachment = createAsyncThunk(
  `${namespace}/medical/claim/add/attachment`,
  async (body, { rejectWithValue }) => {
    const api = "add_medicalClaimAttachment";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Attachment
export const getAMedicalClaimAttachment = createAsyncThunk(
    `${namespace}/medical/claim/attachment`,
    async (body, { rejectWithValue }) => {
      const api = "get_medical_benefits_attachment";
      const replace = {
        search: "{medical_benefit_attachment_id}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Edit
export const editMedicalClaim = createAsyncThunk(
    `${namespace}/medical/claim/edit`,
    async (body, { rejectWithValue }) => {
      const api = "editMedicalClaim";
      const replace = {
        search: "{medical_benefit_id}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Edit Attachment
export const editMedicalClaimAttachment = createAsyncThunk(
  `${namespace}/medical/claim/edit/attachment`,
  async (body, { rejectWithValue }) => {
    const api = "editMedicalClaimAttachment";
    const replace = {
      search: "{medical_benefit_id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Delete
export const deleteMedicalClaim = createAsyncThunk(
  `${namespace}/medical/claim/delete`,
  async (body, { rejectWithValue }) => {
    const api = "deleteMedicalClaim";
    const replace = {
      search: "{medical_benefit_id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//medical claim by leave application id
export const getMedicalClaimByLeaveApplicationID = createAsyncThunk(
  `${namespace}/medical/claim`,
  async (body, { rejectWithValue }) => {
    const api = "medical_claim_by_leaveapplication";
    const replace = {
      search: "{id}",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
    loading: false,
    error: false,
    currentPage: 1,
    totalPages: 0,
    medicalClaimLists: [],
    medicalClaim: {
        amount : 0,
        receipt_reference : "",
        receipt_no : "",
        claim_date : moment(new Date()).format("YYYY-MM-DD"),
    },
    attachment: {}
};

const MedicalClaimSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        // update entity
        updateCurrentPage(state, action) {
            state.currentPage = action.payload;
        },

        updateMedicalClaim(state, action) {
            state.medicalClaim = action.payload;
        },

        updateAttachment(state, action) {
            state.attachment = action.payload;
        },

        resetClaim(state) {
            return {
              ...state,
              medicalClaim: initialState.medicalClaim,
              attachment: initialState.attachment
            };
        },

        getListDetails(state, action) {
            return {
                ...state,
                medicalClaim: state.medicalClaimLists.find((medicalClaim) => medicalClaim.id == action.payload),
            };
        },
    },
    extraReducers: (builder) => {
        builder
        //get list by employee id
        .addCase(getMedicalClaimLists.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(getMedicalClaimLists.fulfilled, (state, action) => {
            state.loading = false;
            state.medicalClaimLists = action.payload;
        })
        .addCase(getMedicalClaimLists.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //get details
        .addCase(getAMedicalClaim.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(getAMedicalClaim.fulfilled, (state, action) => {
            state.loading = false;
            state.medicalClaim = action.payload;
        })
        .addCase(getAMedicalClaim.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //get list count
        .addCase(getMedicalClaimListsCount.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(getMedicalClaimListsCount.fulfilled, (state, action) => {
            state.loading = false;
            state.totalPages = action.payload;
        })
        .addCase(getMedicalClaimListsCount.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //Add
        .addCase(addMedicalClaim.pending, (state) => {
            state.loading = true;
        })
        .addCase(addMedicalClaim.fulfilled, (state, action) => {
            state.loading = false;
        })
        .addCase(addMedicalClaim.rejected, (state, action) => {
            state.error = true;
        })

        //Add Attachment
        .addCase(addMedicalClaimAttachment.pending, (state) => {
          state.loading = true;
        })
        .addCase(addMedicalClaimAttachment.fulfilled, (state, action) => {
            state.loading = false;
        })
        .addCase(addMedicalClaimAttachment.rejected, (state, action) => {
            state.error = true;
        })

        //get attachment
        .addCase(getAMedicalClaimAttachment.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(getAMedicalClaimAttachment.fulfilled, (state, action) => {
            state.loading = false;
            state.attachment = action.payload;
        })
        .addCase(getAMedicalClaimAttachment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //Edit
        .addCase(editMedicalClaim.pending, (state) => {
            state.loading = true;
        })
        .addCase(editMedicalClaim.fulfilled, (state, action) => {
            state.loading = false;
        })
        .addCase(editMedicalClaim.rejected, (state, action) => {
            state.error = true;
        })

        //Edit Attachment
        .addCase(editMedicalClaimAttachment.pending, (state) => {
          state.loading = true;
        })
        .addCase(editMedicalClaimAttachment.fulfilled, (state, action) => {
            state.loading = false;
        })
        .addCase(editMedicalClaimAttachment.rejected, (state, action) => {
            state.error = true;
        })

        //Delete
        .addCase(deleteMedicalClaim.pending, (state) => {
          state.loading = true;
        })
        .addCase(deleteMedicalClaim.fulfilled, (state, action) => {
            state.loading = false;
        })
        .addCase(deleteMedicalClaim.rejected, (state, action) => {
            state.error = true;
        })

        .addCase(getMedicalClaimByLeaveApplicationID.pending, (state, action) => {
          state.loading = true;
        })
        .addCase(getMedicalClaimByLeaveApplicationID.fulfilled, (state, action) => {
          state.loading = false;
          state.medicalClaim = action.payload;
        })
        .addCase(getMedicalClaimByLeaveApplicationID.rejected, (state, action) => {
          state.error = true;
          console.error(action.payload);
        })
    }
});

export const { updateCurrentPage, updateMedicalClaim, resetClaim, getListDetails, updateAttachment } = MedicalClaimSlice.actions;

export default MedicalClaimSlice.reducer;