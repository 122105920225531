import React, { useEffect, useState } from 'react';
import { Container, Card, Form, Table, InputGroup, Button, ButtonGroup, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPenToSquare, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getEmployeesChronically, updateSearch, postsearchText } from "../../3.Store/EmployeeSlice";
import { ThreeDots } from 'react-loader-spinner';

const EmployeeList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [table, setTable] = useState(false);
    const [loading, setLoading] = useState(false);
    const employeeLists = useSelector(state => state.EmployeeSlice.employees);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 20; // Number of items per page
    const totalPages = Math.ceil(employeeLists.length / pageSize);

    const getSearchParamsFromQuery = () => {
        const params = new URLSearchParams(location.search);
        const searchText = params.get('searchText') || '';
        const active = params.get('active') || '1';
        return { searchText, active };
    };

    const { searchText: initialSearchText, active: initialActive } = getSearchParamsFromQuery();

    const [searchText, setSearchText] = useState(initialSearchText);
    const [active, setActive] = useState(parseInt(initialActive, 10));
    
    useEffect(() => {
        const search = {
            searchText: initialSearchText,
            active: parseInt(initialActive, 10),
        };
        dispatch(updateSearch(search));
        setSearchText(initialSearchText);
        setActive(parseInt(initialActive, 10));
        if (initialSearchText || initialActive !== '0') {
            handleBtnSearch(searchText, active);
        }
    }, [initialSearchText, initialActive]);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleBtnSearch();
        }
    };

    const handleBtnSearch = (searchTextOverride = searchText, activeOverride = active) => {
        setTable(true);
        setLoading(true);
        const entity = { searchText: searchTextOverride, active: activeOverride };
        dispatch(updateSearch(entity));
        if (searchTextOverride === "") {
            dispatch(getEmployeesChronically())
                .then(() => {
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            dispatch(postsearchText(entity))
                .then(() => {
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    };

    const handleCheckboxChange = () => {
        const updatedActive = active === 1 ? 0 : 1;
        setActive(updatedActive);
        const entity = { searchText, active: updatedActive };
        dispatch(updateSearch(entity));
        handleBtnSearch(searchText, updatedActive);
    };

    const handleClickView = (id) => {
        navigate(`/EmployeeForm/${id}?searchText=${searchText}&active=${active}`);
    };

    const handleBtnCreate = () => {
        navigate(`/EmployeeForm?searchText=${searchText}&active=${active}`);
    };

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const renderStaffList = () => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize, employeeLists.length);
    
        return employeeLists
          .slice(startIndex, endIndex)
          .map(employee => (
            <tr key={employee.id} style={{ cursor: "pointer" }}>
                <td onClick={() => handleClickView(employee.id)}>{employee.fullname}</td>
                <td onClick={() => handleClickView(employee.id)}>{employee.username}</td>
                <td onClick={() => handleClickView(employee.id)}>{employee.company.description}</td>
                <td onClick={() => handleClickView(employee.id)}>{employee.status.description}</td>
                <td onClick={() => handleClickView(employee.id)}>
                    <FontAwesomeIcon icon={faPenToSquare} />
                </td>
            </tr>
          ));
    };

    return (
        <Container>
        {loading && (
            <ThreeDots
            visible={loading}
            height="80"
            width="80"
            color="#4fa94d"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{
                position: 'fixed',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: '9999',
            }}
            />
        )}
        <Card className="CardContent">
            <h1 className="TitleHeader">Employees</h1>

            <InputGroup className="mb-3">
            <Form.Control placeholder="Search" value={searchText} onChange={handleSearch} onKeyPress={handleKeyPress} />
            <Button className="SearchIconBtn" onClick={() => handleBtnSearch(searchText, active)}>
                <FontAwesomeIcon icon={faSearch} />
            </Button>
            </InputGroup>

            <div className="FormLabel">
                <Form.Check 
                    inline 
                    type="checkbox" 
                    checked={active === 1} 
                    label="Active Employee Only"
                    onChange={handleCheckboxChange}
                />
            </div>

            {!loading && table && (
            <Table className="mt-5">
                <thead>
                    <tr>
                        <th>Staff</th>
                        <th>Username</th>
                        <th>Company</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {employeeLists.length > 0 ? (
                        renderStaffList()
                    ) : (
                        <tr>
                        <td colSpan="5">No results found</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            )}

            <Col style={{ marginLeft: "auto" }}>
                <Form.Label className="FormLabel">{`${currentPage} of ${totalPages}`}</Form.Label> &ensp;
                {currentPage > 1 ? (
                    <Form.Label className="FormLabel" onClick={handlePreviousPage}>
                    <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#ffffff" }} />
                    </Form.Label>
                ) : (
                    <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                    </Form.Label>
                )}
                &ensp;
                {currentPage < totalPages ? (
                    <Form.Label className="FormLabel" onClick={handleNextPage}>
                    <FontAwesomeIcon icon={faChevronRight} style={{ color: "#ffffff" }} />
                    </Form.Label>
                ) : (
                    <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
                    <FontAwesomeIcon icon={faChevronRight} />
                    </Form.Label>
                )}
            </Col>
            
            
            <div className="d-flex justify-content-between">
                <ButtonGroup className="my-2">
                    <Button className="Button" onClick={() => navigate(-1)}>
                    Back
                    </Button>
                </ButtonGroup>
                &nbsp; &nbsp;
                <ButtonGroup className="my-2">
                    <Button className="Button" onClick={handleBtnCreate}>
                    Add
                    </Button>
                </ButtonGroup>
            </div>
        </Card>
        </Container>
    );
};

export default EmployeeList;