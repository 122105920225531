import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { getAEmployee, getEmployeeEdit, updateUser, dismissUser } from "../../3.Store/EmployeeSlice";
import { Authentication, Gender } from "../../2.Commons/DropdownOption";
import moment from "moment/moment";
import { ThreeDots } from 'react-loader-spinner';

const StaffInformationForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showResetPwd, setShowResetPwd] = useState(false);
  const [RepeatPwd, setRepeatPwd] = useState(null);
  const employee = useSelector((state) => state.EmployeeSlice.employeeDetail);
  const auth = useSelector((state) => state.EmployeeSlice.user);
  const { password, authentication_type } = auth;

  const user = JSON.parse(localStorage.getItem("current_user"));
  const id = user?.id;

  useEffect(() => {
    if(id) {
      setLoading(true);
      dispatch(getAEmployee({id}));
      dispatch(getEmployeeEdit({id}))
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    };
  }, [id]);

  // Function to get the authentication type label
  const getAuthenticationLabel = (value) => {
    const auth = Authentication.find(auth => auth.value === value);
    return auth ? auth.label : "";
  };

  // Function to get the gender type label
  const getGenderLabel = (value) => {
    const gender = Gender.find(gender => gender.value === value);
    return gender ? gender.label : "";
  };

  const handleBtnResetPwd = () => {
    setShowResetPwd(true);
  };

  const handleBtnSuggest = () => {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let autoPassword = "";

    for (let i = 0; i < 8; i++) { // Assuming default password length is 12
      autoPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }

    const entity = { ...auth, password: autoPassword };
    console.log(entity);
    dispatch(updateUser(entity));
    setRepeatPwd(autoPassword);
  };

  const handleBtnCancel = () => {
    setShowResetPwd(false);
  };

  const handlePassword = (e) => {
    const entity = { ...auth, password: e.target.value };
    console.log(entity);
    dispatch(updateUser(entity));
  };

  const handleRepeatPassword = (e) => {
    console.log(e.target.value);
    setRepeatPwd(e.target.value);
  };

  const handleBtnSubmit = () => {
    if (showResetPwd) {
      if (password !== RepeatPwd) {
        Swal.fire("Error", "Passwords do not match", "error");
        return;
      }
      else if (password=== "" || RepeatPwd==="") {
        Swal.fire("Error", "Please enter a password", "error");
        return;
      }
      else if (password.length < 6) {
        Swal.fire("Error", "Password must be at least 6 characters long", "error");
        return;
      }
      else if (password === RepeatPwd) {
        setLoading(true);
        dispatch(dismissUser({ authentication_type: 1, id: id, password: password }))
          .unwrap()
          .then(() => {
            setLoading(false);
            Swal.fire({
              title: "Password for " + user.fullname + ' is changed',
              icon: 'success',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                setShowResetPwd(false);
                dispatch(getAEmployee({id}));
                dispatch(getEmployeeEdit({id}));
                
              }
            });
          })
          .catch((err) => {
            setLoading(false);
            Swal.fire("Error", err.response.data, "error");
          });
      }
    }
  };

  const handleBtnBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      {loading && (
          <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
              position: 'fixed',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: '9999',
          }}
          />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Staff Informations</h1>

        <Row className="my-3">
          <Col md={6} sm={6}>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Fullname</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={employee.fullname} readOnly/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Username</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={employee.username} readOnly/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Staff ID</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={employee.sid} readOnly/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Email</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={employee.email} readOnly/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Gender</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={getGenderLabel(employee.gender)} readOnly/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">
                  Authentication Type
                </Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={getAuthenticationLabel(authentication_type)} readOnly/>
              </Col>
            </Row>
          </Col>

          <Col md={6} sm={6}>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Date Join</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={moment(employee.date_joined).format("YYYY-MM-DD")} readOnly/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Company</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={employee.company.description} readOnly/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Working Schedule</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={employee.working_schedule.short_description} readOnly/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Employee Category</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={employee.employee_category.description} readOnly/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Service Length</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={employee.service_length.short_description} readOnly/>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Supervisor</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={employee.supervisor.fullname} readOnly/>
              </Col>
            </Row>
          </Col>
        </Row>

        {showResetPwd == true && (
          <Card style={{ backgroundColor: "gray", marginBottom: "20px" }}>
            <Row className="my-3 mx-3">
              <Col md={6} sm={6}>
                <Form.Label className="FormLabel">New Password</Form.Label>
              </Col>
              <Col md={6} sm={6}>
                <Form.Control name="password" value={password} onChange={handlePassword}/>
              </Col>
            </Row>
            <Row className="my-3 mx-3">
              <Col md={6} sm={6}>
                <Form.Label className="FormLabel">
                  Repeat New Password
                </Form.Label>
              </Col>
              <Col md={6} sm={6}>
                <Form.Control value={RepeatPwd} onChange={handleRepeatPassword}/>
              </Col>
            </Row>
            <div className="d-flex justify-content-between my-3 mx-3">
              <ButtonGroup>
                <Button className="Button" onClick={handleBtnSuggest}>
                  Suggest
                </Button>
              </ButtonGroup>
              <ButtonGroup>
                <Button className="Button" onClick={handleBtnCancel}>
                  Cancel
                </Button>
                &nbsp;&nbsp;
                <Button className="Button" onClick={handleBtnSubmit}>
                  Submit
                </Button>
              </ButtonGroup>
            </div>
          </Card>
        )}

        <div className="d-flex justify-content-between my-2">
          <ButtonGroup>
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button className="Button" onClick={handleBtnResetPwd}>
              Reset Password
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};
export default StaffInformationForm;
