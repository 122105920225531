export const LeaveType = [
  { id: "A", label: "Annual Leave", value: "annual" },
  { id: "AD", label: "Advanced Leave", value: "advanced" },
  { id: "H", label: "Hospitalization", value: "hospitalization" },
  { id: "M", label: "Maternity Leave", value: "maternity" },
  { id: "O", label: "Other Leave", value: "other" },
  { id: "SI", label: "Sick Leave", value: "sick" },
  { id: "SP", label: "Special Leave", value: "special" },
  { id: "UP", label: "Unpaid Leave", value: "unpaid" },
];

export const LeaveNumberDay = [
  { id: "H", label: "1/2 Day", value: "half" },
  { id: "1", label: "One Day", value: "one" },
  { id: "M", label: "More than 1 Day", value: "more_than_one" },
];

export const Location = [
  { id: "I", label: "In Town", value: "in_town" },
  { id: "OT", label: "Out of Town", value: "out_town" },
  { id: "W", label: "West Malaysia", value: "w_msia" },
];

export const SpecialLeaveType = [
  { label: "Convocation", value: "OS" },
  { label: "Paternity Leave", value: "OS" },
  { label: "Demise of Immediate Family", value: "OS" },
  { label: "Demise of Family", value: "OS" },
  { label: "Wedding", value: "OS" },
];

export const SpecialLeaveTypeOption = [
  {
    id: "1da74498-1637-11e9-9e53-5254006876cf",
    label: "Convocation",
    value: "CO",
  },
  {
    id: "287596e0-1637-11e9-9e53-5254006876cf",
    label: "Paternity Leave",
    value: "PL",
  },
  {
    id: "093f0e92-1637-11e9-9e53-5254006876cf",
    label: "Demise of Immediate Family",
    value: "IF",
  },
  {
    id: "0ddd720c-1637-11e9-9e53-5254006876cf",
    label: "Demise of Family",
    value: "F",
  },
  {
    id: "185632cb-1637-11e9-9e53-5254006876cf",
    label: "Wedding",
    value: "W",
  },
];

export const ActionHR = [
  { label: "Reviewed", value: "reviewedAction" },
  { label: "Send Out Notification", value: "sendNotificationAction" },
];

export const Authentication = [
  { label: "NO PASSWORD", value: -1 },
  { label: "LOCAL PASSWORD", value: 1 },
  { label: "ACTIVE DIRECTORY", value: 3 }
]

export const Gender = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
]

export const WorkingSchedule = [
  { label: "HQ OFFICE (Alt. Saturday Halfday)", value: "HQ OFFICE", id: "H" },
  { label: "SITE (Full Saturday)", value: "SITE", id: "S" },
  { label: "SITE OFFICE (Half Saturday)", value: "SITE OFFICE", id: "SG" },
]

export const EmployeeCategory = [
  { label: "DIRECTOR/HOD", value: "director", id: "D" },
  { label: "MANAGER", value: "manager", id: "M" },
  { label: "OFFICER/SUPERVISOR TYPE 500", value: "officer500", id: "O" },
  { label: "OFFICER/SUPERVISOR TYPE 360", value: "officer360", id: "Q" },
  { label: "SLO", value: "slo", id: "S" },
]

export const ServiceLength = [
  { label: "<5 YEARS", value: "<5YRS>", id: "J" },
  { label: "5 TO 15 YEARS", value: "5~15YRS", id: "K" },
  { label: "> 15 YEARS", value: ">15YRS", id: "L" },
  { label: "UNDER PROBATION", value: "probation", id: "P" },
  { label: "UNDER EXTEND PROBATION", value: "extendProbation", id: "Q" },
  { label: "UNKNOWN", value: "unknown", id: "UK" },
]

export const leaveValuesByCategory = {
  director: {
    annual: "18",
    hospitalization: "48",
    maternity: "60",
    medical: "500",
    sick: "12",
  },
  manager: {
    annual: "16",
    hospitalization: "48",
    maternity: "60",
    medical: "500",
    sick: "12",
  },
  officer500: {
    annual: "14",
    hospitalization: "48",
    maternity: "60",
    medical: "500",
    sick: "12",
  },
  officer360: {
    annual: "14",
    hospitalization: "48",
    maternity: "60",
    medical: "360",
    sick: "14",
  },
  slo: {
    annual: "8",
    hospitalization: "46",
    maternity: "60",
    medical: "360",
    sick: "18",
  },
};




