// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ellipsis {
    max-width: 100px; /* Set your desired width */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.custom-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    z-index: 1;
}

.custom-tooltip::after {
    content: attr(data-toggle);
    position: absolute;
    top: -30px; /* Position above the element */
    left: 50%;
    transform: translateX(-50%);
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0; /* Start hidden */
    visibility: hidden; /* Start hidden */
    z-index: 1000;
    transition: opacity 0.3s ease;
}

.custom-tooltip:hover::after {
    opacity: 1;
    visibility: visible;
}`, "",{"version":3,"sources":["webpack://./src/1.Components/LeaveReport/LeaveReport.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB,EAAE,2BAA2B;IAC7C,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;IACf,UAAU;AACd;;AAEA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,UAAU,EAAE,+BAA+B;IAC3C,SAAS;IACT,2BAA2B;IAC3B,sBAAsB,EAAE,qBAAqB;IAC7C,WAAW,EAAE,eAAe;IAC5B,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,UAAU,EAAE,iBAAiB;IAC7B,kBAAkB,EAAE,iBAAiB;IACrC,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,UAAU;IACV,mBAAmB;AACvB","sourcesContent":[".ellipsis {\r\n    max-width: 100px; /* Set your desired width */\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n    white-space: nowrap;\r\n}\r\n\r\n.custom-tooltip {\r\n    position: relative;\r\n    display: inline-block;\r\n    cursor: pointer;\r\n    z-index: 1;\r\n}\r\n\r\n.custom-tooltip::after {\r\n    content: attr(data-toggle);\r\n    position: absolute;\r\n    top: -30px; /* Position above the element */\r\n    left: 50%;\r\n    transform: translateX(-50%);\r\n    background-color: #000; /* Black background */\r\n    color: #fff; /* White text */\r\n    padding: 5px;\r\n    border-radius: 5px;\r\n    white-space: nowrap;\r\n    opacity: 0; /* Start hidden */\r\n    visibility: hidden; /* Start hidden */\r\n    z-index: 1000;\r\n    transition: opacity 0.3s ease;\r\n}\r\n\r\n.custom-tooltip:hover::after {\r\n    opacity: 1;\r\n    visibility: visible;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
