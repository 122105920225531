import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../4.Utils/apiServices";
import moment from "moment/moment";

const namespace = "useraccess";

// Get User Access
export const getUserAccess = createAsyncThunk(
  `${namespace}/lists`,
  async (body, { rejectWithValue }) => {
    const api = "get_user_access";
    const opt = {
      body: body,
    };
    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Get User Access Modules
export const getUserAccessModules = createAsyncThunk(
  `${namespace}/modulelists`,
  async (body, { rejectWithValue }) => {
    const api = "get_user_access_modules";
    const opt = {
      body: body,
    };
    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Update
export const updateUserAccessModules = createAsyncThunk(
  `${namespace}/update/modulelists`,
  async (body, { rejectWithValue }) => {
    const api = "update_user_access";
    const opt = {
      body: body,
    };
    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
    loading: false,
    error: false,
    userAccessList: [],
    moduleList: [],
    user: {
      employee: {
        id: "",
        fullname: ""
      },
      modules: []
    },
    search: {searchText: ""}
};

const UserAccessSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
      // update entity
      updateUserAccess(state, action) {
        state.user = action.payload;
      },

      updateSearch(state, action) {
        state.search = action.payload;
      },

      resetUserAccess(state) {
        return {
          ...state,
          user: initialState.user
        };
      },

      resetSearchText(state) {
        return {
          ...state,
          search: initialState.search
        };
      },
    },
    extraReducers: (builder) => {
      builder
      .addCase(getUserAccess.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUserAccess.fulfilled, (state, action) => {
        state.loading = false;
        state.userAccessList = action.payload;
      })
      .addCase(getUserAccess.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getUserAccessModules.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUserAccessModules.fulfilled, (state, action) => {
        state.loading = false;
        state.moduleList = action.payload;
      })
      .addCase(getUserAccessModules.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(updateUserAccessModules.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateUserAccessModules.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateUserAccessModules.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    }
});

export const { updateUserAccess, resetUserAccess, updateSearch, resetSearchText } = UserAccessSlice.actions;

export default UserAccessSlice.reducer;