import React from 'react';
import Form from 'react-bootstrap/Form';

const ContextMenu = ({ xPos, yPos, options, onOptionChange, onClose }) => {

    function getLabel(key) {
        // Define specific keys that need special handling
        const specialKeys = ["cr", "cf", "dr"];

        // Check if the key is 'dateApplied' for a special label
        if (key === "dateApplied") {
            return "Date Applied";
        }

        if (key === "dateRange") {
            return "Date Range";
        }
      
        // Check if the key is one of the special keys
        if (specialKeys.includes(key)) {
          // Return the uppercase version of the entire key
          return key.toUpperCase();
        } else {
          // Otherwise, return the default label with the first letter capitalized
          return key.charAt(0).toUpperCase() + key.slice(1);
        }
    }

    return (
        <div 
            style={{ 
                position: 'absolute', 
                top: yPos, 
                left: xPos, 
                backgroundColor: 'white', 
                border: '1px solid #ccc', 
                borderRadius: '4px', 
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)', 
                padding: '10px',
                zIndex: 1000
            }} 
            onMouseLeave={onClose}
        >
            {Object.keys(options).map((key) => (
                <Form.Check 
                    key={key} 
                    type="checkbox" 
                    label={getLabel(key)} 
                    name={key}
                    checked={options[key]} 
                    onChange={(e) => onOptionChange(e)}
                    style={{ marginBottom: '5px' }}
                />
            ))}
        </div>
    );
};

export default ContextMenu;

