import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../4.Utils/apiServices";

const namespace = "Login";

export const postLogin = createAsyncThunk(
	`${namespace}/postLogin`,
	async (body, { rejectWithValue }) => {
		const api = "login";
		const opt = {
			body: body,
		};

		try {
			const response = await apiServices(api, opt);
			return response.data;
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const getRoles = createAsyncThunk(
	`${namespace}/getRoles`,
	async (body, { rejectWithValue }) => {
		const api = "get_roles";
		const opt = {
			body: body,
		};

		try {
			const response = await apiServices(api, opt);
			return response.data;
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

const initialState = {
	//entity
	login: {
		username: "",
		password: "",
	},
	loading: false,
	error: false,
};

const LoginSlice = createSlice({
	name: "Login",
	initialState,
	reducers: {
		updateLogin(state, action) {
			return { ...state, login: action.payload };
		},
	},
	extraReducers: {
		[postLogin.pending]: (state, action) => {
			state.loading = true;
		},

		[postLogin.fulfilled]: (state, action) => {
			state.loading = false;

			const currentUser = {
				id: action.payload?.id,
				fullname: action.payload?.fullname
			};

			localStorage.setItem("current_user", JSON.stringify(currentUser));
			// localStorage.setItem("roles", JSON.stringify(action.payload?.roles));
			localStorage.setItem("token", action.payload?.token);
		},

		[postLogin.rejected]: (state, action) => {
			state.error = true;
		},

		[getRoles.pending]: (state, action) => {
			state.loading = true;
		},

		[getRoles.fulfilled]: (state, action) => {
			state.loading = false;

			localStorage.setItem("roles", JSON.stringify(action.payload));
		},

		[getRoles.rejected]: (state, action) => {
			state.error = true;
		},
	},
});

export const { updateLogin } = LoginSlice.actions;
export default LoginSlice.reducer;
