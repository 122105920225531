import { useNavigate } from "react-router-dom";
import { Container, Card, Table, ButtonGroup, Button, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faReply, faClose, faMinus, faHourglass, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { ThreeDots } from 'react-loader-spinner';
import { getLeaveApprovalListByUserID, updateCurrentPage } from "../../3.Store/LeaveApprovalSlice";

const LeaveApprovalList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("current_user"));
  const id = user?.id;
  const lists = useSelector(state => state.LeaveApprovalSlice.approvalLists);
  const currentPage = useSelector(state => state.LeaveApprovalSlice.currentPage);
  const pageSize = 20; // Number of items per page
  const totalPages = Math.ceil(lists.length / pageSize);

  console.log(lists)

  useEffect(() => {
    if(id) {
      setLoading(true);
      dispatch(getLeaveApprovalListByUserID({id}))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    };
  }, [id])

  const handleBtnBack = () => {
    navigate(-1);
  };

  const handleClickView = (id) => {
    navigate(`/LeaveApprovalForm/${id}`);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      dispatch(updateCurrentPage(newPage));
    };
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      dispatch(updateCurrentPage(newPage));
    };
  };

  const renderStaffList = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, lists.length);

    return lists
      .slice(startIndex, endIndex)
      .map(list => (
        <tr key={list.id} style={{ cursor: "pointer" }}>
            <td onClick={() => handleClickView(list.id)}>{moment(list.created).format("YYYY-MM-DD")}</td>
            <td onClick={() => handleClickView(list.id)}>{list.employee.fullname}</td>
            <td onClick={() => handleClickView(list.id)}>{list.leave_type.description}</td>
            <td onClick={() => handleClickView(list.id)}>{list.description}</td>
            <td onClick={() => handleClickView(list.id)}>{list.reason_for_application}</td>
            <td onClick={() => handleClickView(list.id)}>
              {list.status.short_description === "APPROVED" && (
                <FontAwesomeIcon icon={faCheck} color="#28A744" />
              )}
              {list.status.short_description === "WITHDRAWN" && (
                <FontAwesomeIcon icon={faReply} color="#28A744" />
              )}
              {list.status.short_description === "REJECTED-SUPERVISOR" && (
                <FontAwesomeIcon icon={faClose} color="#DC3545" />
              )}
              {list.status.short_description === "DELETED" && (
                <FontAwesomeIcon icon={faMinus} color="#FFC107" />
              )}
              {list.status.short_description === "PENDING" && (
                <FontAwesomeIcon icon={faHourglass} color="#FFC107" />
              )}
            </td>
        </tr>
      ));
  };

  return (
    <Container>
      {loading && (
        <ThreeDots
        visible={loading}
        height="80"
        width="80"
        color="#4fa94d"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
        }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Leave Approval</h1>
        <h1 className="MiniTitleHeader">List</h1>

        <Col style={{ marginLeft: "auto" }}>
          <Form.Label className="FormLabel">{`${currentPage} of ${totalPages}`}</Form.Label> &ensp;
          {currentPage > 1 ? (
            <Form.Label className="FormLabel" onClick={handlePreviousPage}>
              <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#ffffff" }} />
            </Form.Label>
          ) : (
            <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Form.Label>
          )}
          &ensp;
          {currentPage < totalPages ? (
            <Form.Label className="FormLabel" onClick={handleNextPage}>
              <FontAwesomeIcon icon={faChevronRight} style={{ color: "#ffffff" }} />
            </Form.Label>
          ) : (
            <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
              <FontAwesomeIcon icon={faChevronRight} />
            </Form.Label>
          )}
        </Col>

        <Table>
          <thead>
            <tr>
              <th>Date Applied</th>
              <th>Employee</th>
              <th>Type</th>
              <th>Description</th>
              <th>Reason</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {lists.length > 0 ? (
              renderStaffList()
            ) : (
              <tr>
                <td colSpan="6">No results found</td>
              </tr>
            )}
          </tbody>
        </Table>

        <table>
          <tbody>
            <tr>
              <td className="FormLabel">
                <FontAwesomeIcon icon={faCheck} color="#28A744" />
                &nbsp;Approved
                &ensp;&ensp;&ensp;&ensp;&ensp;
                <FontAwesomeIcon icon={faReply} color="#28A744" />
                &nbsp;Withdrawn
                &ensp;&ensp;&ensp;&ensp;&ensp;
                <FontAwesomeIcon icon={faClose} color="#DC3545" />
                &nbsp;Rejected
                &ensp;&ensp;&ensp;&ensp;&ensp;
                <FontAwesomeIcon icon={faMinus} color="#FFC107" />
                &nbsp;Deleted
                &ensp;&ensp;&ensp;&ensp;&ensp;
                <FontAwesomeIcon icon={faHourglass} color="#FFC107" />
                &nbsp;Pending
              </td>
            </tr>
          </tbody>
        </table>

        <Col style={{ marginLeft: "auto" }}>
          <Form.Label className="FormLabel">{`${currentPage} of ${totalPages}`}</Form.Label> &ensp;
          {currentPage > 1 ? (
            <Form.Label className="FormLabel" onClick={handlePreviousPage}>
              <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#ffffff" }} />
            </Form.Label>
          ) : (
            <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Form.Label>
          )}
          &ensp;
          {currentPage < totalPages ? (
            <Form.Label className="FormLabel" onClick={handleNextPage}>
              <FontAwesomeIcon icon={faChevronRight} style={{ color: "#ffffff" }} />
            </Form.Label>
          ) : (
            <Form.Label className="FormLabel" style={{ color: "#cccccc" }}>
              <FontAwesomeIcon icon={faChevronRight} />
            </Form.Label>
          )}
        </Col>

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};
export default LeaveApprovalList;
