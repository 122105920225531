import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiServices } from "../4.Utils/apiServices";

const namespace="staff/on/leave";

//List All Staff On Leave
export const listStaffOnList = createAsyncThunk(
    `${namespace}/lists`,
    async (body, { rejectWithValue }) => {
      const api = "list_staffOnLeave";
      const replace = { 
        search: "{page}", 
        replace: body.page
    };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Total Count
export const CountStaffOnList = createAsyncThunk(
    `${namespace}/count`,
    async (body, { rejectWithValue }) => {
      const api = "list_staffOnLeave_count";
      const opt = {
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

const initialState = {
    search: {
        searchText : "",
    },
    loading: false,
    error: false,
    currentPage: 1,
    totalPages: "",
    lists: [],
}

const StaffOnLeaveSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        // update entity
        updateSearch(state, action) {
            state.search = action.payload;
        },
        updateCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(listStaffOnList.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(listStaffOnList.fulfilled, (state, action) => {
            state.loading = false;
            state.lists = action.payload;
        })
        .addCase(listStaffOnList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        .addCase(CountStaffOnList.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(CountStaffOnList.fulfilled, (state, action) => {
            state.loading = false;
            state.totalPages = action.payload;
        })
        .addCase(CountStaffOnList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
    }
});

export const { updateSearch, updateCurrentPage } = StaffOnLeaveSlice.actions;

export default StaffOnLeaveSlice.reducer;