import Select from "react-select";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import {
  Container,
  Card,
  Form,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import {
  updateLeaveAward,
  addLeaveAwards,
  updateList,
  resetList,
  getEmployees,
  getEmployeeByID,
  getALeaveAward,
} from "../../3.Store/LeaveAwardsSlice";
import { ThreeDots } from 'react-loader-spinner';

const LeaveAwardForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const awardList = useSelector(state => state.LeaveAwardsSlice.awardList) || {};
  const employeeName = useSelector(state => state.LeaveAwardsSlice.employees) || [];
  const employeeDetail = useSelector(state => state.LeaveAwardsSlice.employeeDetail) || {};
  const [loading, setLoading] = useState(false);
  const { employee, created, advanced, annual, hospitalization, maternity, medical, sick, special, effective_date, year } = awardList;
  
  const getSearchParamsFromQuery = () => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const years = params.get('years');
    return { page, years };
  };

  const { page, years } = getSearchParamsFromQuery();

  useEffect(() => {
    setLoading(true);
    dispatch(getEmployees())
      .then(() => {
        if (id) {
          dispatch(getALeaveAward({id})).finally(() => {
            setLoading(false);
          });
        } else {
          dispatch(resetList());
          setLoading(false);
        }
      })
      .catch(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    if (!id && employeeDetail) {
      const entity = { 
        ...awardList,
        employee: employeeDetail
      };
      dispatch(updateList(entity));
    }
  }, [employeeDetail, id]);

  const handleDropdownChange = (selectedOption) => {
    dispatch(getEmployeeByID(selectedOption.id));
  };

  const handleBtnSave = () => {
    const entity = { ...awardList };
    setLoading(true);
    dispatch(addLeaveAwards(entity))
      .unwrap()
      .then(() => {
        Swal.fire("Record Added", "", "success").then(() => {
          navigate(`/LeaveAwardList`);
        });
      })
      .catch((err) => {
        Swal.fire("Error", err.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };  

  const handleUpdate = (e) => {
    const entity = { ...awardList, [e.target.name]: e.target.value };
    dispatch(updateList(entity));
  };

  const handleBtnUpdate = () => {
    const entity = { ...awardList };
    setLoading(true);
    dispatch(updateLeaveAward(entity))
      .unwrap()
      .then(() => {
        Swal.fire("Record Updated", "", "success").then(() => {
          navigate(`/LeaveAwardForm/${id}`);
        });
      })
      .catch((err) => {
        Swal.fire("Error", err.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const handleBtnBack = () => {
    dispatch(resetList());
    // navigate("/LeaveAwardList");
    navigate(`/LeaveAwardList?page=${page}&years=${years}`);
  };

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Leave Award 2024</h1>
        <h1 className="MiniTitleHeader">Employee</h1>

        <Row className="my-3">
          <Col md={6} sm={6}>
            <Row>
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">FullName</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                {!id ? (
                  <Select
                    name="employee"
                    onChange={handleDropdownChange}
                    options={employeeName.map(employee => ({ label: employee.fullname, value: employee.fullname, id: employee.id }))}
                    placeholder="Select Employee"
                  />
                ) : (
                  <Form.Control value={employee?.fullname || ""} readOnly/>
                )}
              </Col>
            </Row>
          </Col>
          <Col md={6} sm={6}>
            <Row>
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Date Joined</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={(employee?.date_joined) ? moment(employee.date_joined).format("YYYY-MM-DD") : ""} readOnly />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="my-3">
          <Col md={6} sm={6}>
            <Row>
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Staff ID</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={employee?.sid || ""} readOnly/>
              </Col>
            </Row>
          </Col>
          <Col md={6} sm={6}>
            <Row>
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Company</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={employee?.company?.description || ""} readOnly />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="my-3">
          <Col md={6} sm={6}>
            <Row>
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Employee Category</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={employee?.employee_category?.description || ""} readOnly />
              </Col>
            </Row>
          </Col>
          <Col md={6} sm={6}>
            <Row>
              <Col md={4} sm={4}>
                <Form.Label className="FormLabel">Service Length</Form.Label>
              </Col>
              <Col md={8} sm={8}>
                <Form.Control value={employee?.service_length?.description || ""} readOnly/>
              </Col>
            </Row>
          </Col>
        </Row>

        {!id && (
          <Row className="my-3">
            <Col md={4} sm={4}>
              <Form.Label className="FormLabel">Year</Form.Label>
            </Col>
            <Col md={8} sm={8}>
              <Form.Control name="year" value={year || ""} onChange={handleUpdate} />
            </Col>
          </Row>
        )}
        
        <Row className="my-3">
          <Col md={4} sm={4}>
            <Form.Label className="FormLabel">Effective Date</Form.Label>
          </Col>
          <Col md={8} sm={8}>
            {!id ? (
              <Form.Control type="date" name="effective_date" value={moment(effective_date).format("YYYY-MM-DD")} onChange={handleUpdate}/>
            ) : (
              <Form.Control value={moment(created).format("YYYY-MM-DD")} readOnly />
            )}
          </Col>
        </Row>

        {[
          { label: "Annual", name: "annual", value: annual },
          { label: "Advanced", name: "advanced", value: advanced },
          { label: "Sick", name: "sick", value: sick },
          { label: "Hospitalization", name: "hospitalization", value: hospitalization },
          { label: "Maternity", name: "maternity", value: maternity },
          { label: "Special", name: "special", value: special },
          { label: "Medical", name: "medical", value: medical },
        ].map((field, index) => (
          <Row className="my-3" key={index}>
            <Col md={4} sm={4}>
              <Form.Label className="FormLabel">{field.label}</Form.Label>
            </Col>
            <Col md={8} sm={8}>
              <Form.Control name={field.name} value={field.value} onChange={handleUpdate} />
            </Col>
          </Row>
        ))}

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>
          &nbsp; &nbsp;
          {!id ? (
            <ButtonGroup className="my-2">
              <Button className="Button" onClick={handleBtnSave}>
                Save
              </Button>
            </ButtonGroup>
          ):(
            <ButtonGroup className="my-2">
              <Button className="Button" onClick={handleBtnUpdate}>
                Update
              </Button>
            </ButtonGroup>
          )}
        </div>
      </Card>
    </Container>
  );
};

export default LeaveAwardForm;