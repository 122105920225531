import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../4.Utils/apiServices";
import moment from "moment/moment";

const namespace = "leaveawards";

//Search Leave Awards by employee name
export const postsearchText = createAsyncThunk(
    `${namespace}/search`,
    async (body, { rejectWithValue }) => {
        const api = "search_leave_awards";
        const opt = {
            body: body,
        };
    
        try {
            const response = await apiServices(api, opt);
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

//List of Leave Awards by Year + Pagination
export const getLeaveAwardsYearPagination = createAsyncThunk(
    `${namespace}/lists`,
    async (body, { rejectWithValue }) => {
      const api = "get_leave_awards_year+pagination";
      const replace = {
        search: "{year}",
        replace: body.year,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Update Leave Award Detail
export const updateLeaveAward = createAsyncThunk(
    `${namespace}/update`,
    async (body, { rejectWithValue }) => {
      const api = "update_leave_awards";
      const replace = {
        search: "{leave_award_id}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Add Leave Award
export const addLeaveAwards = createAsyncThunk(
    `${namespace}/add`,
    async (body, { rejectWithValue }) => {
      const api = "add_leave_awards";
      const opt = {
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Get All Employees Name
export const getEmployees = createAsyncThunk(
    `${namespace}/employee`,
    async (body, { rejectWithValue }) => {
      const api = "get_employees_lists";
      const opt = {
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Get Awards Lists
export const getALeaveAward = createAsyncThunk(
    `${namespace}/get`,
    async (body, { rejectWithValue }) => {
      const api = "list_a_leave_award";
      const replace = {
        search: "{leave_award_id}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

const initialState = {
    search: {
        searchText : "",
        choice: 1, //employee name
    },
    awardList: {
        id: "",
        effective_date : moment(new Date()).format("YYYY-MM-DD"),
        year : new Date().getFullYear(),
        employee : {
            id: ""
        },
        status : {
            id : 1
        },
        advanced: 0,
        annual: 0,
        hospitalization: 0,
        maternity: 0,
        medical: 0,
        sick: 0,
        special: 0
    },
    loading: false,
    error: false,
    currentPage: 1,
    currentPageCount: 0,
    awardLists: [],
    employees: [],
    employeeDetail: null
}

const LeaveAwardsSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        // update entity
        updateSearch(state, action) {
            state.search = action.payload;
        },

        // update entity
        updateList(state, action) {
            return { ...state, awardList: action.payload };
        },

        resetList(state) {
            return {
              ...state,
              awardList: initialState.awardList,
              employeeDetail: initialState.awardList
            };
        },

        getEmployeeByID(state, action) {
            return {
                ...state,
                employeeDetail: state.employees.find((employeeDetail) => employeeDetail.id === action.payload),
            };
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getLeaveAwardsYearPagination.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(getLeaveAwardsYearPagination.fulfilled, (state, action) => {
            state.loading = false;
            state.awardLists = action.payload;
        })
        .addCase(getLeaveAwardsYearPagination.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //get a leave awards
        .addCase(getALeaveAward.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(getALeaveAward.fulfilled, (state, action) => {
            state.loading = false;
            state.awardList = action.payload;
        })
        .addCase(getALeaveAward.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        // post search
        .addCase(postsearchText.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(postsearchText.fulfilled, (state, action) => {
            state.loading = false; // Set loading to false
            state.awardLists = action.payload;
        })
        .addCase(postsearchText.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
            console.error(action.payload);
        })

        //put
        .addCase(updateLeaveAward.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(updateLeaveAward.fulfilled, (state, action) => {
            state.loading = false;
        })
        .addCase(updateLeaveAward.rejected, (state, action) => {
            state.error = true;
            console.error(action.payload);
        })

        //Get Employees
        .addCase(getEmployees.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(getEmployees.fulfilled, (state, action) => {
            state.loading = false; // Set loading to false
            state.employees = action.payload;
        })
        .addCase(getEmployees.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
            console.error(action.payload);
        })

        //add
        .addCase(addLeaveAwards.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(addLeaveAwards.fulfilled, (state, action) => {
            state.loading = false;
        })
        .addCase(addLeaveAwards.rejected, (state, action) => {
            state.error = true;
            console.error(action.payload);
        })
    }
});

export const { updateSearch, resetList, updateList, getEmployeeByID } = LeaveAwardsSlice.actions;

export default LeaveAwardsSlice.reducer;