import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { triggerBase64Download } from 'common-base64-downloader-react';
import {
  Container,
  Card,
  Form,
  Table,
  ButtonGroup,
  Button,
  InputGroup,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faScissors,
  faGenderless,
  faGraduationCap,
  faUnderline,
  faChild,
  faBed,
  faAmbulance,
  faMoneyBill,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { 
  updateSearch,
  searchLeaveSummary,
  getLeaveSummaryEmployeeYear,
  getLeaveSummaryPageCount,
  printExcel
} from '../../3.Store/LeaveSummarySlice';
import { ThreeDots } from 'react-loader-spinner';

const LeaveSummaryList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employee = JSON.parse(localStorage.getItem("current_user"));
  const userId = employee?.id;
  const searchResult = useSelector(state => state.LeaveSummarySlice.summaries);
  const search = useSelector((state) => state.LeaveSummarySlice.search);
  const { searchText, year } = search;
  const [loading, setLoading] = useState(false);

  const roles = JSON.parse(localStorage.getItem("roles"));
  const isHR = roles.includes("HR-L-REC") || roles.includes("SU-L-APPROVAL") || roles.includes("SU-M-BENEFIT");

  useEffect(() => {
    dispatch(getLeaveSummaryPageCount());
    dispatch(getLeaveSummaryEmployeeYear({ id: userId, year }));
  }, [userId]);

  const handleSearch = (e) => {
    const entity = {...search, searchText: e.target.value}
    console.log(entity);
    dispatch(updateSearch(entity));
  };

  const handleBtnSearch = () => {
    setLoading(true);
    const entity = { ...search };
    dispatch(searchLeaveSummary(entity))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleYear = (e) => {
    setLoading(true);
    if(isHR) {
      const entity = {...search, year: e.target.value}
      dispatch(updateSearch(entity));
      dispatch(searchLeaveSummary(entity))
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    else {
      const entity = {...search, 
        searchText: employee.fullname,
        year: e.target.value
      }
      dispatch(updateSearch(entity));
      dispatch(searchLeaveSummary(entity))
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleBtnDownload = () => {
    setLoading(true);

    if(!isHR) {
      const entity = {...search, searchText: employee.fullname}
      console.log(entity)
    }

    const report = dispatch(printExcel({searchText, year}));
    
    report.then((data) => {
        try {
            // Ensure the payload is a string
            if (typeof data.payload !== 'string') {
                throw new Error('Invalid payload format');
            }
            // Decode the base64-encoded data
            const base64EXCEL = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.payload}`;
            // Trigger download
            triggerBase64Download(base64EXCEL, "Leave Summary (" + year + ") - " + employee.fullname);
        } catch (error) {
            console.error('Error handling download:', error);
        }
        finally {
          setLoading(false); // Set loading to false after data fetching completes
        }
    }).catch((error) => {
        console.error('Error fetching report data:', error);
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleBtnSearch();
    }
  };

  const handleBtnBack = () => {
    navigate("/");
  };

  // Function to generate options for the dropdown menu
  const renderYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= currentYear - 5; year--) {
        years.push(
            <option key={year} value={year}>{year}</option>
        );
    }
    return years;
  };

  return (
    <Container>
      {loading && (
        <ThreeDots
          visible={loading}
          height="80"
          width="80"
          color="#4fa94d"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '9999',
          }}
        />
      )}
      <Card className="CardContent">
        <h1 className="TitleHeader">Leave Summary</h1>

        {isHR && (
        <InputGroup className="mb-3">
          <Form.Control placeholder="Search" value={searchText} onChange={handleSearch} onKeyPress={handleKeyPress}/>
          <Button className="SearchIconBtn" onClick={handleBtnSearch}>
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroup>
        )}
        
        <Col md={1} sm={1} style={{ marginLeft: "auto" }}>
          {/* Dropdown menu for selecting the year */}
          <Form.Select value={year} onChange={handleYear}>
              {renderYearOptions()}
          </Form.Select>
        </Col>

        <Table className="mt-3">
          <thead>
            <tr>
              <th>Employee</th>
              <th>OB</th>
              <th>LA</th>
              <th>1</th>
              <th>2</th>
              <th>3</th>
              <th>4</th>
              <th>5</th>
              <th>6</th>
              <th>7</th>
              <th>8</th>
              <th>9</th>
              <th>10</th>
              <th>11</th>
              <th>12</th>
              <th>TTL</th>
              <th>CF</th>
              <th>
                <FontAwesomeIcon icon={faScissors} title="Prorated" />
              </th>
              <th>
                <FontAwesomeIcon icon={faGenderless} title="Other Leave" />
              </th>
              <th>
                <FontAwesomeIcon icon={faGraduationCap} title="Special Leave" />
              </th>
              <th>
                <FontAwesomeIcon icon={faUnderline} title="Unpaid Leave" />
              </th>
              <th>
                <FontAwesomeIcon icon={faChild} title="Maternity Leave" />
              </th>
              <th>
                <FontAwesomeIcon icon={faBed} title="Sick Leave" />
              </th>
              <th>
                <FontAwesomeIcon icon={faAmbulance} title="Hospitalization" />
              </th>
              <th>
                <FontAwesomeIcon icon={faMoneyBill} title="Medical Taken" />
              </th>
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {searchResult.length > 0 ? (
                searchResult.map(employee => (
                  <tr key={employee.employee.id} style={{ cursor: "pointer" }}>
                    <td>{employee.employee.fullname}</td>
                    <td>{employee.ob}</td>
                    <td>{employee.entitled}</td>
                    <td>{employee.jan}</td>
                    <td>{employee.feb}</td>
                    <td>{employee.mar}</td>
                    <td>{employee.apr}</td>
                    <td>{employee.may}</td>
                    <td>{employee.jun}</td>
                    <td>{employee.jul}</td>
                    <td>{employee.aug}</td>
                    <td>{employee.sep}</td>
                    <td>{employee.oct}</td>
                    <td>{employee.nov}</td>
                    <td>{employee.dec}</td>
                    <td>{employee.utilized}</td>
                    <td>{employee.cf}</td>
                    <td>{employee.prorated}</td>
                    <td>{employee.other}</td>
                    <td>{employee.special}</td>
                    <td>{employee.unpaid}</td>
                    <td>{employee.maternity}</td>
                    <td>{employee.sick}</td>
                    <td>{employee.hospitalization}</td>
                    <td>{employee.medicalbenefit}</td>
                  </tr>
                ))
                ) : (
                <tr>
                  <td colSpan="25">No results found</td>
                </tr>
              )}
            </tbody>
          )}
        </Table>

        <div className="d-flex justify-content-between">
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnBack}>
              Back
            </Button>
          </ButtonGroup>
          &nbsp; &nbsp;
          <ButtonGroup className="my-2">
            <Button className="Button" onClick={handleBtnDownload}>
              Download
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </Container>
  );
};
export default LeaveSummaryList;
