import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../4.Utils/apiServices";

const namespace = "leave/withdrawals";

//List of Leave Withdrawals
export const getListsOfLeaveWithdrawal = createAsyncThunk(
    `${namespace}/lists`,
    async (body, { rejectWithValue }) => {
        const api = "get_withdrawal_lists";
        const opt = {
            body: body,
        };
        try {
        const response = await apiServices(api, opt);
        return response.data;
        } catch (err) {
        return rejectWithValue(err);
        }
    }
);

//Leave Withdrawals Review
export const reviewWithdrawal = createAsyncThunk(
    `${namespace}/review`,
    async (body, { rejectWithValue }) => {
      const api = "review_a_withdrawal_list";
      const replace = {
        search: "{leave_withdrawal_application_id}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

//Medical Cert
export const getMedicalCert = createAsyncThunk(
    `${namespace}/medicalCert`,
    async (body, { rejectWithValue }) => {
      const api = "get_medical_certificate_attachment";
      const replace = {
        search: "{id}",
        replace: body.id,
      };
      const opt = {
        replace: replace,
        body: body,
      };
  
      try {
        const response = await apiServices(api, opt);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
);

const initialState = {
    list: {
        id: "",
        remark_withdrawal : "",
        status: {
            id: ""
        }
    },
    loading: false,
    error: false,
    currentPage: 1,
    currentPageCount: 0,
    lists: [],
    cert: [],
    withdrawlist: null // Add withdrawlist property
}

const LeaveWithdrawalSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        // update current page
        updateCurrentPage(state, action) {
            return { ...state, currentPage: action.payload };
        },
  
        // update entity
        updateReview(state, action) {
            state.list = action.payload;
        },

        // reset entity
        resetReview(state) {
            return {
                ...state,
                list: initialState.list,
                withdrawlist: initialState.withdrawlist // Reset withdrawlist
            };
        },

        resetCert(state) {
            return {
              ...state,
              cert: initialState.cert,
            };
        },

        getList(state, action) {
            return {
                ...state,
                withdrawlist: state.lists.find((withdrawlist) => withdrawlist.id == action.payload),
            };
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getListsOfLeaveWithdrawal.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(getListsOfLeaveWithdrawal.fulfilled, (state, action) => {
            state.loading = false;
            state.lists = action.payload;
            console.log(action.payload);
        })
        .addCase(getListsOfLeaveWithdrawal.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //Put
        .addCase(reviewWithdrawal.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(reviewWithdrawal.fulfilled, (state, action) => {
            state.loading = false;
        })
        .addCase(reviewWithdrawal.rejected, (state, action) => {
            state.error = true;
            console.error(action.payload);
        })

        .addCase(getMedicalCert.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(getMedicalCert.fulfilled, (state, action) => {
            state.loading = false;
            state.cert = action.payload;
            console.log(action.payload);
        })
        .addCase(getMedicalCert.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

export const { updateReview, getList, resetReview, updateCurrentPage, resetCert } = LeaveWithdrawalSlice.actions;

export default LeaveWithdrawalSlice.reducer;