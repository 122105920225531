import React, { useState } from 'react';
import { Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import './HRLeaveReport.css';
import { useNavigate } from 'react-router-dom';
import ContextMenu from './ContextMenu';

function LeaveTable({ title, leaveData = [], year, loading }) {
    const navigate = useNavigate();
    const [selectedColumns, setSelectedColumns] = useState({
        dateApplied: true,
        dateRange: true,
        item: true,
        type: title === 'Special Leave',  // Automatically select 'type' column for 'Special Leave'
        reason: true,
        cr: true,
        dr: true,
        cf: true,
    });

    const [contextMenu, setContextMenu] = useState(null);

    const handleColumnChange = (event) => {
        const { name, checked } = event.target;
        const columnCount = Object.values(selectedColumns).filter(Boolean).length;
    
        // Prevent deselecting the last remaining column
        if (columnCount === 1 && !checked) return;
    
        setSelectedColumns((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
    };    

    const handleRightClick = (event, columnKey) => {
        event.preventDefault();
        setContextMenu({
            xPos: event.pageX,
            yPos: event.pageY,
            columnKey,
        });
    };

    const closeContextMenu = () => {
        setContextMenu(null);
    };

    function formatDate(dateString) {
        if (!dateString) {
            return '';
        }
        return moment(dateString).format('YYYY-MM-DD');
    }

    // Calculate total DR
    const totalDR = Array.isArray(leaveData)
        ? leaveData.reduce((total, item) => {
            // Check if item is "LEAVE WITHDRAWN"
            if (item.leave_move_record.leave_movement_type?.description === 'LEAVE WITHDRAWN') {
                // Deduct CR value to total if item is "LEAVE WITHDRAWN"
                return total - parseFloat(item.leave_move_record.cr || 0);
            } else {
                // Otherwise, add DR value to total
                return total + parseFloat(item.leave_move_record.dr || 0);
            }
        }, 0).toFixed(1)
        : '0.0';

    const handleViewDetails = (item) => {
        navigate(`/HRLeaveReportView/${item.leave_move_record.ref_id}`);
    };

    const getBackgroundColor = (item) => {
        return item.leave_move_record.leave_movement_type?.id === 'P' ? 'yellow' : 'white';
    };

    const containsHalf = (value) => {
        return value.toString().includes('.5');
    };

    return (
        <div>
            {contextMenu && (
                <ContextMenu
                    xPos={contextMenu.xPos}
                    yPos={contextMenu.yPos}
                    options={selectedColumns}
                    onOptionChange={handleColumnChange}
                    onClose={closeContextMenu}
                />
            )}
            <Table>
                <thead>
                    <tr style={{ cursor: 'pointer' }}>
                        {selectedColumns.dateApplied && (
                            <th style={{width: '9%'}} onContextMenu={(e) => handleRightClick(e, 'dateApplied')}>
                                Date Applied
                            </th>
                        )}
                        {selectedColumns.dateRange && (
                            <th style={{width: '18%'}} onContextMenu={(e) => handleRightClick(e, 'dateRange')}>
                                {title === 'Medical Benefits' ? 'Invoice Date' : 'From - To'}
                            </th>
                        )}
                        {selectedColumns.item && (
                            <th style={{width: '13%'}} onContextMenu={(e) => handleRightClick(e, 'item')}>
                                Item
                            </th>
                        )}
                        {selectedColumns.type && title === 'Special Leave' && (
                            <th className="col-sm-2" onContextMenu={(e) => handleRightClick(e, 'type')}>
                                Type
                            </th>
                        )}
                        {selectedColumns.reason && (
                            <th style={{width: '30%'}} onContextMenu={(e) => handleRightClick(e, 'reason')}>
                                {title === 'Unpaid Leave' ? 'Remark' : 'Reason'}
                            </th>
                        )}
                        {selectedColumns.cr && (
                            <th style={{width: '5%'}} onContextMenu={(e) => handleRightClick(e, 'CR')}>
                                CR
                            </th>
                        )}
                        {selectedColumns.dr && (
                            <th style={{width: '5%'}} onContextMenu={(e) => handleRightClick(e, 'DR')}>
                                DR
                            </th>
                        )}
                        {selectedColumns.cf && (
                            <th style={{width: '5%'}} onContextMenu={(e) => handleRightClick(e, 'CF')}>
                                CF
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {!loading && Array.isArray(leaveData) && leaveData.length > 0 ? (
                        leaveData.map((item, index) => (

                            <tr key={item.leave_move_record.id} style={{ cursor: 'pointer' }}>
                                {selectedColumns.dateApplied && (
                                    <td style={{ backgroundColor: getBackgroundColor(item) }}>
                                        {/* {item.created ? formatDate(item.created) : formatDate(item.leave_move_record?.date?.date)} */}
                                        {title === 'Medical Benefits'
                                            ? (item.created_date ? formatDate(item.created_date) : formatDate(item.leave_move_record?.date?.date))
                                            : (item.created ? formatDate(item.created) : formatDate(item.leave_move_record?.date?.date))
                                        }
                                    </td>
                                )}
                                {selectedColumns.dateRange && (
                                    <td style={{ backgroundColor: getBackgroundColor(item) }}>
                                        {title === 'Medical Benefits'
                                            ? formatDate(item.claim_date) || '-'
                                            : `${formatDate(item.from_date)}${containsHalf(item.leave_move_record.dr) ? ' ' + (item.from_ampm || '') : ''} - ${formatDate(item.to_date)}${containsHalf(item.leave_move_record.dr) ? ' ' + (item.to_ampm || '') : ''}`}
                                    </td>
                                )}
                                {selectedColumns.item && 
                                    <td style={{ backgroundColor: getBackgroundColor(item) }}>
                                        {item.leave_move_record.leave_movement_type?.description}
                                    </td>
                                }
                                {selectedColumns.type && title === 'Special Leave' && 
                                    <td style={{ backgroundColor: getBackgroundColor(item) }}>
                                        {item.special_leave_type}
                                    </td>
                                }
                                {/* {selectedColumns.reason && (
                                    <td className="ellipsis" style={{ backgroundColor: getBackgroundColor(item) }}>
                                        {item.leave_move_record.leave_movement_type?.id === 'P' ? (
                                            // 'Last Day: ' + item.leave_move_record?.employee?.date_left.split('T')[0]
                                            <OverlayTrigger
                                                placement="auto"
                                                overlay={
                                                    <Tooltip style={{ backgroundColor: 'black', color: 'white', width: '200px', whiteSpace: 'normal'}}>
                                                        {'Last Day : ' + item.leave_move_record?.employee?.date_left.split('T')[0]}
                                                    </Tooltip>
                                                }
                                            >
                                                <span>
                                                    {'Last Day: ' + item.leave_move_record?.employee?.date_left.split('T')[0]}
                                                </span>
                                            </OverlayTrigger>
                                        ) : (
                                            // <span data-toggle="tooltip" title={item.reason_for_application}>
                                            //     {item.reason_for_application}
                                            // </span>
                                            <OverlayTrigger
                                                placement="auto"
                                                overlay={
                                                    <Tooltip style={{ backgroundColor: 'black', color: 'white', width: '200px', whiteSpace: 'normal'}}>
                                                        {item.reason_for_application}
                                                    </Tooltip>
                                                }
                                            >
                                                <span>
                                                    {item.reason_for_application}
                                                </span>
                                            </OverlayTrigger>
                                        )}
                                    </td>
                                )} */}
                                {selectedColumns.reason && (
                                    <td className="ellipsis" style={{ backgroundColor: getBackgroundColor(item) }}>
                                        {item.leave_move_record.cf < 0 ? (
                                            <OverlayTrigger
                                                placement="auto"
                                                overlay={
                                                    <Tooltip style={{ backgroundColor: 'black', color: 'white', width: '200px', whiteSpace: 'normal'}}>
                                                        {"Prorated until the end of the year: " + year + "-12-31"}
                                                    </Tooltip>
                                                }
                                            >
                                                <span>
                                                    {"Prorated until the end of the year: " + year + "-12-31"}
                                                </span>
                                            </OverlayTrigger>
                                        ) : (
                                            item.leave_move_record.leave_movement_type?.id === 'P' ? (
                                                <OverlayTrigger
                                                    placement="auto"
                                                    overlay={
                                                        <Tooltip style={{ backgroundColor: 'black', color: 'white', width: '200px', whiteSpace: 'normal'}}>
                                                            {'Last Day : ' + item.leave_move_record?.employee?.date_left.split('T')[0]}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span>
                                                        {'Last Day: ' + item.leave_move_record?.employee?.date_left.split('T')[0]}
                                                    </span>
                                                </OverlayTrigger>
                                            ) : (
                                                <OverlayTrigger
                                                    placement="auto"
                                                    overlay={
                                                        <Tooltip style={{ backgroundColor: 'black', color: 'white', width: '200px', whiteSpace: 'normal'}}>
                                                            {item.reason_for_application}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span>
                                                        {item.reason_for_application}
                                                    </span>
                                                </OverlayTrigger>
                                            )
                                        )}
                                    </td>
                                )}
                                {selectedColumns.cr && 
                                    <td style={{ backgroundColor: getBackgroundColor(item) }}>
                                        {item.leave_move_record.cr}
                                    </td>
                                }
                                {selectedColumns.dr && (
                                    <td
                                        onClick={() => {
                                            if (item.leave_move_record.leave_movement_type?.description === "LEAVE APPLIED") {
                                                handleViewDetails(item);
                                            }
                                        }}
                                        style={{ backgroundColor: getBackgroundColor(item) }}
                                    >
                                        {item.leave_move_record.dr}
                                    </td>
                                )}
                                {selectedColumns.cf && 
                                    <td style={{ backgroundColor: getBackgroundColor(item) }}>
                                        {item.leave_move_record.cf}
                                    </td>
                                }
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={Object.values(selectedColumns).filter(Boolean).length}>No data available</td>
                        </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        {selectedColumns.dateApplied && <td></td>}
                        {selectedColumns.dateRange && <td></td>}
                        {selectedColumns.item && <td></td>}
                        {selectedColumns.type && title === 'Special Leave' && <td></td>}
                        {selectedColumns.reason && <td className='text-end'>Total:</td>}
                        {selectedColumns.cr && <td></td>}
                        {selectedColumns.dr && <td>{totalDR}</td>}
                        {selectedColumns.cf && <td></td>}
                    </tr>
                </tfoot>
            </Table>
        </div>
    );
}

export default LeaveTable;