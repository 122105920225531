import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Container, Card, Button, ButtonGroup, Row, Col, Form } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from "sweetalert2";
import moment from 'moment';
import { ThreeDots } from 'react-loader-spinner';
import { 
    getHoliday,
    getHolidays, 
    resetHoliday, 
    updateHoliday, 
    putHoliday, 
    postHoliday 
} from "../../3.Store/HolidaySlice";

function HolidayForm() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const getYearFromQuery = () => {
        const params = new URLSearchParams(location.search);
        return params.get('year');
    };

    const yearParam = getYearFromQuery();
    const [dayOfWeek, setDayOfWeek] = useState('');
    const holiday = useSelector((state) => state.HolidaySlice.holiday);
    const { description, is_cny, date } = holiday || [];
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (id) {
            dispatch(getHolidays({year:getYearFromQuery()}))
            .then(() => {
                dispatch(getHoliday(id));
                setLoading(false);
            })
        } else {
            dispatch(resetHoliday());
            setLoading(false)
        }
    }, [id]);

    useEffect(() => {
        if (date?.date) {
            const parsedDate = moment(date.date, ["YYYY-MM-DD", "YYYY-MM-DDTHH:mm:ssZ"]);
            if (parsedDate.isValid()) {
                setDayOfWeek(parsedDate.format('dddd'));
            } else {
                console.error("Invalid date format:", date.date);
            }
        }
    }, [date]);

    const handleForm = (e) => {
        const entity = { ...holiday, description: e.target.value };
        dispatch(updateHoliday(entity));
    };

    const handleDateChange = (e) => {
        setDayOfWeek(moment(e.target.value).format('dddd'));
        const year = moment(e.target.value).format("YYYY");
        const entity = { 
            ...holiday, 
            date: { date: e.target.value },
            year: year 
        };
        dispatch(updateHoliday(entity));
    };

    const handleCnyChange = (event) => {
        const isChecked = event.target.checked ? 1 : 0;
        const entity = { ...holiday, is_cny: isChecked };
        dispatch(updateHoliday(entity));
    };

    const handleCreate = () => {
        setLoading(true);
        const entity = { ...holiday };
        dispatch(postHoliday(entity))
        .unwrap()
        .then(() => {
            setLoading(false);
            localStorage.removeItem('holidays');
            Swal.fire("Record Saved", "", "success", "OK");
            navigate(`/Holidays?year=${yearParam}`);
        })
        .catch((err) => {
            Swal.fire("Error", err.response.data, "error", "OK");
        });
    };

    const handleUpdate = () => {
        setLoading(true);
        const entity = { ...holiday };
        dispatch(putHoliday(entity))
          .unwrap()
          .then(() => {
            setLoading(false);
            localStorage.removeItem('holidays');
            Swal.fire("Record Updated", "", "success", "OK");
            navigate(`/Holidays?year=${yearParam}`);
          })
          .catch((err) => {
            Swal.fire("Error", err.response.data, "error", "OK");
          });
    };

    const handleDelete = () => {
        Swal.fire({
          title: "Are you sure?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                const entity = { ...holiday, status: { id: -1 } };
                dispatch(putHoliday(entity))
                .unwrap()
                .then(() => {
                    setLoading(false);
                    localStorage.removeItem('holidays');
                    Swal.fire("Deleted", "", "success", "OK");
                    navigate(`/Holidays?year=${yearParam}`);
                })
                .catch((err) => {
                    Swal.fire("Error", err.response.data, "error", "OK");
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire("Cancel", "", "info", "OK");
            }
        });
    };

    const handleBack = () => {
        navigate(`/Holidays?year=${yearParam}`);
    };

    return (
        <Container>
            {loading && (
                <ThreeDots
                visible={loading}
                height="80"
                width="80"
                color="#4fa94d"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: '9999',
                }}
                />
            )}
            <Card className="CardContent">
                <h1 className="TitleHeader">
                    {holiday?.id ? "Update Holiday" : "Add Holiday"}
                </h1>

                <Row className="my-3">
                    <Col md={8} sm={8}>
                        <Form.Label className="FormLabel">Date</Form.Label><br/>
                    </Col>
                    <Col md={4} sm={4}>
                        <Form.Control 
                            type="date" 
                            id="date" 
                            name="date" 
                            value={moment(date?.date).format('YYYY-MM-DD')}
                            onChange={handleDateChange}
                        />
                    </Col>
                </Row>

                <Row className="my-2">
                    <Col md={8} sm={8}>
                        <Form.Label className="FormLabel">Day</Form.Label>
                    </Col>
                    <Col md={4} sm={4}>
                        <Form.Control readOnly value={dayOfWeek} />
                    </Col>
                </Row>

                <Row className="my-2">
                    <Col md={8} sm={8}>
                        <Form.Label className="FormLabel">Holiday</Form.Label>
                    </Col>
                    <Col md={4} sm={4}>
                        <Form.Control
                            as="textarea"
                            name="description"
                            value={description || ""}
                            onChange={handleForm}
                        />
                    </Col>
                </Row>

                <Row className="my-2">
                    <Col md={8} sm={8}>
                        <Form.Label className="FormLabel">Is Chinese New Year ?</Form.Label>
                    </Col>
                    <Col md={4} sm={4}>
                        <Form.Check
                            type="checkbox"
                            id="is_cny"
                            name="is_cny"
                            checked={is_cny === 1}
                            onChange={handleCnyChange}
                        />
                    </Col>
                </Row>

                <div className="d-flex justify-content-between">
                    <ButtonGroup className="my-2">
                        <Button className="Button" onClick={handleBack}>
                            Back
                        </Button>
                    </ButtonGroup>

                    <ButtonGroup className="my-2">
                        {holiday?.id && (
                        <Button className="Button" onClick={handleDelete}>
                            Delete
                        </Button>
                        )}
                        &nbsp; &nbsp;
                        {holiday?.id && (
                        <Button className="Button" onClick={handleUpdate}>
                            Update
                        </Button>
                        )}
                        
                        {!holiday?.id && (
                        <Button className="Button" onClick={handleCreate}>
                            Create
                        </Button>
                        )}
                    </ButtonGroup>
                </div>
            </Card>
        </Container>
    );
}

export default HolidayForm;