import axios, { post, put, get } from "axios";

export const apis = [
  // Login
  {
    name: "login",
    method: "POST",
    endpoint: "/login/",
  },

  // Search Employee for Leave Records
  {
    name: "search_employee",
    method: "POST",
    endpoint: "/employees/search",
  },

  {
    name: "get_roles",
    method: "GET",
    endpoint: "/application/module/employees"   
  },

  //Medical Claim
  {
    name: "get_medicalClaimByEmployeeId",
    method: "GET",
    endpoint: "/medical/claims/{employee_id}/page/{page}/count"   
  },

  {
    name: "get_medicalClaimByEmployeeId_count",
    method: "GET",
    endpoint: "/medical/claims/{employee_id}/page/count"   
  },

  {
    name: "add_medicalClaim",
    method: "POST",
    endpoint: "/medical/claims/add"   
  },

  {
    name: "add_medicalClaimAttachment",
    method: "POST",
    endpoint: "/medical/benefit/attachments/add"
  },

  {
    name: "getAmedicalClaim",
    method: "GET",
    endpoint: "/medical/claims/details/{medical_benefit_id}"   
  },

  {
    name: "editMedicalClaim",
    method: "PUT",
    endpoint: "/medical/claims/edit/{medical_benefit_id}"
  },

  {
    name: "editMedicalClaimAttachment",
    method: "PUT",
    endpoint: "/medical/benefit/attachments/edit/{medical_benefit_id}"
  },

  {
    name: "deleteMedicalClaim",
    method: "PUT",
    endpoint: "/medical/claims/delete/{medical_benefit_id}"
  },

  //SU Leave Approval
  {
    name: "get_su_approval_lists",
    method: "GET",
    endpoint: "/leave/approvals/lists/by/default/approver/{employee_id}"   
  },
  {
    name: "su_approve_leave",
    method: "PUT",
    endpoint: "/leave/approvals/approve"
  },
  {
    name: "su_reject_leave",
    method: "PUT",
    endpoint: "/leave/approvals/reject"
  },

  //SU Review Medical Claim
  {
    name: "su_medical_lists",
    method: "GET",
    endpoint: "/medical/claims/listsBySupervisor/{page}"
  },
  {
    name: "su_medical_lists_count",
    method: "GET",
    endpoint: "/medical/claims/listsBySupervisor/maxPageCount"
  },
  {
    name: "su_approve_medical_claim",
    method: "PUT",
    endpoint: "/medical/claims/approve/supervisor/{id}"
  },
  {
    name: "su_medical_lists_count",
    method: "GET",
    endpoint: "/medical/claims/reject/supervisor/{id}"
  },

  // User Access

  {
    name: "get_user_access",
    method: "GET",
    endpoint: "/application/module/employees/lists2"   
  },
  {
    name: "get_user_access_modules",
    method: "GET",
    endpoint: "/application/module/employees/modulelist"
  },
  {
    name: "update_user_access",
    method: "PUT",
    endpoint: "/application/module/employees/update"
  },  
 

  // Get Leave Records
  {
    name: "get_annualLeave",
    method: "GET",
    endpoint: "/leave/movement/records/lists/{id}/{year}",
  },
  {
    name: "get_sickLeave",
    method: "GET",
    endpoint: "/leave/movement/records/lists/sick/leave/{id}/{year}",
  },
  {
    name: "get_specialLeave",
    method: "GET",
    endpoint: "/leave/movement/records/lists/special/leave/{id}/{year}",
  },
  {
    name: "get_otherLeave",
    method: "GET",
    endpoint: "/leave/movement/records/lists/other/leave/{id}/{year}",
  },
  {
    name: "get_unpaidLeave",
    method: "GET",
    endpoint: "/leave/movement/records/lists/unpaid/leave/{id}/{year}",
  },
  {
    name: "get_medicalbenefits",
    method: "GET",
    endpoint: "/leave/movement/records/lists/medical/benefit/{id}/{year}",
  },
  {
    name: "get_maternityLeave",
    method: "GET",
    endpoint: "/leave/movement/records/lists/maternity/leave/{id}/{year}",
  },
  {
    name: "get_hospitalization",
    method: "GET",
    endpoint: "/leave/movement/records/lists/hospitalization/{id}/{year}",
  },
  {
    name: "get_a_leave_details",
    method: "GET",
    endpoint: "/leave/movement/records/lists/{leave_move_records_id}",
  },

  // Download Leave Movement Records
  {
    name: "download_leaveRecords_excel",
    method: "POST",
    endpoint: "/leave/movement/records/download/excel",
  },

  {
    name: "download_leaveRecords_pdf",
    method: "POST",
    endpoint: "/leave/movement/records/download/pdf",
  },  

  // Holiday
  {
    name: "get_holidays_all",
    method: "GET",
    endpoint: "/holidays/lists",
  },
  {
    name: "get_holiday",
    method: "GET",
    endpoint: "/holidays/lists/{id}",
  },
  {
    name: "create_holiday",
    method: "POST",
    endpoint: "/holidays/add",
  },
  {
    name: "put_holiday",
    method: "PUT",
    endpoint: "/holidays/update/{id}",
  },
  {
    name: "getHolidayByYear",
    method: "GET",
    endpoint: "/holidays/page/{year}/count"
  },

  //Leave Summary
  {
    name: "search_leave_summary",
    method: "POST",
    endpoint: "/leave/summaries/search",
  },
  {
    name: "list_all_leave_summary",
    method: "GET",
    endpoint: "/leave/summaries/lists",
  },
  {
    name: "list_a_leave_summary",
    method: "GET",
    endpoint: "/leave/summaries/lists/{leave_summaries_id}",
  },
  {
    name: "list_leave_summary+pagination",
    method: "GET",
    endpoint: "/leave/summaries/page/{year}/count",
  },
  {
    name: "leave_summary_page_count",
    method: "GET",
    endpoint: "/leave/summaries/page/count",
  },
  {
    name: "leave_summary_total_count",
    method: "GET",
    endpoint: "/leave/summaries/{year}/count",
  },
  {
    name: "list_leave_summary_employee_year",
    method: "GET",
    endpoint: "/leave/summaries/{employee_id}/{year}",
  },
  {
    name: "list_sickleave_summary_employee_year",
    method: "GET",
    endpoint: "/sickleave/summaries/{employee_id}/{year}",
  },
  {
    name: "list_medicalbenefit_summary_employee_year",
    method: "GET",
    endpoint: "/medicalbenefit/summaries/{employee_id}/{year}",
  },    
  {
    name: "print_leave_summary_excel",
    method: "POST",
    endpoint: "/leave/summaries/download/excel",
  },
  {
    name: "print_leave_summary_pdf",
    method: "GET",
    endpoint: "/leave/summaries/print/{leave_summary_id}/pdf",
  },

  // Leave Application
  {
    name: "get_leave_application_lists",
    method: "GET",
    endpoint: "/leave/applications/lists/by/employee/{id}",
  },
  {
    name: "get_leave_application_employee+pagination",
    method: "GET",
    endpoint: "/leave/applications/{id}/page/{page}/count",
  },
  {
    name: "get_leave_applications_page_count",
    method: "GET",
    endpoint: "/leave/applications/{id}/page/count",
  },
  {
    name: "get_leave_applications_total_count",
    method: "GET",
    endpoint: "/leave/applications/{id}/count",
  },
  {
    name: "add_leave_application",
    method: "POST",
    endpoint: "/leave/applications/add",
  },
  {
    name: "send_email_notification",
    method: "POST",
    endpoint: "/leave/applications/notify",
  },
  {
    name: "edit_leave_application",
    method: "PUT",
    endpoint: "/leave/applications/edit/{leave_application_id}",
  },
  {
    name: "withdraw_leave_application",
    method: "POST",
    endpoint: "/leave/withdrawals/add/{leave_application_id}",
  },
  {
    name: "get_leave_balance",
    method: "GET",
    endpoint: "/leave/applications/leave/balance/{id}",
  },
  {
    name: "post_days_apply",
    method: "POST",
    endpoint: "/leave/applications/number/of/days/applying",
  },
  {
    name: "get_leave_application_details",
    method: "GET",
    endpoint: "/leave/applications/lists/details/{leave_application_id}",
  },
  {
    name: "delete_leave_application_details",
    method: "PUT",
    endpoint: "/leave/applications/delete/{leave_application_id}",
  },

  //Leave Withdrawals
  {
    name: "get_withdrawal_lists",
    method: "GET",
    endpoint: "/leave/withdrawals/lists",
  },
  {
    name: "get_a_withdrawal_list",
    method: "GET",
    endpoint: "/leave/withdrawals/lists/{leave_withdrawal_application_id}",
  },
  {
    name: "get_withdrawal_lists+pagination",
    method: "GET",
    endpoint: "/leave/withdrawals/page/{page}/count",
  },
  {
    name: "get_withdrawal_lists_pagecount",
    method: "GET",
    endpoint: "/leave/withdrawals/page/count",
  },
  {
    name: "get_withdrawal_lists_count",
    method: "GET",
    endpoint: "/leave/withdrawals/count",
  },
  {
    name: "review_a_withdrawal_list",
    method: "PUT",
    endpoint: "/leave/withdrawals/approval/{leave_withdrawal_application_id}",
  },

  //Review Leave
  {
    name: "update_review_leave",
    method: "PUT",
    endpoint: "/hr/review/update/{leave_application_id}",
  },

  //Review Medical Claims
  {
    name: "search_review_medical_claims_name+pagination",
    method: "POST",
    endpoint: "/hr/review/claim/search/{page}",
  },
  {
    name: "search_review_medical_claims_name_pageCount",
    method: "POST",
    endpoint: "/hr/review/claim/search/page/count",
  },
  {
    name: "search_review_medical_claims_name_totalCount",
    method: "POST",
    endpoint: "/review/claims/search/total/count",
  },
  {
    name: "list_review_medical_claims+pagination",
    method: "GET",
    endpoint: "/hr/review/claim/page/{page}/count",
  },
  {
    name: "list_review_medical_claims_pageCount",
    method: "GET",
    endpoint: "/hr/review/claim/page/count",
  },
  {
    name: "list_review_medical_claims_totalCount",
    method: "GET",
    endpoint: "/hr/review/claims/count",
  },
  {
    name: "approve_medical_claims",
    method: "PUT",
    endpoint: "/hr/review/claim/approve",
  },
  {
    name: "reject_medical_claims",
    method: "PUT",
    endpoint: "/hr/review/claim/reject",
  },
  {
    name: "mark_medical_claims",
    method: "PUT",
    endpoint: "/review/claims/mark/reviewed",
  },
  {
    name: "download_review_medical_claims",
    method: "GET",
    endpoint: "/medical/claims/hr/download/excel/{fromDate}/{toDate}/{searchText}",
  },

  //Leave Award
  {
    name: "search_leave_awards",
    method: "POST",
    endpoint: "/leaveawards/search",
  },
  {
    name: "list_a_leave_award",
    method: "GET",
    endpoint: "/leaveawards/lists/{leave_award_id}",
  },
  {
    name: "get_leave_awards_year+pagination",
    method: "GET",
    endpoint: "/leaveawards/lists/by/year/{year}",
  },
  {
    name: "get_leave_awards_yearCount",
    method: "GET",
    endpoint: "/leave/awards/page/count",
  },
  {
    name: "get_leave_awards_totalcount",
    method: "GET",
    endpoint: "/leave/awards/{year}/count",
  },
  {
    name: "add_leave_awards",
    method: "POST",
    endpoint: "/leaveawards/add",
  },
  {
    name: "print_leave_awards_excel",
    method: "POST",
    endpoint: "/leave/awards/print/excel",
  },
  {
    name: "print_leave_awards_pdf",
    method: "POST",
    endpoint: "/leave/awards/print/pdf",
  },
  {
    name: "update_leave_awards",
    method: "PUT",
    endpoint: "/leaveawards/update/{leave_award_id}",
  },

  //HR Review Leave Application
  {
    name: "get_HR_review+pagination",
    method: "GET",
    endpoint: "/hr/review/page/{page}/count",
  },
  {
    name: "get_HR_review_pagecount",
    method: "GET",
    endpoint: "/hr/review/page/count",
  },
  {
    name: "HR_mark_reviewed",
    method: "PUT",
    endpoint: "/hr/review/reviewed",
  },
  {
    name: "HR_Approved_Application",
    method: "PUT",
    endpoint: "/hr/review/approve",
  },
  {
    name: "HR_Reject_Application",
    method: "PUT",
    endpoint: "/hr/review/reject",
  },
  {
    name: "HR_Send_Notification",
    method: "POST",
    endpoint: "/hr/review/notify",
  },
  {
    name: "HR_Search_Leave",
    method: "POST",
    endpoint: "/hr/review/search/{page}",
  },
  {
    name: "HR_Search_Leave_PageCount",
    method: "POST",
    endpoint: "/hr/review/search/page/count",
  },

  //Medical Certificate
  {
    name: "get_medical_certificate_attachment",
    method: "GET",
    endpoint: "/medical/certificates/lists/attachment/{id}",
  },
  {
    name: "add_medical_certificate_attachment",
    method: "POST",
    endpoint: "/medical/certificates/add",
  },
  {
    name: "edit_medical_certificate_attachment",
    method: "PUT",
    endpoint: "/medical/certificates/edit/{medical_certificate_id}",
  },

  //Employee
  {
    name: "get_employees_lists",
    method: "GET",
    endpoint: "/employees/lists",
  },
  {
    name: "get_employees_lists_chronically",
    method: "GET",
    endpoint: "/employees/list/chronically",
  },
  {
    name: "search_employees_active",
    method: "POST",
    endpoint: "/employees/search",
  },
  {
    name: "get_employee_image",
    method: "GET",
    endpoint: "/employeephoto/{employee_id}",
  },
  {
    name: "get_employee_list_edit",
    method: "GET",
    endpoint: "/users/lists/{employee_id}",
  },
  {
    name: "get_company_lists",
    method: "GET",
    endpoint: "/companies/lists",
  },
  {
    name: "dismiss_user",
    method: "PUT",
    endpoint: "/users/edit/{id}",
  },
  {
    name: "dismiss_employee",
    method: "PUT",
    endpoint: "/employees/edit/{id}",
  },
  {
    name: "add_username",
    method: "POST",
    endpoint: "/employees/username",
  },
  {
    name: "add_user",
    method: "POST",
    endpoint: "/users/add",
  },
  {
    name: "add_employee",
    method: "POST",
    endpoint: "/employees/add",
  },
  {
    name: "add_leaveprorated",
    method: "POST",
    endpoint: "/leaveprorated/add",
  },
  {
    name: "add_employeePhoto",
    method: "POST",
    endpoint: "/employeephoto/add/{id}",
  },
  {
    name: "replace_employeePhoto",
    method: "PUT",
    endpoint: "/employeephoto/replace/{id}",
  },
  {
    name: "resign_employee",
    method: "PUT",
    endpoint: "/employees/resign/{employee_id}",
  },
  {
    name: "get_a_employee",
    method: "GET",
    endpoint: "/employees/lists/{employee_id}",
  },
  {
    name: "print_employee",
    method: "GET",
    endpoint: "/employees/print/{employee_id}/pdf",
  },

  //Leave accural
  {
    name: "get_leave_accruals_lists",
    method: "GET",
    endpoint: "/leave/accruals/page/{year}/count",
  },
  {
    name: "get_a_leave_accrual_list",
    method: "GET",
    endpoint: "/leave/accruals/lists/{leave_accrual_id}",
  },
  {
    name: "submit_leave_accrual_encashment",
    method: "POST",
    endpoint: "/leave/accruals/create/encashment/{leave_accrual_id}",
  },
  {
    name: "update_leave_accrual_encashment",
    method: "PUT",
    endpoint: "/leave/accruals/update/encashment/{encashment_id}",
  },
  {
    name: "update_leave_accrual_forfeit",
    method: "PUT",
    endpoint: "/leave/accruals/update/forfeit/{leave_accrual_id}",
  },
  {
    name: "print_leave_accrual_excel",
    method: "GET",
    endpoint: "/leave/accruals/print/excel/year/{year}",
  },

  //Medical Claim
  {
    name: "medical_claim_by_leaveapplication",
    method: "GET",
    endpoint: "/medical/claims/find_by_leave_application/{id}",
  },
  {
    name: "medical_claim_withdraw",
    method: "PUT",
    endpoint: "/medical/claims/withdrawByEmployee",
  },

  //Staff On Leave
  {
    name: "list_staffOnLeave",
    method: "GET",
    endpoint: "/staffonleave/lists/{page}",
  },
  {
    name: "list_staffOnLeave_count",
    method: "GET",
    endpoint: "/staffonleave/page/count",
  },

  //Medical Benefits Attachment
  {
    name: "get_medical_benefits_attachment",
    method: "GET",
    endpoint: "/medical/benefit/attachments/lists/{medical_benefit_attachment_id}",
  },

  //Special Leave Cert
  {
    name: "get_special_leave_attachment",
    method: "GET",
    endpoint: "/attachments/lists/attachment/{id}",
  },
  {
    name: "add_special_leave_attachment",
    method: "POST",
    endpoint: "/attachments/create",
  },
  {
    name: "edit_special_leave_attachment",
    method: "PUT",
    endpoint: "/update/{attachment}",
  },
];

export const apiURL = `http://192.168.1.173/leave3/apiv2/public/index.php`;


export const apiServices = async (service, opts) => {
  const api = apis.find((api) => api.name === service);

  // const employeetoken = JSON.parse(localStorage.getItem("token"));
  const employeetoken = localStorage.getItem("token");
  console.log(employeetoken);

  let config = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "true",
    "Access-Control-Allow-Credentials": "true",
    // "x-auth-token": employeetoken,
  };

  // if API Not Found
  if (api === undefined) {
    if (opts?.onFailure !== undefined) opts.onFailure("API Not Found");
    return;
  }

  if (employeetoken) {
    config.headers = { "X-Auth-Token": employeetoken };
  }

  const method = api.method;
  let endpoint = api.endpoint;

  if (opts?.replace !== undefined) {
    if (Array.isArray(opts.replace)) {
      opts.replace.forEach((replace) => {
        endpoint = endpoint.replace(replace.search, replace.replace);
      });
    } else {
      endpoint = endpoint.replace(opts?.replace.search, opts?.replace.replace);
    }
  }

  let response = "";
  let data = {};

  if (method === "GET") {
    response = axios.get(`${apiURL}${endpoint}`, config);
  }

  if (method === "POST") {
    const body = opts?.body === undefined ? {} : opts?.body;
    response = axios.post(`${apiURL}${endpoint}`, body, config);
  }

  if (method === "PUT") {
    const body = opts?.body === undefined ? {} : opts?.body;
    response = axios.put(`${apiURL}${endpoint}`, body, config);
  }

  return response;
};